import React, { useState, useEffect, useContext, useCallback, useMemo } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Link } from "react-router-dom";
import Modal from 'react-modal';
import moment from "moment";
import { toast } from "react-toastify";
import { Undo2, BadgeInfo, Hotel, Folder, CalendarFold, Home, Plus, Copy, Shuffle, Info, Calendar } from "lucide-react"

import Select from 'react-select';

import { ThreeDots } from 'react-loader-spinner'

import CheckoutForm from "../components/CheckoutForm";
import "../Dashboard.css";
import { DataContext } from "../context/DataContext";
import RentalAgreement from "../components/RentalAgreement";

import { resortData } from "../data";

import clsx from "clsx"

import { SectionWrapper, SectionHeading, SectionBadge } from "../components/Section"
import { SpotlightCard } from "../components/SpotlightCard";


const stripePromise = loadStripe("pk_live_51O2zjYJ9PR42yBbsq1E9iEUyIcT1PVXqTXWnVEOClkJ13hbmwOptwfnENg354QruXfXucdAH7x4f5j6WIhfo709R007YSLEaYU");

function ResponsesFeature({ className, children, color, onClick }) {
  return (
    <SpotlightCard className={clsx("p-6", className)} color={color} onClick={onClick}>
      {children}
    </SpotlightCard>
  )
}

export default function App() {

  const { user, setPayment } = useContext(DataContext)

  const [clientSecret, setClientSecret] = useState("");
  const [hasSigned, setHasSigned] = useState(false);
  const [hasSelectedOption, setHasSelectedOption] = useState(false);

  const [optionName, setOptionName] = useState("");
  const [optionPrice, setOptionPrice] = useState("");
  const [optionId, setOptionId] = useState("");
  const [optionLink, setOptionLink] = useState("");

  const [hasPaid, setHasPaid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [seeAgreement, setSeeAgreement] = useState(false);
  const [seePlan, setSeePlan] = useState(false);
  const [seeRentalInfo, setSeeRentalInfo] = useState(false);
  const [seeWeeks, setSeeWeeks] = useState(false);
  const [seeCalendar, setSeeCalendar] = useState(false);

  // terms and conditions
  const [checkbox, setCheckbox] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [warnings, setWarnings] = useState({ name: false, phone: false, checkbox: false });

  // rental info
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [usageFee, setUsageFee] = useState("");
  const [formattedUsageFee, setFormattedUsageFee] = useState("");
  const [amenities, setAmenities] = useState([]);
  const [confirmationFile, setConfirmationFile] = useState(null);
  const [comments, setComments] = useState("");

  const [inputFocused, setInputFocused] = useState(false);

  const [onDemandWeeks, setOnDemandWeeks] = useState(1);
  const [onDemandYear, setOnDemandYear] = useState(2024);
  const [onDemandUsageFee, setOnDemandUsageFee] = useState("");
  const [onDemandFormattedUsageFee, setOnDemandFormattedUsageFee] = useState("");
  const [onDemandAmenities, setOnDemandAmenities] = useState([]);

  const [sisterResorts, setSisterResorts] = useState([]);
  const [sisterResortsImage, setSisterResortsImage] = useState(null);
  const [onDemandComments, setOnDemandComments] = useState("");

  const [weeks, setWeeks] = useState([]);
  const [onDemands, setOnDemands] = useState([]);

  const [planName, setPlanName] = useState("");
  const [planPhrase, setPlanPhrase] = useState("");
  const [planUnits, setPlanUnits] = useState("");

  const [modalIsOpen, setIsOpen] = useState(false);
  const [newModalIsOpen, setNewIsOpen] = useState(false);
  const [editModalRentalIsOpen, setEditRentalIsOpen] = useState(false);
  const [editModalOnDemandIsOpen, setEditOnDemandIsOpen] = useState(false);
  const [deleteModalRentalIsOpen, setDeleteRentalIsOpen] = useState(false);
  const [deleteModalOnDemandIsOpen, setDeleteOnDemandIsOpen] = useState(false);

  const [selectedRental, setSelectedRental] = useState(null);
  const [selectedOnDemand, setSelectedOnDemand] = useState(null);

  const [dates, setDates] = useState([]);

  const [limit, setLimit] = useState(0);
  const [isRentalDisabled, setIsRentalDisabled] = useState(false);
  const [isOnDemandDisabled, setIsOnDemandDisabled] = useState(false);

  // ResortFilterForm state and handlers
  const [resorts, setResorts] = useState([]);
  const [names, setNames] = useState([]);
  const [destinations, setDestinations] = useState([]);

  const [selectedResort, setSelectedResort] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [selectedDestination, setSelectedDestination] = useState("");

  const [customAmenities, setCustomAmenities] = useState([]);


  // ResortFilterForm state and handlers
  const [onDemandResorts, setOnDemandResorts] = useState([]);
  const [onDemandNames, setOnDemandNames] = useState([]);

  const [selectedOnDemandResort, setSelectedOnDemandResort] = useState("");
  const [selectedOnDemandName, setSelectedOnDemandName] = useState("");
  const [selectedOnDemandDestination, setSelectedOnDemandDestination] = useState("");

  const [onDemandCustomAmenities, setOnDemandCustomAmenities] = useState([]);

  const [propertiesList, setPropertiesList] = useState([]);

  const [editResort, setEditResort] = useState("");
  const [editName, setEditName] = useState("");
  const [editDestination, setEditDestination] = useState("");
  const [editStartDate, setEditStartDate] = useState("");
  const [editEndDate, setEditEndDate] = useState("");
  const [editUsageFee, setEditUsageFee] = useState("");
  const [editFormattedUsageFee, setEditFormattedUsageFee] = useState("");
  const [editAmenities, setEditAmenities] = useState([]);
  const [editCustomAmenities, setEditCustomAmenities] = useState([]);
  const [editConfirmationFile, setEditConfirmationFile] = useState(null);
  const [editComments, setEditComments] = useState("");


  const [editOnDemandResort, setEditOnDemandResort] = useState("");
  const [editOnDemandName, setEditOnDemandName] = useState("");
  const [editOnDemandDestination, setEditOnDemandDestination] = useState("");
  const [editOnDemandYear, setEditOnDemandYear] = useState("");
  const [editOnDemandWeeks, setEditOnDemandWeeks] = useState("");
  const [editOnDemandUsageFee, setEditOnDemandUsageFee] = useState("");
  const [editOnDemandFormattedUsageFee, setEditOnDemandFormattedUsageFee] = useState("");
  const [editOnDemandSisterResorts, setEditOnDemandSisterResorts] = useState([]);
  const [editOnDemandSisterResortsImage, setEditOnDemandSisterResortsImage] = useState(null);
  const [editOnDemandAmenities, setEditOnDemandAmenities] = useState([]);
  const [editOnDemandCustomAmenities, setEditOnDemandCustomAmenities] = useState([]);
  const [editOnDemandComments, setEditOnDemandComments] = useState("");

  // useEffect(() => {
  //   setProperty(selectedResort + " " + selectedName + " " + selectedDestination);
  // }, [selectedResort, selectedName, selectedDestination]);

  // useEffect(() => {
  //   setOnDemandProperty(selectedOnDemandResort + " " + selectedOnDemandName + " " + selectedOnDemandDestination);
  // }, [selectedOnDemandResort, selectedOnDemandName, selectedOnDemandDestination]);


  useEffect(() => {
    const propertiesList = Array.from(
      new Set(
        resortData
          .filter((row) => row.disponibilidad === "Si")
          .map((row) => ({
            value: `${row.resort} - ${row.name}`,
            label: `${row.resort} - ${row.name}`,
          }))
      )
    );
    setPropertiesList(propertiesList);
  }, []);

  useEffect(() => {
    const uniqueResorts = Array.from(
      new Set(
        resortData
          .filter((row) => row.disponibilidad === "Si")
          .map((row) => row.resort)
      )
    );
    setResorts(uniqueResorts);
  }, []);

  useEffect(() => {
    const filteredNames = Array.from(
      new Set(
        resortData
          .filter((row) => row.resort === selectedResort)
          .map((row) => row.name)
      )
    );
    setNames(filteredNames);

    // Set the default value for the "name" input when there is only one option available
    setSelectedName(filteredNames.length === 1 ? filteredNames[0] : "");
  }, [selectedResort]);

  useEffect(() => {
    const filteredDestinations = Array.from(
      new Set(
        resortData
          .filter(
            (row) => row.resort === selectedResort && row.name === selectedName
          )
          .map((row) => row.destino.split(",")) // Split the string by commas
          .flat() // Flatten the resulting array
      )
    );
    setDestinations(filteredDestinations);
    setSelectedDestination(
      filteredDestinations.length === 1 ? filteredDestinations[0] : ""
    );
  }, [selectedResort, selectedName]);


  useEffect(() => {
    const filteredNames = Array.from(
      new Set(
        resortData
          .filter((row) => row.resort === editResort)
          .map((row) => row.name)
      )
    );
    setNames(filteredNames);

    // Set the default value for the "name" input when there is only one option available
    // setEditName(filteredNames.length === 1 ? filteredNames[0] : "");
  }, [editResort]);

  useEffect(() => {
    const filteredDestinations = Array.from(
      new Set(
        resortData
          .filter(
            (row) => row.resort === editResort && row.name === editName
          )
          .map((row) => row.destino.split(",")) // Split the string by commas
          .flat() // Flatten the resulting array
      )
    );
    setDestinations(filteredDestinations);
    // setEditName(
    //   filteredDestinations.length === 1 ? filteredDestinations[0] : ""
    // );
  }, [editResort, editName]);

  useEffect(() => {
    const uniqueOnDemandResorts = Array.from(
      new Set(
        resortData
          // .filter((row) => row.disponibilidad === "Si")
          .map((row) => row.resort)
      )
    );
    setOnDemandResorts(uniqueOnDemandResorts);
  }, []);


  useEffect(() => {
    const filteredOnDemandNames = Array.from(
      new Set(
        resortData
          .filter((row) => row.resort === selectedOnDemandResort)
          .map((row) => row.name)
      )
    );
    setOnDemandNames(filteredOnDemandNames);

    // Set the default value for the "name" input when there is only one option available
    setSelectedOnDemandName(filteredOnDemandNames.length === 1 ? filteredOnDemandNames[0] : "");
  }, [selectedOnDemandResort]);

  useEffect(() => {
    const filteredOnDemandNames = Array.from(
      new Set(
        resortData
          .filter((row) => row.resort === editOnDemandResort)
          .map((row) => row.name)
      )
    );
    setOnDemandNames(filteredOnDemandNames);

    // Set the default value for the "name" input when there is only one option available
    // setEditOnDemandName(filteredOnDemandNames.length === 1 ? filteredOnDemandNames[0] : "");
  }, [editOnDemandResort]);

  useEffect(() => {
    if (startDate) {
      // add 7 days to the start date
      const endDate = moment(startDate).add(7, "days").format("YYYY-MM-DD");
      // set the end date
      setEndDate(endDate);
    }
  }, [startDate]);

  useEffect(() => {
    if (limit && weeks && onDemands) {
      if (weeks.length >= limit) {
        setIsRentalDisabled(true);
      } else {
        setIsRentalDisabled(false);
      }
      if (onDemands.length >= limit) {
        setIsOnDemandDisabled(true);
      } else {
        setIsOnDemandDisabled(false);
      }
    }
  }, [limit, weeks, onDemands]);

  const amenitiesList = useMemo(() => [
    "Resort Platinum Status",
    "2 for 1 - 50 minutes Spa massages",
    "Free Massages",
    "Discounted Rounds of Golf",
    "2 to 16 Free Rounds of Golf",
    "Access to Air Gondola"
  ], []);

  const handleAmenities = (e) => {
    const { value } = e.target;
    if (amenities.includes(value)) {
      setAmenities(amenities.filter((amenity) => amenity !== value));
    } else {
      setAmenities([...amenities, value]);
    }
  };

  const handleOnDemandAmenities = (e) => {
    const { value } = e.target;
    if (onDemandAmenities.includes(value)) {
      setOnDemandAmenities(onDemandAmenities.filter((amenity) => amenity !== value));
    } else {
      setOnDemandAmenities([...onDemandAmenities, value]);
    }
  };

  const handleEditOnDemandAmenities = (e) => {
    const { value } = e.target;
    if (editOnDemandAmenities.includes(value)) {
      setEditOnDemandAmenities(editOnDemandAmenities.filter((amenity) => amenity !== value));
    } else {
      setEditOnDemandAmenities([...editOnDemandAmenities, value]);
    }
  };

  const handleRentalAmenities = (e) => {
    const { value } = e.target;
    if (editAmenities.includes(value)) {
      setEditAmenities(editAmenities.filter((amenity) => amenity !== value));
    } else {
      setEditAmenities([...editAmenities, value]);
    }
  };

  const handleAddCustomAmenity = (e) => {
    if (e.key === "Enter") {
      setCustomAmenities([
        ...customAmenities,
        { name: e.target.value, checked: true },
      ]);
      e.target.value = "";
    }
  };

  const handleEditAddCustomAmenity = (e) => {
    if (e.key === "Enter") {
      setEditCustomAmenities([
        ...editCustomAmenities,
        { name: e.target.value, checked: true },
      ]);
      e.target.value = "";
    }
  };

  const handleEditOnDemandAddCustomAmenity = (e) => {
    if (e.key === "Enter") {
      setEditOnDemandCustomAmenities([
        ...editOnDemandCustomAmenities,
        { name: e.target.value, checked: true },
      ]);
      e.target.value = "";
    }
  };

  const deleteCustomAmenity = (index) => {
    const updatedAmenities = [...customAmenities];
    updatedAmenities.splice(index, 1);
    setCustomAmenities(updatedAmenities);
  };

  const editDeleteCustomAmenity = (index) => {
    const updatedAmenities = [...editCustomAmenities];
    updatedAmenities.splice(index, 1);
    setEditCustomAmenities(updatedAmenities);
  };

  const editOnDemandDeleteCustomAmenity = (index) => {
    const updatedAmenities = [...editOnDemandCustomAmenities];
    updatedAmenities.splice(index, 1);
    setEditOnDemandCustomAmenities(updatedAmenities);
  };

  const handleCustomAmenitiesCheck = (e, index) => {
    const updatedAmenities = [...customAmenities];
    updatedAmenities[index].checked = e.target.checked;
    setCustomAmenities(updatedAmenities);
  };

  const editHandleCustomAmenitiesCheck = (e, index) => {
    const updatedAmenities = [...editCustomAmenities];
    updatedAmenities[index].checked = e.target.checked;
    setEditCustomAmenities(updatedAmenities);
  };

  const editHandeOnDemandCustomAmenitiesCheck = (e, index) => {
    const updatedAmenities = [...editOnDemandCustomAmenities];
    updatedAmenities[index].checked = e.target.checked;
    setEditOnDemandCustomAmenities(updatedAmenities);
  };

  const handleOnDemandAddCustomAmenity = (e) => {
    if (e.key === "Enter") {
      setOnDemandCustomAmenities([
        ...onDemandCustomAmenities,
        { name: e.target.value, checked: true },
      ]);
      e.target.value = "";
    }
  };

  const deleteOnDemandCustomAmenity = (index) => {
    const updatedAmenities = [...onDemandCustomAmenities];
    updatedAmenities.splice(index, 1);
    setOnDemandCustomAmenities(updatedAmenities);
  };

  const handleOnDemandCustomAmenitiesCheck = (e, index) => {
    const updatedAmenities = [...onDemandCustomAmenities];
    updatedAmenities[index].checked = e.target.checked;
    setOnDemandCustomAmenities(updatedAmenities);
  };

  const formatUsageFee = (value) => {
    if (!value) {
      return "";
    }
    if (value < 0) {
      value = 0;
    }
    return parseFloat(value).toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  };

  // convert to base64
  const handleFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setConfirmationFile(reader.result);
    };
  };

  const handleEditFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setEditConfirmationFile(reader.result);
    };
  };

  const handleSisterResortsFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setSisterResortsImage(reader.result);
    };
  };

  const handleEditSisterResortsFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setEditOnDemandSisterResortsImage(reader.result);
    };
  };

  const handleCheckbox = (e) => {
    setCheckbox(!checkbox);
  };

  const submitTyC = (e) => {
    e.preventDefault();
    validateForm()

    if (user && name && phone && checkbox) {
      fetch("https://vrco.azurewebsites.net//api/SignTyC", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fullName: name,
          mobileNumber: phone,
          address: "",
          city: "",
          state: "",
          zipCode: "",
          email: user.email,
          uid: user.uid
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setHasSigned(true);
        })
        .catch((err) => {
          console.log(err);
          setHasSigned(false);
        }
        );
    }
  };

  const validateForm = useCallback(() => {
    const newWarnings = {
      name: !name,
      phone: !phone,
      checkbox: !checkbox,
    };
    console.log(name, phone, checkbox);
    setWarnings(newWarnings);
  }, [name, phone, checkbox]);

  useEffect(() => {
    if (name || phone || checkbox) {
      validateForm();
    }
  }, [name, phone, checkbox, validateForm]);

  const handleSisterResorts = (selectedOptions) => {
    setSisterResorts(selectedOptions);
  };

  const handleEditSisterResorts = (selectedOptions) => {
    setEditOnDemandSisterResorts(selectedOptions);
  };

  const submitRentalInfo = () => {
    if (user) {
      console.log(JSON.stringify({
        emailAddress: user.email,
        rental: selectedResort + " " + selectedName + " " + selectedDestination,
        start: startDate,
        end: endDate,
        usageFee: usageFee,
        amenities: amenities.concat(customAmenities.map((amenity) => amenity.name)).join(","), // Convert the array to a string
        amenitiesDocuments: "",
        // confirmationOfReservation: confirmationFile,
        comments: comments,
        uid: user.uid
      })
      );

      fetch("https://vrco.azurewebsites.net//api/Rental", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          emailAddress: user.email,
          rental: selectedName,
          start: startDate,
          end: endDate,
          usageFee: usageFee,
          amenities: amenities.concat(customAmenities.map((amenity) => amenity.name)).join(","), // Convert the array to a string
          amenitiesDocuments: "",
          confirmationOfReservation: confirmationFile,
          uid: user.uid,
          resort: selectedResort,
          destination: selectedDestination,
          comments: comments
        }),
      })
        .then(() => {
          toast.success('Booked Week uploaded', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          fetch("https://vrco.azurewebsites.net//api/GetRental?uid=" + user.uid)
            .then((res) => res.json())
            .then((data) => {
              console.log(data);
              setWeeks(data);
            }
            );
          setSelectedResort("");
          setSelectedName("");
          setSelectedDestination("");
          setStartDate("");
          setEndDate("");
          setUsageFee("");
          setFormattedUsageFee("");
          setAmenities([]);
          setConfirmationFile(null);
          setCustomAmenities([]);
          setComments("");
        });
    }
  };

  const submitOnDemandInfo = (e) => {
    e.preventDefault();
    if (user) {
      console.log(JSON.stringify({
        property: selectedOnDemandName,
        year: onDemandYear,
        noOfWeeks: onDemandWeeks,
        usageFee: onDemandUsageFee,
        sisterResorts: sisterResorts.map((resort) => resort.value).join(","), // Convert the array to a string
        sisterResortsFile: sisterResortsImage,
        amenities: onDemandAmenities.concat(onDemandCustomAmenities.map((amenity) => amenity.name)).join(","), // Convert the array to a string
        comments: onDemandComments,
        uid: user.uid,
        emailAddress: user.email,
        resort: selectedOnDemandResort,
        destination: selectedOnDemandDestination
      }));

      fetch("https://vrco.azurewebsites.net//api/OnDemand", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          property: selectedOnDemandName,
          year: onDemandYear,
          noOfWeeks: onDemandWeeks,
          usageFee: onDemandUsageFee,
          sisterResorts: sisterResorts.map((resort) => resort.value).join(","), // Convert the array to a string
          sisterResortsFile: sisterResortsImage,
          amenities: onDemandAmenities.concat(onDemandCustomAmenities.map((amenity) => amenity.name)).join(","), // Convert the array to a string
          comments: onDemandComments,
          uid: user.uid,
          emailAddress: user.email,
          resort: selectedOnDemandResort,
          destination: selectedOnDemandDestination
        }),
      })
        .then(() => {
          toast.success('On-Demand Rental uploaded', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          fetch("https://vrco.azurewebsites.net//api/GetOnDemand?uid=" + user.uid)
            .then((res) => res.json())
            .then((data) => {
              console.log(data);
              setOnDemands(data);
            }
            );
          setSelectedOnDemandResort("");
          setSelectedOnDemandName("");
          setSelectedOnDemandDestination("");
          setOnDemandYear(2024);
          setOnDemandWeeks(1);
          setOnDemandUsageFee("");
          setOnDemandFormattedUsageFee("");
          setSisterResorts([]);
          setSisterResortsImage(null);
          setOnDemandAmenities([]);
          setOnDemandCustomAmenities([]);
          setOnDemandComments("");
        });
    }
  };

  const selectOption = (name, price, id, planId, link) => {
    setHasSelectedOption(true);
    setOptionName(name);
    setOptionPrice(price);
    setOptionId(id);
    setPayment({
      name: name,
      price: price,
      id: id,
      planId: planId,
    })
    setOptionLink(link);
  };

  const deselectOption = () => {
    setHasSelectedOption(false);
    setOptionName("");
    setOptionPrice("");
    setOptionId("");
    setPayment({});
    setOptionLink("");
  };


  useEffect(() => {
    if (user && hasSelectedOption && !hasPaid) {
      fetch("https://vrco.azurewebsites.net/api/Pay", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: user.email,
          option: optionId
        }),
      })
        .then((res) => res.json())
        .then((data) => setClientSecret(data.clientSecret))
    }
  }, [hasSelectedOption, user, optionId, hasPaid]);

  useEffect(() => {
    if (clientSecret) {
      console.log(clientSecret);
    }
  }, [clientSecret]);

  const handleDashboardMenu = (section) => {
    if (section === "agreement") {
      setSeeAgreement(true);
      setSeePlan(false);
      setSeeRentalInfo(false);
      setSeeWeeks(false);
      setSeeCalendar(false);
    } else if (section === "plan") {
      setSeeAgreement(false);
      setSeePlan(true);
      setSeeRentalInfo(false);
      setSeeWeeks(false);
      setSeeCalendar(false);
    } else if (section === "rentalInfo") {
      setSeeAgreement(false);
      setSeePlan(false);
      setSeeRentalInfo(true);
      setSeeWeeks(false);
      setSeeCalendar(false);
    } else if (section === "weeks") {
      setSeeAgreement(false);
      setSeePlan(false);
      setSeeRentalInfo(false);
      setSeeWeeks(true);
      setSeeCalendar(false);
    } else if (section === "faq") {
      setSeeAgreement(false);
      setSeePlan(false);
      setSeeRentalInfo(false);
      setSeeWeeks(false);
      setSeeCalendar(false);
    } else if (section === "calendar") {
      setSeeAgreement(false);
      setSeePlan(false);
      setSeeRentalInfo(false);
      setSeeWeeks(false);
      setSeeCalendar(true);
    }
  };

  const timestampToDate = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${month}/${day}/${year}`;
  };

  const submitRentalModal = (e) => {
    e.preventDefault();
    setIsOpen(false);
    submitRentalInfo()
  };

  const submitOnDemandModal = (e) => {
    e.preventDefault();
    setNewIsOpen(false);
    submitOnDemandInfo(e);
  };

  const editRental = (week) => {
    setSelectedRental(week);
    setEditRentalIsOpen(true);
  }

  useEffect(() => {
    if (selectedRental) {
      console.log(selectedRental);
      setEditResort(selectedRental.resort);
      setEditName(selectedRental.rental);
      setEditDestination(selectedRental.destination);
      setEditStartDate(selectedRental.start.split(" ")[0]);
      setEditEndDate(selectedRental.end.split(" ")[0]);
      setEditUsageFee(selectedRental.usageFee);
      setEditFormattedUsageFee(formatUsageFee(selectedRental.usageFee));
      setEditAmenities(selectedRental.amenities.split(",").filter((amenity) => amenitiesList.includes(amenity)));
      const values = selectedRental.amenities.split(",").filter((amenity) => !amenitiesList.includes(amenity));
      setEditCustomAmenities(values.map((amenity) => ({ name: amenity, checked: true })));
      setEditComments(selectedRental.comments);
    }
  }, [selectedRental, amenitiesList])

  // useEffect(() => {
  //   console.log(editAmenities);
  //   console.log(editCustomAmenities);
  //   console.log(editAmenities.concat(editCustomAmenities.map((amenity) => amenity.name)).join(","))
  // }, [editCustomAmenities])

  const editRentalModal = (e) => {
    e.preventDefault();
    setEditRentalIsOpen(false);
    const requestBody = {
      id: selectedRental.id,
      emailAddress: user.email,
      uid: user.uid,
      rental: editName,
      start: editStartDate,
      end: editEndDate,
      usageFee: editUsageFee,
      amenities: editAmenities.concat(editCustomAmenities.map((amenity) => amenity.name)).join(","), // Convert the array to a string
      amenitiesDocuments: "",
      resort: editResort,
      destination: editDestination,
      comments: editComments
    };
    console.log(requestBody);
    if (editConfirmationFile) {
      requestBody.confirmationOfReservation = editConfirmationFile;
    }
    fetch("https://vrco.azurewebsites.net//api/Rental", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(requestBody),
    })
      .then((res) => res.json())
      .then((data) => {
        toast.success('Booked Week updated', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(data);
        fetch("https://vrco.azurewebsites.net//api/GetRental?uid=" + user.uid)
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            setWeeks(data);
          }
          );
      });
    setEditName("");
    setEditStartDate("");
    setEditEndDate("");
    setEditUsageFee("");
    setEditFormattedUsageFee("");
    setEditAmenities([]);
    setEditConfirmationFile(null);
    setEditCustomAmenities([]);
    setEditComments("");
  };

  const editOnDemand = (week) => {
    setSelectedOnDemand(week);
    setEditOnDemandIsOpen(true)
  }

  useEffect(() => {
    if (selectedOnDemand) {
      console.log(selectedOnDemand);
      setEditOnDemandResort(selectedOnDemand.resort);
      setEditOnDemandName(selectedOnDemand.property);
      setEditOnDemandDestination(selectedOnDemand.destination);
      setEditOnDemandYear(selectedOnDemand.year);
      setEditOnDemandWeeks(selectedOnDemand.noOfWeeks);
      setEditOnDemandUsageFee(selectedOnDemand.usageFee);
      setEditOnDemandFormattedUsageFee(formatUsageFee(selectedOnDemand.usageFee));
      setEditOnDemandSisterResorts(selectedOnDemand.sisterResorts !== "" ? selectedOnDemand.sisterResorts?.split(",").map((resort) => ({ value: resort, label: resort })) : []);
      setEditOnDemandAmenities(selectedOnDemand.amenities.split(",").filter((amenity) => amenitiesList.includes(amenity)));
      const values = selectedOnDemand.amenities.split(",").filter((amenity) => !amenitiesList.includes(amenity));
      setEditOnDemandCustomAmenities(values.map((amenity) => ({ name: amenity, checked: true })));
      setEditOnDemandComments(selectedOnDemand.comments);
    }
  }, [selectedOnDemand, amenitiesList])

  const editOnDemandModal = (e) => {
    e.preventDefault();
    setEditOnDemandIsOpen(false);
    const requestBody = {
      id: selectedOnDemand.id,
      emailAddress: user.email,
      uid: user.uid,
      property: editOnDemandName,
      year: editOnDemandYear,
      noOfWeeks: editOnDemandWeeks,
      usageFee: editOnDemandUsageFee,
      sisterResorts: editOnDemandSisterResorts.map((resort) => resort.value).join(","), // Convert the array to a string
      amenities: editOnDemandAmenities.concat(editOnDemandCustomAmenities.map((amenity) => amenity.name)).join(","), // Convert the array to a string
      comments: editOnDemandComments,
      resort: editOnDemandResort,
      destination: editOnDemandDestination
    };
    console.log(requestBody);
    if (editOnDemandSisterResortsImage) {
      requestBody.sisterResortsFile = editOnDemandSisterResortsImage;
    }
    fetch("https://vrco.azurewebsites.net//api/OnDemand", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(requestBody),
    })
      .then((res) => res.json())
      .then((data) => {
        toast.success('On-Demand Rental updated', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(data);
        fetch("https://vrco.azurewebsites.net//api/GetOnDemand?uid=" + user.uid)
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            setOnDemands(data);
          });
        // setEditOnDemandName("");
      });
  };

  const deleteRental = (week) => {
    setSelectedRental(week);
    setDeleteRentalIsOpen(true);
  }

  const deleteRentalModal = (week) => {
    fetch("https://vrco.azurewebsites.net//api/Rental", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        id: week.id,
        uid: user.uid,
        emailAddress: user.email
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        toast.success('Booked Week deleted', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(data);
        fetch("https://vrco.azurewebsites.net//api/GetRental?uid=" + user.uid)
          .then((res) => res.json())
          .then((data) => {
            setWeeks(data);
          }
          );
      });
    setDeleteRentalIsOpen(false);
  }

  const deleteOnDemand = (week) => {
    setSelectedOnDemand(week);
    setDeleteOnDemandIsOpen(true);
  }

  const deleteOnDemandModal = (week) => {
    fetch("https://vrco.azurewebsites.net//api/OnDemand", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        id: week.id,
        uid: user.uid,
        emailAddress: user.email
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        toast.success('On-Demand Rental deleted', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        fetch("https://vrco.azurewebsites.net//api/GetOnDemand?uid=" + user.uid)
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            setOnDemands(data);
          });
      });
    setDeleteOnDemandIsOpen(false);
  }

  useEffect(() => {
    if (user) {
      fetch("https://vrco.azurewebsites.net/api/GetData?uid=" + user.uid)
        .then((res) => res.json())
        .then((data) => {
          if (data.termsandconditions) {
            setHasSigned(true);
          }
          if (data.payment) {
            setHasSelectedOption(true);
            setHasPaid(true);
          }
          // if (data.rentals) {
          //   setHasRentalInfo(true);
          // }
          setSeeAgreement(true);
        }).then(() => setIsLoading(false));
      fetch("https://vrco.azurewebsites.net//api/GetRental?uid=" + user.uid)
        .then((res) => res.json())
        .then((data) => {
          setWeeks(data);
        }
        );

      fetch("https://vrco.azurewebsites.net//api/GetPayment?uid=" + user.uid)
        .then((res) => res.json())
        .then((data) => {
          // setAmount(data.value);
          setPlanName(data.plan);
          setPlanPhrase(data.description);
          setPlanUnits(JSON.parse(data.benefits));
          setLimit(data.units);
        }
        );

      fetch("https://vrco.azurewebsites.net//api/GetOnDemand?uid=" + user.uid)
        .then((res) => res.json())
        .then((data) => {
          setOnDemands(data);
        }
        );
    }
  }, [user]);

  useEffect(() => {
    if (seeCalendar) {
      fetch("https://vrco.azurewebsites.net//api/GetDates?uid=" + user.uid)
        .then((res) => res.json())
        .then((data) => {
          setDates(data);
        }
        );
      setDates(dates);
    }
  }, [seeCalendar, dates]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   if (amount) {
  //     if (amount === "1,399" || amount === "1,499" || amount === "1,699") {
  //       setPlanName("Starter Plan");
  //       setPlanPhrase("Best option to promote residences");
  //       setPlanUnits("1 - 2 units");
  //       setLimit(2);
  //     } else if (amount === "1,799" || amount === "1,899" || amount === "1,999.99") {
  //       setPlanName("Pro Plan");
  //       setPlanPhrase("Extended & premium support");
  //       setPlanUnits("3 - 5 units");
  //       setLimit(5);
  //     } else if (amount === "999") {
  //       setPlanName("Limited T.S. Plan");
  //       setPlanPhrase("Basic plan for T.S. properties");
  //       setPlanUnits("1 - 2 units");
  //       setLimit(2);
  //     } else if (amount === "4,999") {
  //       setPlanName("Expert Plan");
  //       setPlanPhrase("High end mansions and villas");
  //       setPlanUnits("Up to 10 units");
  //       setLimit(10);
  //     } else if (amount === "3,000" || amount === "2,999") {
  //       setPlanName("Mixed Pro Plan");
  //       setPlanPhrase("For luxury Residences ");
  //       setPlanUnits("1 - 3 units");
  //       setLimit(3);
  //     } else if (amount === "1,999" || amount === "2,499") {
  //       setPlanName("Standard Plan");
  //       setPlanPhrase("Basic plan for T.S. properties");
  //       setPlanUnits("1 - 2 units");
  //       setLimit(2);
  //     } else if (amount === "2,499.99") {
  //       setPlanName("Extended Plan");
  //       setPlanPhrase("Extended & premium support");
  //       setPlanUnits("3 - 5 units");
  //       setLimit(5);
  //     } else if (amount === "3,499") {
  //       setPlanName("Extended Plan");
  //       setPlanPhrase("For luxury Residences");
  //       setPlanUnits("1 - 3 units");
  //       setLimit(3);
  //     } else if (amount === "7,999") {
  //       setPlanName("Premium Plan");
  //       setPlanPhrase("High end mansions and villas");
  //       setPlanUnits("Up to 3 residences");
  //       setLimit(3);
  //     } else if (amount === "7,999.99" || amount === "9,999") {
  //       setPlanName("Supreme Plan");
  //       setPlanPhrase("High end mansions and villas");
  //       setPlanUnits("Up to 3 residences");
  //       setLimit(3);
  //     } else if (amount === "14,999") {
  //       setPlanName("Expert Priority Plan");
  //       setPlanPhrase("High End Elite Residences")
  //       setPlanUnits("Up to 4 Luxury Residences")
  //       setLimit(4);
  //     } else if (amount === "99,999") {
  //       setPlanName("Unlimited");
  //       setPlanPhrase("Unlimited properties");
  //       setPlanUnits("Unlimited");
  //       setLimit(100);
  //     }
  //   }
  // }, [amount]);

  useEffect(() => {
    if (startDate) {
      // add 7 days to the start date
      const endDate = moment(startDate).add(7, "days").format("YYYY-MM-DD");
      // set the end date
      setEndDate(endDate);
    }
  }, [startDate]);


  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#303f9f',
    },
  };

  const options = {
    clientSecret,
    appearance,
  };


  const getBusyDates = () => {
    const busyDates = {};
    dates.forEach(date => {
      const start = moment(date.start);
      const end = moment(date.end);
      const diff = end.diff(start, "days");
      for (let i = 0; i <= diff; i++) {
        // add the date to the array and add indicator if it is a start or end date
        busyDates[start.format("YYYY-MM-DD")] = i === 0 ? "start" : i === diff ? "end" : "middle";
        start.add(1, "days");
      }
    });
    return busyDates;
  };

  const busyDates = getBusyDates();

  const getMonthDays = (date) => {
    const currentMonth = moment(date).format("MMMM");
    const daysInMonth = moment(date).daysInMonth();
    const startingDay = moment(date)
      .startOf("month")
      .format("dddd")
    const startingDayIndex = moment.weekdays().indexOf(startingDay);
    const calendar = [];
    let month = moment(date).format("MM");
    let year = moment(date).format("YYYY");
    let day = 1;
    for (let i = 0; i < 6; i++) {
      const row = [];
      for (let j = 0; j < 7; j++) {
        if (i === 0 && j < startingDayIndex) {
          row.push(<td className="px-3 py-2 text-gray-400"></td>);
        } else if (day > daysInMonth) {
          break;
        } else {
          const isBusy = busyDates[`${year}-${month}-${day.toString().padStart(2, "0")}`];
          row.push(
            <td className={`${isBusy ? "bg-yellow-400" : ""} ${`${year}-${month}-${day.toString().padStart(2, "0")}` === moment().format("YYYY-MM-DD") ? "text-blue-500 font-bold" : ""} ${isBusy === "start" ? "rounded-l-lg" : ""} ${isBusy === "end" ? "rounded-r-lg" : ""}`}>
              <p className="px-3 py-2">{day}</p>
            </td>
          );
          day++;
        }
      }
      calendar.push(<tr>{row}</tr>);
    }
    return { calendar, currentMonth };
  }

  const monthList = ["2024-01-01", "2024-02-01", "2024-03-01", "2024-04-01", "2024-05-01", "2024-06-01", "2024-07-01", "2024-08-01", "2024-09-01", "2024-10-01", "2024-11-01", "2024-12-01"];

  const getBackgroundResortImage = (selectedResort) => {
    const backgroundResortImages = [
      {
        resort: "The Estates",
        image: "/the-estates.jpg",
      },
      {
        resort: "Grand Luxxe",
        image: "/grand-luxxe.jpg",
      },
      {
        resort: "The Grand Bliss",
        image: "/grand-bliss.jpg",
      },
      {
        resort: "The Grand Mayan",
        image: "/grand-mayan.jpg",
      },
      {
        resort: "The Bliss",
        image: "/bliss.jpg",
      },
      {
        resort: "Mayan Palace",
        image: "/mayan-palace.jpg",
      },
      {
        resort: "Residence At Grand Luxxe",
        image: "/residence.jpg",
      },
      {
        resort: "Le Blanc Los Cabos",
        image: "/residence-los-cabos.jpg",
      },
      {
        resort: "Moon Palace Nizuc",
        image: "residence-nizuc.jpg",
      },
      {
        resort: "Deluxxe At The Grand Mayan (Nuevo Vallarta)",
        image: "deluxxe.jpg",
      },
      {
        resort: "Empire Estates",
        image: "empire-estates.jpg",
      },
      {
        resort: "Park Units",
        image: "park-units.jpg",
      },
      {
        resort: "Kingdom Of The Sun III (Parque)",
        image: "kingdom.jpg",
      }
    ]
    const image = backgroundResortImages.find((resort) => resort.resort === selectedResort)?.image;
    if (image) {
      return image;
    } else {
      return "/soon.jpg";
    }
  }


  return (
    <>
      {isLoading ? (
        <div className="flex h-4/5 ">
          <div className="m-auto">
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#303f9f"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      ) : (
        <div className="flex pb-20 lg:pb-0 bg-zinc-50">
          {/* sidebar */}
          <div className="hidden md:flex flex-col h-[calc(100vh-90px)] p-3 bg-white shadow w-64">
            <div className="space-y-3">
              <div className="flex-1">
                <ul className="pt-2 pb-4 space-y-1 text-sm">
                  <li className="rounded-sm">
                    <button
                      onClick={() => handleDashboardMenu("agreement")}
                      className={`flex w-full items-center p-2 space-x-3 rounded-md cursor-pointer hover:bg-gray-100 ${seeAgreement ? "font-bold bg-gray-200" : ""}`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                        />
                      </svg>
                      <span>Rental Setup</span>
                    </button>
                    <div className="flex items-center gap-2 flex-col pt-1">
                      <li className="rounded-sm w-full pl-4">
                        <div className="flex justify-between">
                          <p className="text-gray-500">Sign Rental Agreement</p>
                          {hasSigned && (
                            <img src="/check.svg" className="" alt="check" />
                          )}
                        </div>
                      </li>
                      <li className="rounded-sm w-full pl-4">
                        <div className="flex justify-between">
                          <p className="text-gray-500">Complete Payment</p>
                          {hasPaid && (
                            <img src="/check.svg" className="" alt="check" />
                          )}
                        </div>
                      </li>
                      {/* <li className="rounded-sm w-full pl-4">
                        <div className="flex justify-between">
                          <p className="text-gray-500">Send Reservation Form</p>
                          {hasRentalInfo && (
                            <img src="/check.svg" className="" alt="check" />
                          )}
                        </div>
                      </li> */}
                    </div>
                  </li>
                  {hasPaid && (
                    <li className="rounded-sm">
                      <button
                        onClick={() => handleDashboardMenu("rentalInfo")}
                        className={`flex w-full items-center p-2 space-x-3 rounded-md cursor-pointer hover:bg-gray-100 ${seeRentalInfo ? "font-bold bg-gray-200" : ""}`}
                      >
                        <BadgeInfo className="w-6 h-6" strokeWidth={seeRentalInfo ? 2 : 1.5} />
                        <span>Rental information</span>
                      </button>
                    </li>
                  )}
                  {hasPaid && (
                    <li className="rounded-sm">
                      <button
                        onClick={() => handleDashboardMenu("weeks")}
                        className={`flex w-full items-center p-2 space-x-3 rounded-md cursor-pointer hover:bg-gray-100 ${seeWeeks ? "font-bold text-blue-500 bg-gray-200" : "text-blue-500"}`}
                      >
                        <Hotel className="w-6 h-6" strokeWidth={seeWeeks ? 2 : 1.5} />
                        <span>My Weeks</span>
                      </button>
                    </li>
                  )}
                  {hasPaid && (
                    <li className="rounded-sm">
                      <button
                        onClick={() => handleDashboardMenu("plan")}
                        className={`flex w-full items-center p-2 space-x-3 rounded-md cursor-pointer hover:bg-gray-100 ${seePlan ? "font-bold bg-gray-200" : ""}`}
                      >
                        <Folder className="w-6 h-6" strokeWidth={seePlan ? 2 : 1.5} />
                        <span>Plan</span>
                      </button>
                    </li>
                  )}
                  {hasPaid && (
                    <li className="rounded-sm">
                      <button
                        onClick={() => handleDashboardMenu("calendar")}
                        className={`flex w-full items-center p-2 space-x-3 rounded-md cursor-pointer hover:bg-gray-100 ${seeCalendar ? "font-bold bg-gray-200" : ""}`}
                      >
                        <CalendarFold className="w-6 h-6" strokeWidth={seeCalendar ? 2 : 1.5} />
                        <span className="text-left">Opportunity Spreadsheet</span>
                      </button>
                    </li>
                  )}
                  {hasPaid && (
                    <div className="w-full justify-center items-center pt-3">
                      <p className="text-center text-gray-600 text-[10px]">Need help? Contact us</p>
                    </div>
                  )}
                  {hasPaid && (
                    <div className="w-full justify-center items-center pt-1">
                      <a href="https://wa.me/18787896440" target="_blank" rel="noreferrer">
                        <img src="/wabutton.png" alt="whatsapp" className="w-8/12 m-auto" />
                      </a>
                    </div>
                  )}
                  {hasPaid && (
                    <div className="flex w-full justify-center items-center gap-2 pt-1">
                      <p className="text-center text-gray-600 text-xs">+1 (878) 789-6440</p>
                      <Copy className="w-2 h-2 text-gray-600 cursor-pointer" onClick={() => navigator.clipboard.writeText("+1 (878) 789-6440")} />
                    </div>
                  )}
                  {/* {hasPaid && (
                    <li className="rounded-sm">
                      <a
                        onClick={() => handleDashboardMenu("faq")}
                        className={`flex items-center p-2 space-x-3 rounded-md cursor-pointer hover:bg-gray-100 ${seeFaq ? "font-bold bg-gray-200" : ""}`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 9c0-3.5 5.5-3.5 5.5 0 0 2.5-2.5 2-2.5 5M12 18.01l.01-.011"
                          />
                        </svg>
                        <span>FAQ</span>
                      </a>
                    </li>
                  )} */}
                </ul>
              </div>
            </div>
          </div>
          <div className="md:hidden">
            <section id="bottom-navigation" className="block fixed inset-x-0 bottom-0 z-10 bg-white shadow">
              <div id="tabs" className="flex justify-between">
                <button onClick={() => handleDashboardMenu("agreement")} className={"flex flex-col w-full justify-start items-center text-center pt-2 pb-1 rounded-md" + (seeAgreement ? " bg-gray-100" : "")}>
                  <Home className="w-6 h-6" strokeWidth={seeAgreement ? 2 : 1.5} />
                  <span className={`tab tab-home block text-xs ${seeAgreement ? "font-bold" : ""}`}>Rental Setup</span>
                </button>
                {hasPaid && (
                  <>
                    <button onClick={() => handleDashboardMenu("rentalInfo")} className={"flex flex-col w-full justify-start items-center text-center pt-2 pb-1 rounded-md" + (seeRentalInfo ? " bg-gray-100" : "")}>
                      <BadgeInfo className="w-6 h-6" strokeWidth={seeRentalInfo ? 2 : 1.5} />
                      <span className="tab tab-whishlist block text-xs">Rental Info</span>
                    </button>
                    <button onClick={() => handleDashboardMenu("weeks")} className={"flex flex-col w-full justify-start items-center text-center pt-2 pb-1 rounded-md" + (seeWeeks ? " bg-gray-100 text-blue-500" : "text-blue-400")}>
                      <Hotel className="w-6 h-6" strokeWidth={seeWeeks ? 2 : 1.5} />
                      <span className="tab tab-explore block text-xs">Weeks</span>
                    </button>
                    <button onClick={() => handleDashboardMenu("plan")} className={"flex flex-col w-full justify-start items-center text-center pt-2 pb-1 rounded-md" + (seePlan ? " bg-gray-100" : "")}>
                      <Folder className="w-6 h-6" strokeWidth={seePlan ? 2 : 1.5} />
                      <span className="tab tab-kategori block text-xs">Plan</span>
                    </button>
                    <button onClick={() => handleDashboardMenu("calendar")} className={"flex flex-col w-full justify-start items-center text-center pt-2 pb-1 rounded-md" + (seeCalendar ? " bg-gray-100" : "")}>
                      <CalendarFold className="w-6 h-6" strokeWidth={seeCalendar ? 2 : 1.5} />
                      <span className="tab tab-kategori block text-xs">Spreadsheet</span>
                    </button>
                  </>
                )}
                {/* <a onClick={() => handleDashboardMenu("faq")} className={"w-full focus:text-teal-500 justify-center inline-block text-center pt-2 pb-1" + (seeFaq ? " text-indigo-500" : "")}>
                  <svg width="25" height="25" className="inline-block mb-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2} >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 9c0-3.5 5.5-3.5 5.5 0 0 2.5-2.5 2-2.5 5M12 18.01l.01-.011"
                    />
                  </svg>
                  <span className="tab tab-account block text-xs">FAQ</span>
                </a> */}
              </div>
            </section>
          </div>
          {seeAgreement && (
            <div className="container mx-auto mt-10">
              {((!isLoading && !hasSigned)) && (
                <div className="terms px-8 md:px-30 xl:px-80 py-8 text-justify">
                  <div className="render">
                    <div className="max-w-2xl mx-auto text-center pb-8">
                      <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">Rental Agreement</h2>
                    </div>
                    <div className="overflow-y-auto h-96 border-2 border-gray-200 rounded-lg p-4 shadow-md">
                      <RentalAgreement />
                    </div>
                    <div className="py-8">
                      <h2 className="text-3xl">Terms and Conditions</h2>
                      <p className="text-justify pt-4">
                        Before you continue, please read the following <Link className="text-blue-600 font-semibold" to="/terms" >Terms and Conditions</Link> and <Link className="text-blue-600 font-semibold" to="/privacy" >Privacy Policy</Link> carefully.
                      </p>
                    </div>
                    <form className="flex flex-col justify-start">
                      <div className="md:flex md:items-center mb-6">
                        <div className="md:w-3/12">
                          <label className="block text-gray-500 font-bold md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                            Full Name
                          </label>
                        </div>
                        <div className="md:w-3/5">
                          <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500" id="inline-full-name" type="text" value={name} onChange={(e) => setName(e.target.value)} autoComplete="name" />
                          {warnings.name && <p className="text-red-500 text-sm pt-0.5">Please enter your full name.</p>}
                        </div>
                      </div>
                      <div className="md:flex md:items-center mb-6">
                        <div className="md:w-3/12">
                          <label className="block text-gray-500 font-bold md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                            Phone
                          </label>
                        </div>
                        <div className="md:w-3/5">
                          <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500" id="inline-phone" type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} autoComplete="tel" />
                          {warnings.phone && <p className="text-red-500 text-sm pt-0.5">Please enter your phone number.</p>}
                        </div>
                      </div>
                      {/* <div className="md:flex md:items-center mb-6">
                        <div className="md:w-3/12">
                          <label className="block text-gray-500 font-bold md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                            Address
                          </label>
                        </div>
                        <div className="md:w-3/5">
                          <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500" id="inline-full-name" type="text" value={address} onChange={(e) => setAddress(e.target.value)} />
                        </div>
                      </div>
                      <div className="md:flex md:items-center mb-6">
                        <div className="md:w-3/12">
                          <label className="block text-gray-500 font-bold md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                            State
                          </label>
                        </div>
                        <div className="md:w-3/5">
                          <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500" id="inline-full-name" type="text" value={state} onChange={(e) => setState(e.target.value)} />
                        </div>
                      </div>
                      <div className="md:flex md:items-center mb-6">
                        <div className="md:w-3/12">
                          <label className="block text-gray-500 font-bold md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                            City
                          </label>
                        </div>
                        <div className="md:w-3/5">
                          <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500" id="inline-full-name" type="text" value={city} onChange={(e) => setCity(e.target.value)} />
                        </div>
                      </div>
                      <div className="md:flex md:items-center mb-6">
                        <div className="md:w-3/12">
                          <label className="block text-gray-500 font-bold md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                            Zip Code
                          </label>
                        </div>
                        <div className="md:w-3/5">
                          <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500" id="inline-full-name" type="text" value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
                        </div>
                      </div> */}
                      <div className="flex flex-col mb-6">
                        <div className="flex items-center">
                          <input className="w-5 h-5 text-blue-600 bg-gray-200 rounded border-gray-400 focus:ring-blue-500 dark:focus:ring-blue-600 focus:ring-2" type="checkbox" id="terms" name="terms" value="terms" checked={checkbox} onChange={handleCheckbox} />
                          <label className="ml-2 font-bold text-gray-900" for="terms" onChange={handleCheckbox}> I accept the rental agreement, the terms and conditions, and the privacy policy.</label>
                        </div>
                        {warnings.checkbox && <p className="text-red-500 text-sm pt-0.5">Please accept the terms and conditions.</p>}
                      </div>
                      <button className="main btn btn-primary" onClick={submitTyC} type="button">
                        Sign
                      </button>
                    </form>
                  </div>
                </div>
              )}
              {hasSigned && !hasSelectedOption && (
                <div className="">
                  <div className="py-8 px-4 mx-auto max-w-screen-xl lg:px-6">
                    <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                      <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">Select the best plan for you</h2>
                      {/* <p className="mb-5 text-gray-500 sm:text-xl">Pay once. Use forever.</p> */}
                    </div>
                    <div className="space-y-8 xl:grid xl:grid-cols-4 lg:grid lg:grid-cols-3 md:grid md:grid-cols-2 sm:gap-6 xl:gap-10 lg:space-y-0 md:space-y-0">
                      {/* <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow xl:p-8">
                        <h3 className="mb-4 text-2xl font-semibold">Limited T.S. Plan</h3>
                        <p className="font-medium text-gray-500 sm:text-lg">Basic plan for T.S. properties</p>
                        <div className="flex justify-center items-baseline my-8">
                          <span className="mr-2 text-5xl font-extrabold">$1,199</span>
                          <span className="text-gray-500">USD</span>
                        </div>
                        <ul role="list" className="mb-8 space-y-4 text-left">
                          <li className="flex items-center space-x-3">
                            <svg className="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                            <span>1 - 2 units</span>
                          </li>
                        </ul>
                        <a onClick={() => selectOption("Limited T.S. Plan", "1,199", "C", "https://buy.stripe.com/dR600W8br3nd5zOfZw")} className="text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-4 focus:ring-indigo-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center cursor-pointer">Select Limited T.S. Plan</a>
                      </div> */}
                      <div className="flex flex-col justify-between p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-200 shadow xl:p-6 gap-8 hover:border-emerald-200 transition-all duration-100 ease-in-out">
                        <div className="flex flex-col items-center h-2/5 mb-4 gap-3">
                          <h3 className="text-3xl font-bold">Standard Plan</h3>
                          <p className="font-medium text-gray-500 sm:text-lg">Basic Plan for T.S. properties.</p>
                        </div>
                        <div className="flex flex-col items-center h-2/5 gap-4">
                          {/* <div className="flex justify-center items-baseline">
                            <span className="mr-2 text-5xl font-extrabold">$2,499</span>
                            <span className="text-gray-500">USD</span>
                          </div> */}
                          <div className="mb-8 space-y-2 text-left">
                            <li className="flex items-center space-x-3">
                              <svg className="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                              <span className="font-normal text-gray-700">MAYAN PALACE & THE BLISS</span>
                            </li>
                            <li className="flex items-center space-x-3">
                              <svg className="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                              <span className="font-normal text-gray-700">1 - 2 weeks</span>
                            </li>
                          </div>
                        </div>
                        <div className="text-white bg-emerald-600 hover:bg-emerald-700 focus:ring-4 focus:ring-indigo-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Standard Plan</div>
                      </div>
                      <div className="flex flex-col justify-between p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-200 shadow xl:p-6 gap-8 hover:border-teal-200 transition-all duration-100 ease-in-out">
                        <div className="flex flex-col items-center h-2/5 mb-4 gap-3">
                          <h3 className="text-3xl font-bold">Extended Plan</h3>
                          <p className="font-medium text-gray-500 sm:text-lg">Extended and premium support.</p>
                        </div>
                        <div className="flex flex-col items-center h-2/5 gap-4">
                          {/* <div className="flex justify-center items-baseline">
                            <span className="mr-2 text-5xl font-extrabold">$4,999</span>
                            <span className="text-gray-500">USD</span>
                          </div> */}
                          <div className="mb-8 space-y-2 text-left">
                            <li className="flex items-center space-x-3">
                              <svg className="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                              <span className="font-normal text-gray-700">GRAND MAYAN & GRAND BLISS & GRAND IMPERIAL & PARK UNITS</span>
                            </li>
                            <li className="flex items-center space-x-3">
                              <svg className="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                              <span className="font-normal text-gray-700">1 - 4 weeks</span>
                            </li>
                          </div>
                        </div>
                        <div className="text-white bg-teal-600 hover:bg-teal-700 focus:ring-4 focus:ring-indigo-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Extended Plan</div>
                      </div>
                      <div className="flex flex-col justify-between p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-200 shadow xl:p-6 gap-8 hover:border-cyan-200 transition-all duration-100 ease-in-out">
                        <div className="flex flex-col items-center h-2/5 mb-4 gap-3">
                          <h3 className="text-3xl font-bold">Premium Plan</h3>
                          <p className="font-medium text-gray-500 sm:text-lg">For luxury properties.</p>
                        </div>
                        <div className="flex flex-col items-center h-2/5 gap-4">
                          {/* <div className="flex justify-center items-baseline">
                            <span className="mr-2 text-5xl font-extrabold">$9,999</span>
                            <span className="text-gray-500">USD</span>
                          </div> */}
                          <div className="mb-8 space-y-2 text-left">
                            <li className="flex items-center space-x-3">
                              <svg className="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                              <span className="font-normal text-gray-700">GRAND LUXXE & KINGDOM OF THE SUN & LUX PROPERTIES</span>
                            </li>
                            <li className="flex items-center space-x-3">
                              <svg className="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                              <span className="font-normal text-gray-700">1 - 2 weeks</span>
                            </li>
                          </div>
                        </div>
                        <div className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-indigo-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Premium Plan</div>
                      </div>
                      <div className="flex flex-col justify-between p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border-2 border-indigo-400 shadow-md shadow-indigo-100 xl:p-6 gap-8 hover:shadow-indigo-300 transition-all duration-100 ease-in-out">
                        <div className="flex flex-col items-center h-2/5 mb-4 gap-3">
                          <div className="py-1 px-2 rounded-full bg-gradient-to-r from-indigo-400 to-indigo-600">
                            <p className="text-white text-xs">Priority</p>
                          </div>
                          <h3 className="text-3xl font-bold">Expert Priority Plan</h3>
                          <p className="font-medium text-gray-500 sm:text-lg">High end mansions and villas.</p>
                        </div>
                        <div className="flex flex-col items-center h-2/5 gap-4">
                          {/* <div className="flex justify-center items-baseline">
                            <span className="mr-2 text-5xl font-extrabold">$14,999</span>
                            <span className="text-gray-500">USD</span>
                          </div> */}
                          <div className="mb-8 space-y-2 text-left">
                            <li className="flex items-center space-x-3">
                              <svg className="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                              <span className="font-normal text-gray-700">RESIDENCES & ESTATES & PRESIDENTIAL & LUX RESIDENCES</span>
                            </li>
                            <li className="flex items-center space-x-3">
                              <svg className="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                              <span className="font-normal text-gray-700">1-5 Residences</span>
                            </li>
                          </div>
                        </div>
                        <div className="text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-4 focus:ring-indigo-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Expert Priority Plan</div>
                      </div>
                    </div>
                    <p className="text-gray-700 font-medium pt-8 text-center">Contact us for more information about the plans at <span className="text-indigo-600 font-bold"> contact@vrco.site</span></p>
                    <p className="text-gray-500 pt-8">* Park Units properties are matched by its equivalents above.</p>
                  </div>
                </div>
              )}
              {((hasSigned && hasSelectedOption && !hasPaid)) && (
                <>
                  <div className="relative flex flex-col justify-center items-center">
                    <button onClick={deselectOption} className="absolute felx justify-center items-center top-4 left-10 bg-gray-200 hover:bg-gray-300 p-2 rounded-md px-4"><Undo2 className=" w-5 h-5 text-gray-700 inline" />
                    </button>
                    <div className="">
                      <div className="info">
                        <h3 className="text-info text-5xl">${optionPrice} USD</h3>
                        <h3 className="text-info py-4">Enrollment fee for {optionName}</h3>
                      </div>
                      {clientSecret && (
                        <Elements options={options} stripe={stripePromise}>
                          <CheckoutForm />
                        </Elements>
                      )}
                      {/* <PayPalScriptProvider options={{
                    "client-id": "AUt3Bf0qOZ_3Ihy-dcrNY77fnaJYrZYHOs_7VUFxXQThkDZGjKlc2BJSlrSQPzTJX1SP0Op9OE9Uo0ju",
                    "currency": "USD"
                  }}>
                    <PayPalButtons
                      createOrder={(data, actions) => {
                        return actions.order.create({
                          purchase_units: [
                            {
                              amount: {
                                value: optionPrice.replace(",", ""),
                              },
                            },
                          ],
                        });
                      }}
                      onApprove={(data, actions) => {
                        return actions.order.capture().then((details) => {
                          const name = details.payer.name.given_name;
                          const email = details.payer.email_address;
                          fetch("https://vrco.azurewebsites.net//api/ConfirmPayment", {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                              uid: user.uid,
                              email: email,
                              payment: optionPrice
                            }),
                          })
                            .then((response) => response.json())
                            .then((data) => {
                              console.log("Success:", data);
                              setHasPaid(true);
                            }
                            );
                        });
                      }}
                    />
                  </PayPalScriptProvider> */}
                      <p className="font-light text-gray-500 py-4">Payment not working? <a href={optionLink} className="text-indigo-600 font-medium text-center cursor-pointer hover:text-indigo-700 text-md" target="_blank" rel="noreferrer">Click here to pay with Stripe</a></p>
                    </div>
                  </div>
                </>
              )}
              {hasSigned && hasPaid && (
                <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                  <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-8">
                    <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                      Thank you for trusting us with your property!
                    </h2>
                    <p className="text-base text-gray-700 md:text-lg pb-2">
                      We want to express our gratitude for subscribing to our promotion platform. We are thrilled to have you as part of our family.
                    </p>
                    <p className="text-base text-gray-700 md:text-lg pb-2">
                      To continue, we encourage you to review the rental information and become familiar with the two options for listing your properties. Once you have a good understanding of these options, you can proceed to the "My Weeks" section.

                    </p>
                    <p className="text-base text-gray-700 md:text-lg">
                      If you have any questions or concerns, please do not hesitate to contact us at <a className="text-indigo-600 font-bold" href="mailto:contact@vrco.site">contact@vrco.site</a>
                    </p>

                    <p className="text-base text-gray-700 md:text-lg font-semibold pt-4"> <span className="text-indigo-600 font-bold">Important: </span>
                      To ensure important emails don't end up in your spam folder, remember to check it regularly and add us to your favorites or contacts list to easily access our emails and prevent them from being marked as spam
                    </p>
                  </div>
                  <div id="overview" className="scroll-mt-8">
                    <div className="mt-8 grid gap-4 md:grid-cols-2 lg:grid-cols-4">
                      {/* Rental Information: Find service details and frequently asked questions here.
My Weeks: Upload your properties for promotion here.
My Plan: View your rental agreement and active plan here.
Opportunity Spreadsheet: See the fixed dates reserved by other clients. This is for informational purposes only. */}

                      <ResponsesFeature className={clsx("transition-all delay-150 duration-[--duration] cursor-pointer")} color="bg-emerald-500" onClick={() => handleDashboardMenu("rentalInfo")}>
                        <div className="flex items-center justify-center py-6">
                          <Info className="relative h-10 w-10 fill-orange-400/10 stroke-[1.5] text-zinc-100" />
                        </div>
                        <div className="mt-2">
                          <div className="text-lg text-white">Rental Information</div>
                          <p className="mt-1 text-sm font-light leading-relaxed text-white/75">Find service details and frequently asked questions here.</p>
                        </div>
                      </ResponsesFeature>
                      <ResponsesFeature className={clsx("transition-all delay-150 duration-[--duration] cursor-pointer")} color="bg-cyan-500" onClick={() => handleDashboardMenu("weeks")}>
                        <div className="flex items-center justify-center py-6">
                          <Hotel className="relative h-10 w-10 fill-orange-400/10 stroke-[1.5] text-zinc-100" />
                        </div>
                        <div className="mt-2">
                          <div className="text-lg text-white">My Weeks</div>
                          <p className="mt-1 text-sm font-light leading-relaxed text-white/75">Upload your properties for promotion here.</p>
                        </div>
                      </ResponsesFeature>
                      <ResponsesFeature className={clsx("transition-all delay-150 duration-[--duration] cursor-pointer")} color="bg-indigo-500" onClick={() => handleDashboardMenu("plan")}>
                        <div className="flex items-center justify-center py-6">
                          <Folder className="relative h-10 w-10 fill-orange-400/10 stroke-[1.5] text-zinc-100" />
                        </div>
                        <div className="mt-2">
                          <div className="text-lg text-white">My Plan</div>
                          <p className="mt-1 text-sm font-light leading-relaxed text-white/75">View your rental agreement and active plan here.</p>
                        </div>
                      </ResponsesFeature>
                      <ResponsesFeature className={clsx("transition-all delay-150 duration-[--duration] cursor-pointer")} color="bg-violet-500" onClick={() => handleDashboardMenu("calendar")}>
                        <div className="flex items-center justify-center py-6">
                          <Calendar className="relative h-10 w-10 fill-orange-400/10 stroke-[1.5] text-zinc-100" />
                        </div>
                        <div className="mt-2">
                          <div className="text-lg text-white">Opportunity Spreadsheet</div>
                          <p className="mt-1 text-sm font-light leading-relaxed text-white/75">See the fixed dates reserved by other clients. This is for informational purposes only.</p>
                        </div>
                      </ResponsesFeature>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {seePlan && (
            <section className="py-10 w-full">
              <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                <div className="max-w-2xl mx-auto text-center">
                  <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">Plan Details</h2>
                </div>
                <div className="terms px-8 md:px-30 xl:px-60">
                  {/* <h2 className="text-2xl font-bold leading-tight text-black sm:text-2xl lg:text-3xl">Your Plan</h2> */}
                  <div className="px-4 py-6 mx-auto max-w-screen-xl">
                    <div className="space-y-8 gap-6 py-4 lg:space-y-0 md:space-y-0">
                      <div className={`flex flex-col gap-6 justify-center items-center p-6 mx-auto max-w-lg text-center rounded-lg 
                        ${planName === "Starter Plan" || planName === "Pro Plan" || planName === "Limited T.S. Plan" || planName === "Standard Plan" ? "py-10 bg-gradient-to-br from-green-50 to-emerald-50 border-2 border-emerald-500" :
                          planName === "Expert Plan" || planName === "Mixed Pro Plan" || planName === "Extended Plan" ? "py-10 bg-gradient-to-br from-emerald-50 to-teal-50 border-2 border-teal-500" :
                            planName === "Premium Plan" ? "py-10 bg-gradient-to-br from-teal-50 to-cyan-50 border-2 border-cyan-500" :
                              planName === "Supreme Plan" || planName === "Expert Priority Plan" ? "bg-gradient-to-br from-blue-50 to-indigo-50 border-2 border-indigo-500" :
                                "py-10 border-2 border-gray-400"}
                        shadow-lg shadow-gray-300`}>
                        <div className="flex flex-col items-center gap-1">
                          {planName === "Expert Priority Plan" && (
                            <div className="py-1 px-2 rounded-full bg-gradient-to-r from-indigo-400 to-indigo-600">
                              <p className="text-white text-xs">Priority</p>
                            </div>
                          )}
                          <h3 className="text-[27px] font-semibold">{planName}</h3>
                          <p className="text-gray-600 font-medium sm:text-lg ">{planPhrase}</p>
                        </div>
                        <div className="flex justify-center items-baseline">
                          <div role="list" className="space-y-4 text-left">
                            {planUnits.map((unit, index) => (
                              <li className="flex items-center space-x-3" key={index}>
                                <svg className="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                <span className="text-gray-700 font-medium">{unit}</span>
                              </li>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="max-w-2xl mx-auto text-center pb-8">
                      <p className="">To upgrade your plan please contact us at <a className="text-indigo-600 font-bold" href="mailto: contact@vrco.site"> contact@vrco.site</a></p>
                    </div>
                  </div>

                  <div className="max-w-3xl mx-auto pb-5 gap-2">
                    <h2 className="text-2xl text-center font-bold leading-tight text-black sm:text-2xl lg:text-3xl">Rental Agreement</h2>
                    <p className="text-gray-600">By using our services, you agree to our Terms and Conditions, Privacy Policy, and Rental Agreement. Any modifications to these documents will be notified to you via email.</p>
                  </div>
                  <div className="overflow-y-auto h-96 border-2 border-gray-400 rounded-lg p-6 shadow-md">
                    <RentalAgreement />
                  </div>
                </div>
              </div>
            </section>
          )}
          {seeWeeks && (
            <section className="py-10 w-full">
              <Modal isOpen={modalIsOpen} onRequestClose={() => setIsOpen(false)} className="relative bg-white rounded-lg mx-auto mt-10 p-6 max-w-5xl h-[90%] overflow-y-auto border border-gray-400">
                <div className="px-2 md:px-16">
                  <div className="rental-info">
                    <div className="absolute top-3 right-3">
                      <button
                        className="close cursor-pointer p-1 focus:outline-none"
                        onClick={() => setIsOpen(false)}
                      >
                        <svg
                          className="w-8 h-8 text-gray-700 hover:text-red-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 9.292L5.858 5.15 4.444 6.564 8.586 10.707 4.444 14.85l1.414 1.414L10 12.121l4.142 4.143 1.414-1.414L11.414 10.707 15.556 6.564 14.142 5.15 10 9.292z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>
                    <h2 className="text-2xl font-bold leading-tight text-black sm:text-2xl lg:text-3xl py-8">Add Booked Week</h2>
                    <div className="md:flex md:items-center mb-6">
                      <div className="md:w-3/12">
                        <label className="text-gray-600 block md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                          Resort
                        </label>
                      </div>
                      <div className="md:w-8/12">
                        <select
                          id="resort"
                          value={selectedResort}
                          onChange={(e) => setSelectedResort(e.target.value)}
                          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
                        >
                          <option value="">Select a resort</option>
                          {resorts.map((resort, index) => (
                            <option key={index} value={resort}>
                              {resort}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="md:flex md:items-center mb-6">
                      <div className="md:w-3/12">
                        <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                          Property
                        </label>
                      </div>
                      <div className="md:w-8/12 relative">
                        <input
                          id="name"
                          value={selectedName}
                          onChange={(e) => setSelectedName(e.target.value)}
                          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500 disabled:opacity-50"
                          list="name-datalist"
                          disabled={!selectedResort}
                          autoComplete="off"
                          placeholder="Type your property"
                          onFocus={() => setInputFocused(true)}
                          onBlur={() => setInputFocused(false)}
                        />
                        <datalist id="name-datalist">
                          {names.map((name, index) => (
                            <option key={index} value={name} />
                          ))}
                        </datalist>
                        {!inputFocused && !selectedName && (
                          <span className="absolute right-0 bottom-0 mb-1 mr-1 text-xs text-gray-500">If your property is not on the list you can type it</span>
                        )}
                      </div>
                    </div>
                    <div className="md:flex md:items-center mb-6">
                      <div className="md:w-3/12">
                        <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                          Destination
                        </label>
                      </div>
                      <div className="md:w-8/12 relative">
                        <select
                          id="destination"
                          value={selectedDestination}
                          onChange={(e) => setSelectedDestination(e.target.value)}
                          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500 disabled:opacity-50"
                          disabled={!selectedResort || !selectedName}
                        >
                          <option value="">Select a destination</option>
                          {destinations.map((destination, index) => (
                            <option key={index} value={destination}>
                              {destination}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="md:flex md:items-center mb-6">
                      <div className="md:w-3/12">
                        <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                          Start Date
                        </label>
                      </div>
                      <div className="md:w-8/12 relative">
                        <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500" id="inline-full-name" type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                      </div>
                    </div>
                    <div className="md:flex md:items-center mb-6">
                      <div className="md:w-3/12">
                        <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                          End Date
                        </label>
                      </div>
                      <div className="md:w-8/12 relative">
                        <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500" id="inline-full-name" type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} disabled={true} />
                      </div>
                    </div>
                    <div className="md:flex md:items-center mb-6">
                      <div className="md:w-3/12">
                        <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                          Usage fee
                        </label>
                      </div>
                      <div className="md:w-8/12 relative">
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <span className="text-gray-600">$</span>
                          </div>
                          <input
                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 pl-8 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
                            id="usage-fee"
                            placeholder="Enter your usage fee as specified in the contract."
                            type="text"
                            value={formattedUsageFee}
                            onChange={(e) => {
                              const value = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
                              if (value === "") {
                                setUsageFee("");
                                setFormattedUsageFee("");
                              } else {
                                setUsageFee(value);
                                setFormattedUsageFee(formatUsageFee(value));
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col lg:flex-row justify-start items-start w-full gap-4">
                      <div className="flex flex-col md:w-full lg:w-5/12">
                        <p className="block text-gray-600 mb-1 md:mb-0 pt-4 pb-3">
                          Select your amenities privileges
                        </p>
                        <div className="flex flex-col px-6 pb-6">
                          <label className="inline-flex items-center py-2">
                            <input className="form-checkbox text-indigo-600" type="checkbox" id="resort" name="Resort Platinum Status" value="Resort Platinum Status" onChange={handleAmenities} />
                            <span className="ml-2">Resort Platinum Status</span>
                          </label>
                          <label className="inline-flex items-center py-2">
                            <input type="checkbox" className="form-checkbox text-indigo-600" id="2for1" name="2 for 1 - 50 minutes Spa massages" value="2 for 1 - 50 minutes Spa massages" onChange={handleAmenities} />
                            <span className="ml-2">2 for 1 - 50 minutes Spa massages</span>
                          </label>
                          <label className="inline-flex items-center py-2">
                            <input type="checkbox" className="form-checkbox text-indigo-600" id="massages" name="Free Massages" value="Free Massages" onChange={handleAmenities} />
                            <span className="ml-2">Free Massages</span>
                          </label>
                          <label className="inline-flex items-center py-2">
                            <input type="checkbox" id="golf" name="Discounted Rounds of Golf" value="Discounted Rounds of Golf" onChange={handleAmenities} className="form-checkbox text-indigo-600" />
                            <span className="ml-2">Discounted Rounds of Golf</span>
                          </label>
                          <label className="inline-flex items-center py-2">
                            <input type="checkbox" id="golf1" name="2 to 16 Free Rounds of Golf" value="2 to 16 Free Rounds of Golf" onChange={handleAmenities} className="form-checkbox text-indigo-600" />
                            <span className="ml-2">2 to 16 Free Rounds of Golf</span>
                          </label>
                          <label className="inline-flex items-center py-2">
                            <input type="checkbox" id="gondola" name="Access to Air Gondola" value="Access to Air Gondola" onChange={handleAmenities} className="form-checkbox text-indigo-600" />
                            <span className="ml-2">Access to Air Gondola</span>
                          </label>
                          {customAmenities.map((amenity, index) => (
                            <div className="inline-flex items-center py-2" key={index}>
                              <input
                                type="checkbox"
                                id={`custom-${index}`}
                                name={amenity.name}
                                value={amenity.name}
                                checked={amenity.checked}
                                onChange={(e) => handleCustomAmenitiesCheck(e, index)}
                                className="form-checkbox text-indigo-600"
                              />
                              <span className="ml-2">{amenity.name}</span>
                              <button
                                className="ml-4 text-gray-400 hover:text-red-800 transition duration-150 ease-in-out focus:outline-none"
                                onClick={() => deleteCustomAmenity(index)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-4 w-4"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M10 9.293l5.707-5.707a1 1 0 011.414 1.414L11.414 10l5.707 5.707a1 1 0 01-1.414 1.414L10 11.414l-5.707 5.707a1 1 0 01-1.414-1.414l5.707-5.707-5.707-5.707a1 1 0 011.414-1.414L10 9.293z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </button>
                            </div>
                          ))}
                          <div className="md:flex md:items-center mb-6">
                            {/* <div className="md:w-3/12">
                              <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" htmlFor="custom-amenity">
                                Add Amenity
                              </label>
                            </div> */}
                            <div className="relative flex flex-row w-full gap-1">
                              <input
                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
                                id="custom-amenity"
                                type="text"
                                placeholder="Add custom amenity and press Enter"
                                onKeyPress={handleAddCustomAmenity}
                              />
                              <button
                                className="p-2 text-xs bg-gray-300 text-gray-600 hover:text-gray-800 focus:outline-none transition duration-150 ease-in-out px-2 py-1 rounded-md hover:bg-gray-400 z-10"
                                onClick={handleAddCustomAmenity}
                              >
                                <Plus className="w-4 h-4 mx-1" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col w-full lg:w-6/12">
                        <div className="md:flex flex-col md:items-start mb-6">
                          <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pt-4 pr-4 pb-3" htmlFor="comment-section">
                            Comments (Optional)
                          </label>
                          <div className="md:w-full pl-6">
                            <textarea
                              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500 h-48"
                              id="comment-section"
                              rows="5"
                              placeholder="Enter your comments here..."
                              value={comments}
                              onChange={(e) => setComments(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <p className="block text-gray-600 md:text-center mb-1 md:mb-0 pt-4 pb-4">
                      Upload here the confirmation of your reservation.
                    </p>
                    <input className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer focus:outline-none" type="file" id="file" name="file" accept="image/*" onChange={handleFile} />
                    <br /> */}

                    <button className="main btn btn-primary" onClick={submitRentalModal} disabled={!(selectedResort && selectedName && selectedDestination && startDate && endDate && usageFee)}>
                      Send
                    </button>
                  </div>
                </div>
              </Modal>
              <Modal isOpen={newModalIsOpen} onRequestClose={() => setNewIsOpen(false)} className="relative bg-white rounded-lg mx-auto mt-10 p-6 max-w-5xl h-[90%] overflow-y-auto border border-gray-400" >
                <div className="px-2 md:px-16">
                  <div className="rental-info">
                    <div className="flex justify-end">
                      <div className="absolute top-3 right-3">
                        <button
                          className="close cursor-pointer p-1 focus:outline-none"
                          onClick={() => setNewIsOpen(false)}
                        >
                          <svg
                            className="w-8 h-8 text-gray-700 hover:text-red-500"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 9.292L5.858 5.15 4.444 6.564 8.586 10.707 4.444 14.85l1.414 1.414L10 12.121l4.142 4.143 1.414-1.414L11.414 10.707 15.556 6.564 14.142 5.15 10 9.292z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <h2 className="text-2xl font-bold leading-tight text-black sm:text-2xl lg:text-3xl py-8">Add On-Demand Rental</h2>
                    <div className="md:flex md:items-center mb-6">
                      <div className="md:w-3/12">
                        <label className="text-gray-600 block md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                          Resort
                        </label>
                      </div>
                      <div className="md:w-8/12">
                        <select
                          id="resort"
                          value={selectedOnDemandResort}
                          onChange={(e) => setSelectedOnDemandResort(e.target.value)}
                          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
                        >
                          <option value="">Select a resort</option>
                          {onDemandResorts.map((resort, index) => (
                            <option key={index} value={resort}>
                              {resort}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="md:flex md:items-center mb-6">
                      <div className="md:w-3/12">
                        <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                          Property
                        </label>
                      </div>
                      <div className="md:w-8/12 relative">
                        <input
                          id="name"
                          value={selectedOnDemandName}
                          onChange={(e) => setSelectedOnDemandName(e.target.value)}
                          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500 disabled:opacity-50"
                          list="name-datalist"
                          disabled={!selectedOnDemandResort}
                          autoComplete="off"
                          placeholder="Type your property"
                          onFocus={() => setInputFocused(true)}
                          onBlur={() => setInputFocused(false)}
                        />
                        <datalist id="name-datalist">
                          {onDemandNames.map((name, index) => (
                            <option key={index} value={name} />
                          ))}
                        </datalist>
                        {!inputFocused && !selectedOnDemandName && (
                          <span className="absolute right-0 bottom-0 mb-1 mr-1 text-xs text-gray-500">If your property is not on the list you can type it</span>
                        )}
                      </div>
                    </div>
                    <div className="md:flex md:items-center mb-6">
                      <div className="md:w-3/12">
                        <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                          Year
                        </label>
                      </div>
                      <div className="md:w-8/12 relative">
                        <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500" id="inline-full-name" type="number" placeholder="Select a year" value={onDemandYear} onChange={(e) => setOnDemandYear(e.target.value)} />
                      </div>
                    </div>
                    <div className="md:flex md:items-center mb-6">
                      <div className="md:w-3/12">
                        <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                          Number of weeks
                        </label>
                      </div>
                      <div className="md:w-8/12 relative">
                        <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500" id="inline-full-name" type="number" placeholder="Number of weeks" value={onDemandWeeks} onChange={(e) => setOnDemandWeeks(e.target.value)} />
                      </div>
                    </div>
                    <div className="md:flex md:items-center mb-6">
                      <div className="md:w-3/12">
                        <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                          Usage fee
                        </label>
                      </div>
                      <div className="md:w-8/12 relative">
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <span className="text-gray-600">$</span>
                          </div>
                          <input
                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 pl-8 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
                            id="usage-fee"
                            type="text"
                            placeholder="Enter your usage fee as specified in the contract."
                            value={onDemandFormattedUsageFee}
                            onChange={(e) => {
                              const value = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
                              if (value === "") {
                                setOnDemandUsageFee("");
                                setOnDemandFormattedUsageFee("");
                              } else {
                                setOnDemandUsageFee(value);
                                setOnDemandFormattedUsageFee(formatUsageFee(value));
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="md:flex md:items-start mb-6">
                      <div className="md:w-3/12">
                        <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4 pt-1.5" htmlFor="sister-resorts">
                          Sister Resorts
                        </label>
                      </div>
                      <div className="md:w-8/12 relative">
                        <Select
                          isMulti
                          options={propertiesList}
                          value={sisterResorts}
                          onChange={handleSisterResorts}
                          className="basic-multi-select bg-gray-100"
                          classNamePrefix="select"
                        />
                        <p className="text-gray-500 text-sm pt-2">Enter all sister resorts listed in your contract to see more options available to your unit and receive offers.</p>
                      </div>
                    </div>
                    {sisterResorts.length > 0 && (
                      <div className="md:flex md:items-center mb-6 flex-col w-9/12 mx-auto">
                        <p className="block text-gray-600 md:text-center mb-1 md:mb-0 pt-4 pb-3">
                          Note: Attach a photo of the sister resorts / addendums. We do not need any data or information of your contract, we only need a photo of the sister resorts section to provide you with a better service. (Optional)
                        </p>
                        <input
                          className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer focus:outline-none"
                          type="file"
                          id="sister-resorts-file"
                          name="sister-resorts-file"
                          accept="image/*"
                          onChange={handleSisterResortsFile}
                        />
                      </div>
                    )}
                    <div className="flex flex-col lg:flex-row justify-start items-start w-full gap-4">
                      <div className="flex flex-col w-full lg:w-5/12">
                        <p className="block text-gray-600 mb-1 md:mb-0 pt-4 pb-3">
                          Select your amenities privileges
                        </p>
                        <div className="flex flex-col px-6 pb-6">
                          <label className="inline-flex items-center py-2">
                            <input className="form-checkbox text-indigo-600" type="checkbox" id="resort" name="Resort Platinum Status" value="Resort Platinum Status" onChange={handleOnDemandAmenities} />
                            <span className="ml-2">Resort Platinum Status</span>
                          </label>
                          <label className="inline-flex items-center py-2">
                            <input type="checkbox" className="form-checkbox text-indigo-600" id="2for1" name="2 for 1 - 50 minutes Spa massages" value="2 for 1 - 50 minutes Spa massages" onChange={handleOnDemandAmenities} />
                            <span className="ml-2">2 for 1 - 50 minutes Spa massages</span>
                          </label>
                          <label className="inline-flex items-center py-2">
                            <input type="checkbox" className="form-checkbox text-indigo-600" id="massages" name="Free Massages" value="Free Massages" onChange={handleOnDemandAmenities} />
                            <span className="ml-2">Free Massages</span>
                          </label>
                          <label className="inline-flex items-center py-2">
                            <input type="checkbox" id="golf" name="Discounted Rounds of Golf" value="Discounted Rounds of Golf" onChange={handleOnDemandAmenities} className="form-checkbox text-indigo-600" />
                            <span className="ml-2">Discounted Rounds of Golf</span>
                          </label>
                          <label className="inline-flex items-center py-2">
                            <input type="checkbox" id="golf1" name="2 to 16 Free Rounds of Golf" value="2 to 16 Free Rounds of Golf" onChange={handleOnDemandAmenities} className="form-checkbox text-indigo-600" />
                            <span className="ml-2">2 to 16 Free Rounds of Golf</span>
                          </label>
                          <label className="inline-flex items-center py-2">
                            <input type="checkbox" id="gondola" name="Access to Air Gondola" value="Access to Air Gondola" onChange={handleOnDemandAmenities} className="form-checkbox text-indigo-600" />
                            <span className="ml-2">Access to Air Gondola</span>
                          </label>
                          {onDemandCustomAmenities.map((amenity, index) => (
                            <div className="inline-flex items-center py-2" key={index}>
                              <input
                                type="checkbox"
                                id={`custom-${index}`}
                                name={amenity.name}
                                value={amenity.name}
                                checked={amenity.checked}
                                onChange={(e) => handleOnDemandCustomAmenitiesCheck(e, index)}
                                className="form-checkbox text-indigo-600"
                              />
                              <span className="ml-2">{amenity.name}</span>
                              <button
                                className="ml-4 text-gray-400 hover:text-red-800 transition duration-150 ease-in-out focus:outline-none"
                                onClick={() => deleteOnDemandCustomAmenity(index)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-4 w-4"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M10 9.293l5.707-5.707a1 1 0 011.414 1.414L11.414 10l5.707 5.707a1 1 0 01-1.414 1.414L10 11.414l-5.707 5.707a1 1 0 01-1.414-1.414l5.707-5.707-5.707-5.707a1 1 0 011.414-1.414L10 9.293z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </button>
                            </div>
                          ))}
                          <div className="md:flex md:items-center mb-6">
                            {/* <div className="md:w-3/12">
                              <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" htmlFor="custom-amenity">
                                Add Amenity
                              </label>
                            </div> */}
                            <div className="relative flex flex-row w-full gap-1">
                              <input
                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
                                id="custom-amenity"
                                type="text"
                                placeholder="Add custom amenity and press Enter"
                                onKeyPress={handleOnDemandAddCustomAmenity}
                              />
                              <button
                                className="p-2 text-xs bg-gray-300 text-gray-600 hover:text-gray-800 focus:outline-none transition duration-150 ease-in-out px-2 py-1 rounded-md hover:bg-gray-400 z-10"
                                onClick={handleOnDemandAddCustomAmenity}
                              >
                                <Plus className="w-4 h-4 mx-1" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col w-full lg:w-6/12">
                        <div className="md:flex flex-col md:items-start mb-6">
                          <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pt-4 pr-4 pb-3" htmlFor="comment-section">
                            Comments (Optional)
                          </label>
                          <div className="md:w-full pl-6">
                            <textarea
                              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500 h-48"
                              id="comment-section"
                              rows="5"
                              placeholder="Enter your comments here..."
                              value={onDemandComments}
                              onChange={(e) => setOnDemandComments(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <button className="main btn btn-primary" onClick={submitOnDemandModal} disabled={!(selectedOnDemandResort && selectedOnDemandName && onDemandYear && onDemandWeeks && onDemandUsageFee)}>
                      Send
                    </button>
                  </div>
                </div>
              </Modal>
              <Modal isOpen={editModalRentalIsOpen} onRequestClose={() => setEditRentalIsOpen(false)} className="relative bg-white rounded-lg mx-auto mt-10 p-6 max-w-5xl h-[90%] overflow-y-auto border border-gray-400">
                <div className="px-2 md:px-16">
                  <div className="rental-info">
                    <div className="flex justify-end">
                      <div className="absolute top-3 right-3">
                        <button
                          className="close cursor-pointer p-1 focus:outline-none"
                          onClick={() => setEditRentalIsOpen(false)}
                        >
                          <svg
                            className="w-8 h-8 text-gray-700 hover:text-red-500"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 9.292L5.858 5.15 4.444 6.564 8.586 10.707 4.444 14.85l1.414 1.414L10 12.121l4.142 4.143 1.414-1.414L11.414 10.707 15.556 6.564 14.142 5.15 10 9.292z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <h2 className="text-2xl font-bold leading-tight text-black sm:text-2xl lg:text-3xl py-8">Edit Booked Week</h2>
                    <div className="md:flex md:items-center mb-6">
                      <div className="md:w-3/12">
                        <label className="text-gray-600 block md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                          Resort
                        </label>
                      </div>
                      <div className="md:w-8/12">
                        <select
                          id="resort"
                          value={editResort}
                          onChange={(e) => setEditResort(e.target.value)}
                          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
                        >
                          <option value="">Select a resort</option>
                          {resorts.map((resort, index) => (
                            <option key={index} value={resort}>
                              {resort}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="md:flex md:items-center mb-6">
                      <div className="md:w-3/12">
                        <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                          Property
                        </label>
                      </div>
                      <div className="md:w-8/12 relative">
                        <input
                          id="name"
                          value={editName}
                          onChange={(e) => setEditName(e.target.value)}
                          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500 disabled:opacity-50"
                          list="name-datalist"
                          disabled={!editResort}
                          autoComplete="off"
                          placeholder="Select a property"
                          onFocus={() => setInputFocused(true)}
                          onBlur={() => setInputFocused(false)}
                        />
                        <datalist id="name-datalist">
                          {names.map((name, index) => (
                            <option key={index} value={name} />
                          ))}
                        </datalist>
                        {!inputFocused && !editName && (
                          <span className="absolute right-0 bottom-0 mb-1 mr-1 text-xs text-gray-500">If your property is not on the list you can type it</span>
                        )}
                      </div>
                    </div>
                    <div className="md:flex md:items-center mb-6">
                      <div className="md:w-3/12">
                        <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                          Destination
                        </label>
                      </div>
                      <div className="md:w-8/12 relative">
                        <select
                          id="destination"
                          value={editDestination}
                          onChange={(e) => setEditDestination(e.target.value)}
                          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500 disabled:opacity-50"
                          disabled={!editResort || !editName}
                        >
                          <option value="">Select a destination</option>
                          {destinations.map((destination, index) => (
                            <option key={index} value={destination}>
                              {destination}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="md:flex md:items-center mb-6">
                      <div className="md:w-3/12">
                        <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                          Start Date
                        </label>
                      </div>
                      <div className="md:w-8/12 relative">
                        <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500" id="inline-full-name" type="date" value={editStartDate} onChange={(e) => setEditStartDate(e.target.value)} />
                      </div>
                    </div>
                    <div className="md:flex md:items-center mb-6">
                      <div className="md:w-3/12">
                        <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                          End Date
                        </label>
                      </div>
                      <div className="md:w-8/12 relative">
                        <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500" id="inline-full-name" type="date" value={editEndDate} onChange={(e) => setEditEndDate(e.target.value)} />
                      </div>
                    </div>
                    <div className="md:flex md:items-center mb-6">
                      <div className="md:w-3/12">
                        <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                          Usage fee
                        </label>
                      </div>
                      <div className="md:w-8/12 relative">
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <span className="text-gray-600">$</span>
                          </div>
                          <input
                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 pl-8 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
                            id="usage-fee"
                            type="text"
                            value={editFormattedUsageFee}
                            onChange={(e) => {
                              const value = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
                              if (value === "") {
                                setEditUsageFee("");
                                setEditFormattedUsageFee("");
                              } else {
                                setEditUsageFee(value);
                                setEditFormattedUsageFee(formatUsageFee(value));
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col lg:flex-row justify-start items-start w-full gap-4">
                      <div className="flex flex-col w-full lg:w-5/12">
                        <p className="block text-gray-600 mb-1 md:mb-0 pt-4 pb-3">
                          Select your amenities privileges
                        </p>
                        <div className="flex flex-col px-6 pb-6">
                          <label className="inline-flex items-center py-2">
                            <input className="form-checkbox text-indigo-600" type="checkbox" id="resort" name="Resort Platinum Status" value="Resort Platinum Status" onChange={handleRentalAmenities} checked={editAmenities.includes("Resort Platinum Status")} />
                            <span className="ml-2">Resort Platinum Status</span>
                          </label>
                          <label className="inline-flex items-center py-2">
                            <input type="checkbox" className="form-checkbox text-indigo-600" id="2for1" name="2 for 1 - 50 minutes Spa massages" value="2 for 1 - 50 minutes Spa massages" onChange={handleRentalAmenities} checked={editAmenities.includes("2 for 1 - 50 minutes Spa massages")} />
                            <span className="ml-2">2 for 1 - 50 minutes Spa massages</span>
                          </label>
                          <label className="inline-flex items-center py-2">
                            <input type="checkbox" className="form-checkbox text-indigo-600" id="massages" name="Free Massages" value="Free Massages" onChange={handleRentalAmenities} checked={editAmenities.includes("Free Massages")} />
                            <span className="ml-2">Free Massages</span>
                          </label>
                          <label className="inline-flex items-center py-2">
                            <input type="checkbox" id="golf" name="Discounted Rounds of Golf" value="Discounted Rounds of Golf" onChange={handleRentalAmenities} className="form-checkbox text-indigo-600" checked={editAmenities.includes("Discounted Rounds of Golf")} />
                            <span className="ml-2">Discounted Rounds of Golf</span>
                          </label>
                          <label className="inline-flex items-center py-2">
                            <input type="checkbox" id="golf1" name="2 to 16 Free Rounds of Golf" value="2 to 16 Free Rounds of Golf" onChange={handleRentalAmenities} className="form-checkbox text-indigo-600" checked={editAmenities.includes("2 to 16 Free Rounds of Golf")} />
                            <span className="ml-2">2 to 16 Free Rounds of Golf</span>
                          </label>
                          <label className="inline-flex items-center py-2">
                            <input type="checkbox" id="gondola" name="Access to Air Gondola" value="Access to Air Gondola" onChange={handleRentalAmenities} className="form-checkbox text-indigo-600" checked={editAmenities.includes("Access to Air Gondola")} />
                            <span className="ml-2">Access to Air Gondola</span>
                          </label>
                          {editCustomAmenities.map((amenity, index) => (
                            <>
                              {amenity.name !== "" && (
                                <div className="inline-flex items-center py-2" key={index}>
                                  <input
                                    type="checkbox"
                                    id={`custom-${index}`}
                                    name={amenity.name}
                                    value={amenity.name}
                                    checked={amenity.checked}
                                    onChange={(e) => editHandleCustomAmenitiesCheck(e, index)}
                                    className="form-checkbox text-indigo-600"
                                  />
                                  <span className="ml-2">{amenity.name}</span>
                                  <button
                                    className="ml-4 text-gray-400 hover:text-red-800 transition duration-150 ease-in-out focus:outline-none"
                                    onClick={() => editDeleteCustomAmenity(index)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-4 w-4"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M10 9.293l5.707-5.707a1 1 0 011.414 1.414L11.414 10l5.707 5.707a1 1 0 01-1.414 1.414L10 11.414l-5.707 5.707a1 1 0 01-1.414-1.414l5.707-5.707-5.707-5.707a1 1 0 011.414-1.414L10 9.293z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              )}
                            </>
                          ))}
                          <div className="md:flex md:items-center mb-6">
                            {/* <div className="md:w-3/12">
                              <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" htmlFor="custom-amenity">
                                Add Amenity
                              </label>
                            </div> */}
                            <div className="relative flex flex-row w-full gap-1">
                              <input
                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
                                id="custom-amenity"
                                type="text"
                                placeholder="Type custom amenity and press Enter"
                                onKeyPress={handleEditAddCustomAmenity}
                              />
                              <button
                                className="p-2 text-xs bg-gray-300 text-gray-600 hover:text-gray-800 focus:outline-none transition duration-150 ease-in-out px-2 py-1 rounded-md hover:bg-gray-400 z-10"
                                onClick={handleEditAddCustomAmenity}
                              >
                                <Plus className="w-4 h-4 mx-1" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col w-full lg:w-6/12">
                        <div className="md:flex md:items-start mb-6">
                          <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pt-4 pr-4 pb-3" htmlFor="comment-section">
                            Comments (Optional)
                          </label>
                          <div className="md:w-full pl-6">
                            <textarea
                              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500 h-48"
                              id="comment-section"
                              rows="5"
                              placeholder="Enter your comments here..."
                              value={editComments}
                              onChange={(e) => setEditComments(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="block text-gray-600 md:text-center mb-1 md:mb-0 pt-4 pb-3">
                      Upload here the confirmation of your reservation.
                    </p>
                    <input className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer focus:outline-none" type="file" id="file" name="file" accept="image/*" onChange={handleEditFile} />
                    <br />
                    <button className="main btn btn-primary" onClick={editRentalModal}>
                      Send
                    </button>
                  </div>
                </div>
              </Modal>
              <Modal isOpen={editModalOnDemandIsOpen} onRequestClose={() => setEditOnDemandIsOpen(false)} className="relative bg-white rounded-lg mx-auto mt-10 p-6 max-w-5xl h-[90%] overflow-y-auto border border-gray-400">
                <div className="px-2 md:px-16">
                  <div className="rental-info">
                    <div className="flex justify-end">
                      <div className="absolute top-3 right-3">
                        <button
                          className="close cursor-pointer p-1 focus:outline-none"
                          onClick={() => setEditOnDemandIsOpen(false)}
                        >
                          <svg
                            className="w-8 h-8 text-gray-700 hover:text-red-500"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 9.292L5.858 5.15 4.444 6.564 8.586 10.707 4.444 14.85l1.414 1.414L10 12.121l4.142 4.143 1.414-1.414L11.414 10.707 15.556 6.564 14.142 5.15 10 9.292z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <h2 className="text-2xl font-bold leading-tight text-black sm:text-2xl lg:text-3xl py-8">Edit On-Demand Rental</h2>
                    <div className="md:flex md:items-center mb-6">
                      <div className="md:w-3/12">
                        <label className="text-gray-600 block md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                          Resort
                        </label>
                      </div>
                      <div className="md:w-8/12">
                        <select
                          id="resort"
                          value={editOnDemandResort}
                          onChange={(e) => setEditOnDemandResort(e.target.value)}
                          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
                        >
                          <option value="">Select a resort</option>
                          {onDemandResorts.map((resort, index) => (
                            <option key={index} value={resort}>
                              {resort}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="md:flex md:items-center mb-6">
                      <div className="md:w-3/12">
                        <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                          Property
                        </label>
                      </div>
                      <div className="md:w-8/12 relative">
                        <input
                          id="name"
                          value={editOnDemandName}
                          onChange={(e) => setEditOnDemandName(e.target.value)}
                          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500 disabled:opacity-50"
                          list="name-datalist"
                          disabled={!editOnDemandResort}
                          autoComplete="off"
                          placeholder="Type your property"
                          onFocus={() => setInputFocused(true)}
                          onBlur={() => setInputFocused(false)}
                        />
                        <datalist id="name-datalist">
                          {onDemandNames.map((name, index) => (
                            <option key={index} value={name} />
                          ))}
                        </datalist>
                        {!inputFocused && !editOnDemandName && (
                          <span className="absolute right-0 bottom-0 mb-1 mr-1 text-xs text-gray-500">If your property is not on the list you can type it</span>
                        )}
                      </div>
                    </div>
                    <div className="md:flex md:items-center mb-6">
                      <div className="md:w-3/12">
                        <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                          Year
                        </label>
                      </div>
                      <div className="md:w-8/12 relative">
                        <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500" id="inline-full-name" type="number" placeholder="Select a year" value={editOnDemandYear} onChange={(e) => setEditOnDemandYear(e.target.value)} />
                      </div>
                    </div>
                    <div className="md:flex md:items-center mb-6">
                      <div className="md:w-3/12">
                        <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                          Number of weeks
                        </label>
                      </div>
                      <div className="md:w-8/12 relative">
                        <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500" id="inline-full-name" type="number" placeholder="Number of weeks" value={editOnDemandWeeks} onChange={(e) => setEditOnDemandWeeks(e.target.value)} />
                      </div>
                    </div>
                    <div className="md:flex md:items-center mb-6">
                      <div className="md:w-3/12">
                        <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                          Usage fee
                        </label>
                      </div>
                      <div className="md:w-8/12 relative">
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <span className="text-gray-600">$</span>
                          </div>
                          <input
                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 pl-8 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
                            id="usage-fee"
                            type="text"
                            placeholder="Enter your usage fee as specified in the contract."
                            value={editOnDemandFormattedUsageFee}
                            onChange={(e) => {
                              const value = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
                              if (value === "") {
                                setEditOnDemandUsageFee("");
                                setEditOnDemandFormattedUsageFee("");
                              } else {
                                setEditOnDemandUsageFee(value);
                                setEditOnDemandFormattedUsageFee(formatUsageFee(value));
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="md:flex md:items-start mb-6">
                      <div className="md:w-3/12">
                        <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4 pt-1.5" htmlFor="sister-resorts">
                          Sister Resorts
                        </label>
                      </div>
                      <div className="md:w-8/12 relative">
                        <Select
                          isMulti
                          options={propertiesList}
                          value={editOnDemandSisterResorts}
                          onChange={handleEditSisterResorts}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                        <p className="text-gray-500 text-sm pt-2">Enter all sister resorts listed in your contract to see more options available to your unit and receive offers.</p>
                      </div>
                    </div>
                    {editOnDemandSisterResorts?.length > 0 && (
                      <div className="md:flex md:items-center mb-6 flex-col w-9/12 mx-auto">
                        <p className="block text-gray-500 md:text-center mb-1 md:mb-0 pt-4 pb-3">
                          Note: Attach a photo of the sister resorts / addendums. We do not need any data or information of your contract, we only need a photo of the sister resorts section to provide you with a better service. (Optional)
                        </p>
                        <input
                          className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer focus:outline-none"
                          type="file"
                          id="sister-resorts-file"
                          name="sister-resorts-file"
                          accept="image/*"
                          onChange={handleEditSisterResortsFile}
                        />
                      </div>
                    )}
                    <div className="flex flex-col lg:flex-row justify-start items-start w-full gap-4">
                      <div className="flex flex-col w-full lg:w-5/12">
                        <p className="block text-gray-600 mb-1 md:mb-0 pt-4 pb-3">
                          Select your amenities privileges
                        </p>
                        <div className="flex flex-col px-6 pb-6">
                          <label className="inline-flex items-center py-2">
                            <input className="form-checkbox text-indigo-600" type="checkbox" id="resort" name="Resort Platinum Status" value="Resort Platinum Status" onChange={handleEditOnDemandAmenities} checked={editOnDemandAmenities.includes("Resort Platinum Status")} />
                            <span className="ml-2">Resort Platinum Status</span>
                          </label>
                          <label className="inline-flex items-center py-2">
                            <input type="checkbox" className="form-checkbox text-indigo-600" id="2for1" name="2 for 1 - 50 minutes Spa massages" value="2 for 1 - 50 minutes Spa massages" onChange={handleEditOnDemandAmenities} checked={editOnDemandAmenities.includes("2 for 1 - 50 minutes Spa massages")} />
                            <span className="ml-2">2 for 1 - 50 minutes Spa massages</span>
                          </label>
                          <label className="inline-flex items-center py-2">
                            <input type="checkbox" className="form-checkbox text-indigo-600" id="massages" name="Free Massages" value="Free Massages" onChange={handleEditOnDemandAmenities} checked={editOnDemandAmenities.includes("Free Massages")} />
                            <span className="ml-2">Free Massages</span>
                          </label>
                          <label className="inline-flex items-center py-2">
                            <input type="checkbox" id="golf" name="Discounted Rounds of Golf" value="Discounted Rounds of Golf" onChange={handleEditOnDemandAmenities} className="form-checkbox text-indigo-600" checked={editOnDemandAmenities.includes("Discounted Rounds of Golf")} />
                            <span className="ml-2">Discounted Rounds of Golf</span>
                          </label>
                          <label className="inline-flex items-center py-2">
                            <input type="checkbox" id="golf1" name="2 to 16 Free Rounds of Golf" value="2 to 16 Free Rounds of Golf" onChange={handleEditOnDemandAmenities} className="form-checkbox text-indigo-600" checked={editOnDemandAmenities.includes("2 to 16 Free Rounds of Golf")} />
                            <span className="ml-2">2 to 16 Free Rounds of Golf</span>
                          </label>
                          <label className="inline-flex items-center py-2">
                            <input type="checkbox" id="gondola" name="Access to Air Gondola" value="Access to Air Gondola" onChange={handleEditOnDemandAmenities} className="form-checkbox text-indigo-600" checked={editOnDemandAmenities.includes("Access to Air Gondola")} />
                            <span className="ml-2">Access to Air Gondola</span>
                          </label>
                          {editOnDemandCustomAmenities.map((amenity, index) => (
                            <>
                              {amenity.name !== "" && (
                                <div className="inline-flex items-center py-2" key={index}>
                                  <input
                                    type="checkbox"
                                    id={`custom-${index}`}
                                    name={amenity.name}
                                    value={amenity.name}
                                    checked={amenity.checked}
                                    onChange={(e) => editHandeOnDemandCustomAmenitiesCheck(e, index)}
                                    className="form-checkbox text-indigo-600"
                                  />
                                  <span className="ml-2">{amenity.name}</span>
                                  <button
                                    className="ml-4 text-gray-400 hover:text-red-800 transition duration-150 ease-in-out focus:outline-none"
                                    onClick={() => editOnDemandDeleteCustomAmenity(index)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-4 w-4"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M10 9.293l5.707-5.707a1 1 0 011.414 1.414L11.414 10l5.707 5.707a1 1 0 01-1.414 1.414L10 11.414l-5.707 5.707a1 1 0 01-1.414-1.414l5.707-5.707-5.707-5.707a1 1 0 011.414-1.414L10 9.293z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              )}
                            </>
                          ))}
                          <div className="md:flex md:items-center mb-6">
                            {/* <div className="md:w-3/12">
                          <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" htmlFor="custom-amenity">
                            Add Amenity
                          </label>
                        </div> */}
                            <div className="relative flex flex-row w-full gap-1">
                              <input
                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
                                id="custom-amenity"
                                type="text"
                                placeholder="Add custom amenity and press Enter"
                                onKeyPress={handleEditOnDemandAddCustomAmenity}
                              />
                              <button
                                className="p-2 text-xs bg-gray-300 text-gray-600 hover:text-gray-800 focus:outline-none transition duration-150 ease-in-out px-2 py-1 rounded-md hover:bg-gray-400 z-10"
                                onClick={handleEditOnDemandAddCustomAmenity}
                              >
                                <Plus className="w-4 h-4 mx-1" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col w-full lg:w-6/12">
                        <div className="md:flex flex-col md:items-start mb-6">
                          <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pt-4 pr-4 pb-3" htmlFor="comment-section">
                            Comments (Optional)
                          </label>
                          <div className="md:w-full pl-6">
                            <textarea
                              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500 h-48"
                              id="comment-section"
                              rows="5"
                              placeholder="Enter your comments here..."
                              value={editOnDemandComments}
                              onChange={(e) => setEditOnDemandComments(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <button className="main btn btn-primary" onClick={editOnDemandModal}>
                      Send
                    </button>
                  </div>
                </div>
              </Modal>
              <Modal isOpen={deleteModalRentalIsOpen} onRequestClose={() => setDeleteRentalIsOpen(false)} className="relative bg-white rounded-lg mx-auto p-6 max-w-md h048 mt-[calc(50vh-10rem)] overflow-y-auto border border-gray-400">
                {selectedRental && (
                  <p className="text-gray-700 text-center">
                    Are you sure you want to delete <span className="font-semibold">{selectedRental.resort} {selectedRental.rental} {selectedRental.destination}</span> booked week?
                  </p>
                )}
                <div className="flex justify-center items-center gap-8 mt-6">
                  <button className="bg-gray-300 hover:bg-gray-400 p-2 px-6 rounded-lg" onClick={() => setDeleteRentalIsOpen(false)} >
                    No
                  </button>
                  <button className="bg-red-500 hover:bg-red-600 p-2 px-6 rounded-lg" onClick={() => deleteRentalModal(selectedRental)} >
                    Yes
                  </button>
                </div>
              </Modal>
              <Modal isOpen={deleteModalOnDemandIsOpen} onRequestClose={() => setDeleteOnDemandIsOpen(false)} className="relative bg-white rounded-lg mx-auto p-6 max-w-md h048 mt-[calc(50vh-10rem)] overflow-y-auto border border-gray-400">
                {selectedOnDemand && (
                  <p className="text-gray-700 text-center">
                    Are you sure you want to delete <span className="font-semibold">{selectedOnDemand.resort} {selectedOnDemand.property}</span> on-demand rental?
                  </p>
                )}
                <div className="flex justify-center items-center gap-8 mt-6">
                  <button className="bg-gray-300 hover:bg-gray-400 p-2 px-6 rounded-lg" onClick={() => setDeleteOnDemandIsOpen(false)} >
                    No
                  </button>
                  <button className="bg-red-500 hover:bg-red-600 p-2 px-6 rounded-lg" onClick={() => deleteOnDemandModal(selectedOnDemand)} >
                    Yes
                  </button>
                </div>
              </Modal>
              <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                <div className="pb-8 max-w-2xl mx-auto text-center">
                  <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">My Weeks</h2>
                </div>
                <div className="add terms px-8 md:px-30 xl:px-60">
                  <div className="py-2 px-4 mx-auto max-w-screen-xl lg:px-6">
                    <div className="space-y-8 sm:gap-6 xl:gap-10 lg:space-y-0 md:space-y-0">
                      <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-gray-200 hover:bg-gray-300 rounded-lg border border-gray-400 shadow cursor-pointer" onClick={() => setNewIsOpen(true)} disabled={isOnDemandDisabled}>
                        <div className="flex items-center justify-center flex-col">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                          </svg>
                          <p className="text-lg text-blue-600">Add On-Demand Rental</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex px-8 md:px-30 xl:px-60">
                  <button className="py-2 px-4 w-full lg:px-6">
                    <div className="space-y-8 sm:gap-6 xl:gap-10 lg:space-y-0 md:space-y-0">
                      <div className="flex flex-col p-2 mx-auto max-w-lg text-center text-gray-900 bg-gray-100 hover:bg-gray-200 rounded-lg border border-gray-200 shadow cursor-pointer" onClick={() => setIsOpen(true)} disabled={isRentalDisabled}>
                        <div className="flex items-center justify-center flex-row">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-zinc-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                          </svg>
                          <p className="text-sm text-zinc-500">Add Booked Week</p>
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
                {weeks.map((week, index) => (
                  <div className="terms md:px-30 xl:px-60" key={week.id}>
                    <div className="py-4 px-4 mx-auto max-w-screen-xl lg:px-6">
                      <div className="space-y-8 sm:gap-6 xl:gap-10 lg:space-y-0 md:space-y-0">
                        <div className="relative flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-gradient-to-r from-[#faf5ff] to-purple-50 rounded-2xl border border-zinc-300 shadow">
                        <div className="flex justify-start items-center">
                          <div className="absolute top-2 right-2 flex items-center">
                              <button className="rounded-full bg-blue-200 p-1 hover:bg-blue-300 mx-1 text-xs disabled:opacity-50" onClick={() => editRental(week)}>
                                <img src="/edit.svg" alt="edit" className="w-4"></img>
                              </button>
                              <button className=" rounded-full bg-red-400 p-1 hover:bg-red-600 mx-1 text-xs disabled:opacity-50" onClick={() => deleteRental(week)}>
                                <img src="/delete.svg" alt="edit" className="w-4"></img>
                              </button>
                            </div>
                            <div className="flex justify-end">
                            <span className={"px-2 py-1 text-xs font-semibold tracking-wider rounded-full bg-yellow-300 bg-gradient-to-r from-yellow-300 to-amber-500 border border-zinc-400 text-zinc-900"}>Booked Week</span>
                            </div>
                          </div>
                          <h3 className="my-4 text-2xl font-semibold">{week.resort} {week.rental} {week.destination}</h3>
                          {getBackgroundResortImage(week.resort) && (
                            <img src={getBackgroundResortImage(week.resort)} alt={week.resort} className="w-full h-40 object-cover rounded-lg mb-4" />
                          )}
                          <div className="flex flex-col lg:flex-row justify-center items-stretch w-full mb-8">
                            <div className="w-full lg:w-1/3 flex flex-col items-center border-2 border-zinc-500 rounded-t-lg lg:rounded-tr-none lg:rounded-l-lg p-2 lg:p-4">
                            <p>Start</p>
                            <p className="font-semibold">{timestampToDate(week.start)}</p>
                            </div>
                            <div className="w-full lg:w-1/3 flex flex-col items-center border-l-2 lg:border-l-0 border-r-2 lg:border-r-0 lg:border-t-2 lg:border-b-2 border-zinc-500 p-2 lg:p-4">
                            <p>End</p> 
                            <p className="font-semibold">{timestampToDate(week.end)}</p>
                            </div>
                            <div className="w-full lg:w-1/3 flex flex-col items-center border-2 border-zinc-500 rounded-b-lg lg:rounded-bl-none lg:rounded-r-lg p-2 lg:p-4">
                              <p>Usage fee</p>
                              <p className="font-semibold">{formatUsageFee(week.usageFee)}</p>
                            </div>
                          </div>
                          <div role="list" className="mb-8 space-y-4 text-left">
                            <li className="flex items-center space-x-3">
                              <span>Amenities:</span> <span className="font-semibold">{week.amenities}</span>
                            </li>
                            <div className="w-full h-[0.5px] bg-zinc-300 my-8"></div>
                            {week.comments && (
                              <li className="flex items-center space-x-3">
                                <span>Comments:</span> <span className="font-semibold">{week.comments}</span>
                              </li>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {onDemands.map((week, index) => (
                  <div className="terms md:px-30 xl:px-60" key={week.id}>
                    <div className="py-4 px-4 mx-auto max-w-screen-xl lg:px-6">
                      <div className="space-y-8 sm:gap-6 xl:gap-10 lg:space-y-0 md:space-y-0">
                        <div className="relative flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-gradient-to-r from-[#faf5ff] to-violet-100 rounded-2xl border border-zinc-300 shadow">
                          <div className="flex justify-start items-center">
                            <div className="absolute top-2 right-2 flex items-center">
                              <button className="flex items-center rounded-full bg-blue-200 p-1 hover:bg-blue-300 mx-1 text-xs disabled:opacity-50" onClick={() => editOnDemand(week)}>
                                <img src="/edit.svg" alt="edit" className="w-4"></img>
                              </button>
                              <button className="flex items-center rounded-full bg-red-400 p-1 hover:bg-red-600 mx-1 text-xs disabled:opacity-50" onClick={() => deleteOnDemand(week)}>
                                <img src="/delete.svg" alt="delete" className="w-4"></img>
                              </button>
                            </div>
                            <div className="flex justify-end">
                              <span className={"px-2 py-1 text-xs font-semibold tracking-wider rounded-full bg-green-300 bg-gradient-to-r from-emerald-300 to-emerald-500 border border-zinc-400 text-zinc-900"}>On Demand</span>
                            </div>
                          </div>
                          <h3 className="my-4 text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-br from-stone-600 to-stone-900">{week.property}</h3>
                          {getBackgroundResortImage(week.resort) && (
                            <img src={getBackgroundResortImage(week.resort)} alt={week.resort} className="w-full h-40 object-cover rounded-lg mb-4" />
                          )}
                          {/* <p className="font-light text-gray-500 sm:text-lg ">Best option for rental properties</p>
                              <div className="flex justify-center items-baseline my-8">
                                <span className="mr-2 text-5xl font-extrabold">$1,699</span>
                                <span className="text-gray-500">USD</span>
                              </div> */}
                          <div className="flex flex-col lg:flex-row justify-center items-stretch w-full mb-8">
                            <div className="w-full lg:w-1/3 flex flex-col items-center border-2 border-zinc-500 rounded-t-lg lg:rounded-tr-none lg:rounded-l-lg p-2 lg:p-4">
                              <p>Resort</p>
                              <p className="font-semibold">{week.resort}</p>
                            </div>
                            <div className="w-full lg:w-1/3 flex flex-col items-center border-l-2 lg:border-l-0 border-r-2 lg:border-r-0 lg:border-t-2 lg:border-b-2 border-zinc-500 p-2 lg:p-4">
                              <p>No. of Weeks</p>
                              <p className="font-semibold">{week.noOfWeeks}</p>
                            </div>
                            <div className="w-full lg:w-1/3 flex flex-col items-center border-2 border-zinc-500 rounded-b-lg lg:rounded-bl-none lg:rounded-r-lg p-2 lg:p-4">
                              <p>Usage fee</p>
                              <p className="font-semibold">{formatUsageFee(week.usageFee)}</p>
                            </div>
                          </div>
                          <div role="list" className="mb-8 space-y-4 text-left">
                            <li className="flex items-center space-x-3">
                              <span>Sister Resorts:</span> <span className="font-semibold">{week.sisterResorts}</span>
                            </li>
                            <li className="flex items-center space-x-3">
                              <span>Amenities:</span> <span className="font-semibold">{week.amenities}</span>
                            </li>
                            <li className="flex items-center space-x-3">
                              <span>Year:</span> <span className="font-semibold">{week.year}</span>
                            </li>
                            <div className="w-full h-[0.5px] bg-zinc-300 my-8"></div>
                            {week.comments && (
                              <li className="flex items-center space-x-3">
                                <span>Comments:</span> <span className="font-semibold">{week.comments}</span>
                              </li>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          )}
          {seeRentalInfo && (
            <section className="py-10 w-full">
              <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                <div className="max-w-2xl mx-auto text-center">
                  <h1 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">Rental Information</h1>
                  <p className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-600">We have the following types of services covered by your plan:</p>
                </div>
                <div className="block md:hidden w-full justify-center items-center pt-3">
                  <p className="text-center text-gray-600 text-[10px]">Need help? Contact us</p>
                </div>
                <div className="w-full md:hidden justify-center items-center pt-1">
                  <a href="https://wa.me/18787896440" target="_blank" rel="noreferrer">
                    <img src="/wabutton.png" alt="whatsapp" className="w-8/12 m-auto max-w-48" />
                  </a>
                </div>
                <div className="flex w-full md:hidden justify-center items-center gap-2 pt-1">
                  <p className="text-center text-gray-600 text-xs">+1 (878) 789-6440</p>
                  <Copy className="w-2 h-2 text-gray-600 cursor-pointer" onClick={() => navigator.clipboard.writeText("+1 (878) 789-6440")} />
                </div>
                <div className="flex flex-col terms px-8 md:px-30 xl:px-60 gap-2">
                  <h2 className="text-2xl font-bold text-gray-900 pt-10">On Demand Service</h2>
                  <p className="text-base text-gray-700 md:text-lg">
                    We are <span className="text-base md:text-lg text-gray-800 font-semibold">constantly looking</span> for guests for your <span className="text-base md:text-lg text-gray-800 font-semibold">available weeks</span> and if we have a <span className="text-base md:text-lg text-gray-800 font-semibold">prospective renter</span>, we will let you know in <span className="text-base md:text-lg text-gray-800 font-semibold">advance</span> so that we can schedule an <span className="text-base md:text-lg text-gray-800 font-semibold">on-demand booking</span>. This way you will only pay <span className="text-base md:text-lg text-gray-800 font-semibold">maintenance fees</span> with a <span className="text-base md:text-lg text-gray-800 font-semibold">confirmed guest</span>.
                  </p>
                  <h3 className="text-xl font-bold text-gray-900 mt-1">How does it work?</h3>
                  <p className="text-base text-gray-700 md:text-lg ml-3"><span className="text-base md:text-lg text-gray-800 font-semibold">1.</span> We ask you to let us know the type of unit you are willing to offer for rent.</p>
                  <p className="text-base text-gray-700 md:text-lg ml-3"><span className="text-base md:text-lg text-gray-800 font-semibold">2.</span> We are actively looking for a prospective guest for a specific week.</p>
                  <p className="text-base text-gray-700 md:text-lg ml-3"><span className="text-base md:text-lg text-gray-800 font-semibold">3.</span> When we have an interested guest, we send an email to active VRCo users that match the potential guest's interest (uploaded unit).</p>
                  <p className="text-base text-gray-700 md:text-lg ml-3"><span className="text-base md:text-lg text-gray-800 font-semibold">4.</span> VRCo users must verify the available dates of the prospective guests' preferred unit.</p>
                  <p className="text-base text-gray-700 md:text-lg ml-3"><span className="text-base md:text-lg text-gray-800 font-semibold">5.</span> We confirm with the potential renter the date they are willing to plan their trip and proceed with payment.</p>
                  <p className="text-base text-gray-700 md:text-lg ml-3"><span className="text-base md:text-lg text-gray-800 font-semibold">6.</span> The VRCo user must confirm as soon as possible the reservation on those dates, destination and with the renter's NAME (in case of non-confirmation, the amount will be refunded).</p>
                  <p className="text-base text-gray-700 md:text-lg ml-3"><span className="text-base md:text-lg text-gray-800 font-semibold">7.</span> We send the confirmation to the guest of his new reservation. </p>

                </div>
                <div className="flex flex-col terms px-8 md:px-30 xl:px-60 gap-2">
                  <h1 className="text-2xl font-bold text-gray-900 pt-10">Booked Weeks Promotion</h1>
                  <p className="text-base md:text-lg text-gray-700 font-semibold">This service is only for booked weeks.</p>
                  <h3 className="text-xl font-bold text-gray-900 mt-1">What do we need from you?</h3>
                  <p className="text-base text-gray-700 md:text-lg ml-3"><span className="text-base md:text-lg text-gray-800 font-semibold">1.</span> Type of contract (for example:  One bedroom apartment). </p>
                  <p className="text-base text-gray-700 md:text-lg ml-3"><span className="text-base md:text-lg text-gray-800 font-semibold">2.</span> Destination. (e.g. San Jose del Cabo) </p>
                  <p className="text-base text-gray-700 md:text-lg ml-3"><span className="text-base md:text-lg text-gray-800 font-semibold">3.</span> Confirmed dates of your booked weeks - Check in - Check out.</p>
                  <p className="text-base text-gray-700 md:text-lg italic">* We will never ask for personal information such as contract number, member ID or paid-in capital but we may ask for addendum information.</p>
                  <h3 className="text-xl font-bold text-gray-900 mt-1">What's next?</h3>
                  <p className="text-base text-gray-700 md:text-lg">We will <span className="text-base md:text-lg text-gray-800 font-semibold">upload this information</span> into our database, where an agent will be able to <span className="text-base md:text-lg text-gray-800 font-semibold">start promoting</span> your weeks on all the <span className="text-base md:text-lg text-gray-800 font-semibold">popular rental sites</span> in the most <span className="text-base md:text-lg text-gray-800 font-semibold">strategic way</span> to ensure you get <span className="text-base md:text-lg text-gray-800 font-semibold">full exposure</span> for your <span className="text-base md:text-lg text-gray-800 font-semibold">unused inventory</span>.</p>
                  <h3 className="text-xl font-bold text-gray-900 mt-1">We've found a client! Now what?</h3>
                  <p className="text-base text-gray-700 md:text-lg">Once we have the <span className="text-base md:text-lg text-gray-800 font-semibold">guest's deposit</span>, we will inform you so you can <span className="text-base md:text-lg text-gray-800 font-semibold">change the contract to the guest's name</span>. We will then <span className="text-base md:text-lg text-gray-800 font-semibold">confirm with both parties</span>. For these services, everything mentioned in the <span className="text-base md:text-lg text-gray-800 font-semibold">Rental Agreement</span> you previously signed applies and both types of services can be used at the <span className="text-base md:text-lg text-gray-800 font-semibold">same time</span>.



                  </p>
                </div>
              </div>
              <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl mt-20">
                <div className="max-w-2xl mx-auto text-center">
                  <h2 className="text-xl font-bold leading-tight text-black">Frequently Asked Questions</h2>
                  <p className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-600">Check here some of the most frequent questions our clients have for you to get more clarity about how we work:</p>
                </div>
                <div>
                  <div className="w-full mx-auto mt-10 space-y-2 shadow border border-zinc-300 rounded-lg">
                    <details open className="p-4 px-8 border-b-2">
                      <summary className="font-semibold">
                        Who sets the nightly rental rates of the properties?
                      </summary>
                      <div className="mt-3">
                        <p className="text-sm leading-6 text-gray-600">
                          The rental rates have been adjusted after testing different ranges in the market, and the intention is to achieve the highest utility to our customers. </p>
                      </div>
                    </details>
                    <details open className="p-4 px-8 border-b-2">
                      <summary className="font-semibold">
                        Can I use certificates instead of regular weeks?
                      </summary>
                      <div className="mt-3">
                        <p className="text-sm leading-6 text-gray-600">
                          We only accept weeks where the reservation name can be modified, it is our experience that weeks offered via CERTIFICATES don’t have the ability to make this modification.
                        </p>
                      </div>
                    </details>
                    <details open className="p-4 px-8 border-b-2">
                      <summary className="font-semibold">
                        What’s next?
                      </summary>
                      <div className="mt-3">
                        <p className="text-sm leading-6 text-gray-600">
                          We will upload this information to our database, where an agent is going to be able to start promoting your weeks in all of the popular rental sites and in the must strategic way to assure that you get full exposure on your unused inventory.
                        </p>
                      </div>
                    </details>
                    <details open className="p-4 px-8 border-b-2">
                      <summary className="font-semibold">
                        Can I have the same week advertised with different rental companies?
                      </summary>
                      <div className="mt-3">
                        <p className="text-sm leading-6 text-gray-600">
                          YES, you can always advertise your weeks in multiple channels apart from us.
                        </p>
                        <p className="text-sm leading-6 text-gray-600">
                          However keep in mind that you have to respect our Price Parity rule.
                        </p>
                      </div>
                    </details>
                    <details open className="p-4 px-8 border-b-2">
                      <summary className="font-semibold">
                        What is the Opportunity Spreadsheet?
                      </summary>
                      <div className="mt-3">
                        <p className="text-sm leading-6 text-gray-600">
                          The Opportunity Spreadsheet tab is where you find what weeks have been already submitted by another participant in each type of condominium along with the pertaining rental privileges of the owner. You’ll find this table in the “Dashboard” section once you’ve logged in to your account.
                        </p>
                        <p className="text-sm leading-6 text-gray-600">
                          For these services, everything mentioned in the Rental Agreement you previously signed applies and both types of service can be used at the same time.
                        </p>
                      </div>
                    </details>
                    <details open className="p-4 px-8 border-b-2">
                      <summary className="font-semibold">
                        What happens if a week doesn't get rented?
                      </summary>
                      <div className="mt-3">
                        <p className="text-sm leading-6 text-gray-600">
                          You can always withdraw any week at any time with no penalties as long as is not subject to a booked rental by editing or eliminating a property in the "Weeks Deposited" section.
                        </p>
                      </div>
                    </details>
                    <details open className="p-4 px-8 border-b-2">
                      <summary className="font-semibold">
                        Will you ever add new properties for marketing and rental?
                      </summary>
                      <div className="mt-3">
                        <p className="text-sm leading-6 text-gray-600">
                        </p>
                        <p className="text-sm leading-6 text-gray-600">
                          The exclusive rental companies we partner with have certain standards to admit new properties in their portfolio, right now these are the only properties that qualify.
                        </p>
                        <p className="text-sm leading-6 text-gray-600">
                          In the future, if new properties come up, they will be submitted to be admitted, and the rental companies will decide.

                        </p>
                      </div>
                    </details>
                  </div>
                </div>
              </div>
            </section>
          )}
          {seeCalendar && (
            <section className="py-10 w-full">
              <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                <div className="text-center">
                  <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">Opportunity Spreadsheet</h2>
                  <p className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-600">Find what weeks have been already submitted by other users</p>
                  <p className="max-w-xl mx-auto mt-3 text-2xl leading-relaxed text-gray-800">2024</p>
                  <div className="md:p-6 mx-6 grid grid-cols-12 gap-6 justify-center content-center items-center">
                    {monthList.map((month, index) => {
                      const { calendar, currentMonth } = getMonthDays(month);
                      return (
                        <div className="col-span-12 lg:col-span-6 xl:col-span-4" key={index}>
                          <h1 className="text-lg font-medium">{currentMonth}</h1>
                          <table className="calendar-table mt-4">
                            <thead>
                              <tr className="text-gray-600">
                                <th className="px-3 py-2">S</th>
                                <th className="px-3 py-2">M</th>
                                <th className="px-3 py-2">T</th>
                                <th className="px-3 py-2">W</th>
                                <th className="px-3 py-2">T</th>
                                <th className="px-3 py-2">F</th>
                                <th className="px-3 py-2">S</th>
                              </tr>
                            </thead>
                            <tbody className="calendar-body">{calendar}</tbody>
                          </table>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      )}
    </>
  );
}
