import Companies from "../components/Companies";
import Hero from "../components/Hero";
import MoreInfo from "../components/MoreInfo";
// import Feature from "../components/Feature";
import Footer from "../components/Footer";
import MarqueeDemo from "../components/reviews";

export default function Home() {
  return (
    <div className="flex items-center justify-center min-h-screen">
    <div className="max-w-lg w-full mx-6">
      <div className="relative mb-12">
        <div className="absolute -top-10 -left-4 w-24 h-24 bg-gradient-to-br from-fuchsia-400 to-indigo-500 opacity-10 rounded-full blur-xl"></div>
        <div className="absolute top-6 right-10 w-16 h-16 bg-gradient-to-br from-amber-300 to-pink-500 opacity-10 rounded-full blur-xl"></div>
        <h1 className="text-4xl font-bold text-gray-900 mb-2 tracking-tight">We're updating our site</h1>
        <div className="h-1 w-40 bg-indigo-500 mb-6"></div>
        <p className="text-gray-600 text-lg">Back online soon</p>
      </div>
      
      <div className="mb-10 border-l-2 border-gray-200 pl-6">
        <p className="text-gray-600 mb-6">
          We're making some improvements to create a better experience for you. Thanks for your patience.
        </p>
      </div>
      
      <div className="flex items-center justify-between mt-12">
        <div className="text-sm text-gray-400">
          <p>© 2025 VRCO</p>
        </div>
        {/* <div className="text-sm text-indigo-500">
          info@vrco.site
        </div> */}
      </div>
    </div>
  </div>
  )
}