import { Link } from "react-router-dom";
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import Footer from "../components/Footer";

const beachLED = []

for (let i = 0; i < 240; i++) {
  let random = Math.floor(Math.random() * 3);
  if (random === 0) {
    beachLED[i] = 'bg-slate-600/50';
  } else if (random === 1) {
    beachLED[i] = 'bg-indigo-600/50';
  } else {
    beachLED[i] = '';
  }
}

const LED = forwardRef(({ color }, ref) => {
  const [opacity, setOpacity] = useState(1);

  const handleClick = () => {
    setOpacity(0);
    setTimeout(() => setOpacity(1), 3000);
  };

  useImperativeHandle(ref, () => ({
    handleClick,
  }));

  return (
    <div className={`w-3 h-3 m-0.5 rounded-full ${color} hover:brightness-50 transition duration-50 ease-in-out`} onMouseEnter={handleClick} style={{ opacity }} />
  );
});

const LEDDisplay = ({ ledArray }) => {
  const [ledRefs, setLedRefs] = useState([]);

  useEffect(() => {
    setLedRefs((refs) => Array(ledArray.length).fill().map(() => React.createRef()));
  }, [ledArray]);

  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * ledArray.length);
      ledRefs[randomIndex].current.handleClick();
    }, 1000);
    return () => clearInterval(interval);
  }, [ledRefs, ledArray]);

  return (
    <div className="flex flex-wrap justify-center items-center pt-20">
      {ledArray.map((color, index) => (
        <LED key={index} color={color} ref={ledRefs[index]} />
      ))}
    </div>
  );
};


export default function How() {
  return (
    <>
      <div className="py-16 md:px-24 px-4 lg:px-8 lg:h-[calc(90)] bg-zinc-50">
        <div className="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <h2 className="text-4xl font-bold leading-tight sm:text-4xl lg:text-5xl text-center text-transparent gradient-text md:py-2">
              Follow these steps
            </h2>
          </div>
          <div className="relative grid gap-8 row-gap-5 mb-8 md:row-gap-8 lg:grid-cols-4 sm:grid-cols-2">
            <div className="absolute inset-0 flex items-center justify-center sm:hidden lg:flex">
              <div className="w-px h-full bg-slate-400 lg:w-full lg:h-1" />
            </div>
            <div className="p-5 duration-300 transform bg-slate-200 border border-slate-400  rounded-lg shadow-sm hover:-translate-y-2 hover:border-indigo-400">
              <div className="flex items-center justify-between mb-2">
                <p className="text-lg font-bold leading-5 text-blue-600">Sign Up</p>
                <p className="flex items-center justify-center w-6 h-6 font-bold rounded-md bg-slate-100">
                  1
                </p>
              </div>
              <p className="text-sm">
                Sign up using your email and password to start.
              </p>
              <img src="/email-svgrepo-com.svg" alt="signup" className="w-12 mt-5 mx-auto filter-indigo-light" />
            </div>
            <div className="p-5 duration-300 transform bg-slate-200 border border-slate-400 rounded-lg shadow-sm hover:-translate-y-2 hover:border-indigo-400">
              <div className="flex items-center justify-between mb-2">
                <p className="text-lg font-bold leading-5 text-blue-600">Subscribe</p>
                <p className="flex items-center justify-center w-6 h-6 font-bold rounded-md bg-slate-100">
                  2
                </p>
              </div>
              <p className="text-sm">
                Read the rental agreement and pay the enrollment fee to get started.
              </p>
              <img src="/credit-card-svgrepo-com.svg" alt="subscribe" className="w-12 mt-5 mx-auto filter-indigo" />
            </div>
            <div className="p-5 duration-300 transform bg-slate-200 border border-slate-400 rounded-lg shadow-sm hover:-translate-y-2 hover:border-indigo-400">
              <div className="flex items-center justify-between mb-2">
                <p className="text-lg font-bold leading-5 text-blue-600">Upload your property</p>
                <p className="flex items-center justify-center w-6 h-6 font-bold rounded-md bg-slate-100">
                  3
                </p>
              </div>
              <p className="text-sm">
                Fill out the form to share your property with us to start advertising it.
              </p>
              <img src="/home-svgrepo-com.svg" alt="deposit" className="w-12 mt-5 mx-auto filter-indigo-light" />
            </div>
            <div className="p-5 duration-300 transform bg-slate-200 border border-slate-400 rounded-lg shadow-sm hover:-translate-y-2 hover:border-indigo-400">
              <div className="flex items-center justify-between mb-2">
                <p className="text-lg font-bold leading-5 text-blue-600">Start earning</p>
                <p className="flex items-center justify-center w-6 h-6 font-bold rounded-md bg-slate-100">
                  4
                </p>
              </div>
              <p className="text-sm">
                Once you fill out the form, we will start promoting your reservation.
              </p>
              <img src="/money-svgrepo-com.svg" alt="earn" className="w-12 mt-5 mx-auto filter-indigo-light" />
            </div>
          </div>
          <div className="pt-4 sm:mt-8 sm:flex justify-center">
              <div>
                <Link
                  to="/rental-commissions"
                  className="flex w-full items-center justify-center rounded-full border border-transparent px-8 py-3 text-base font-medium text-white bg-blue-600 hover:bg-blue-900 md:py-4 md:text-lg md:px-10">
                  Our Properties
                </Link>
              </div>
          </div>
          <LEDDisplay ledArray={beachLED} />
        </div>
      </div>
      <Footer />
    </>
  );
};