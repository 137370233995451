import React, { useState } from "react";
import { resortData } from "../data";
import Modal from "react-modal";
import Footer from "../components/Footer";

Modal.setAppElement("#root");

// const resortImages = [
//   {
//     resort: "The Estates",
//     image: "/logo-the-estates.svg",
//   },
//   {
//     resort: "Grand Luxxe",
//     image: "/logo-grand-luxxe.svg",
//   },
//   {
//     resort: "The Grand Bliss",
//     image: "/logo-grand-bliss.svg",
//   },
//   {
//     resort: "The Grand Mayan",
//     image: "/logo-grand-mayan.svg",
//   },
//   {
//     resort: "The Bliss",
//     image: "/logo-bliss.svg",
//   },
//   {
//     resort: "Mayan Palace",
//     image: "/logo-mayan-palace.svg",
//   },
// ]

const backgroundResortImages = [
  {
    resort: "The Estates",
    image: "/the-estates.jpg",
  },
  {
    resort: "Grand Luxxe",
    image: "/grand-luxxe.jpg",
  },
  {
    resort: "The Grand Bliss",
    image: "/grand-bliss.jpg",
  },
  {
    resort: "The Grand Mayan",
    image: "/grand-mayan.jpg",
  },
  {
    resort: "The Bliss",
    image: "/bliss.jpg",
  },
  {
    resort: "Mayan Palace",
    image: "/mayan-palace.jpg",
  },
  {
    resort: "Residence At Grand Luxxe",
    image: "/residence.jpg",
  },
  {
    resort: "Le Blanc Los Cabos",
    image: "/residence-los-cabos.jpg",
  },
  {
    resort: "Moon Palace Nizuc",
    image: "residence-nizuc.jpg",
  },
  {
    resort: "Deluxxe At The Grand Mayan (Nuevo Vallarta)",
    image: "deluxxe.jpg",
  },
  {
    resort: "Empire Estates",
    image: "empire-estates.jpg",
  },
  {
    resort: "Park Units",
    image: "park-units.jpg",
  },
  {
    resort: "Kingdom Of The Sun III (Parque)",
    image: "kingdom.jpg",
  }
]


function ResortModal({ resort, closeModal }) {
  return (
    <Modal
      isOpen={!!resort}
      onRequestClose={closeModal}
      contentLabel="Resort Modal"
      className="bg-gray-100 rounded-lg mx-auto mt-10 p-6 max-w-3xl w-full h-5/6 overflow-y-auto border border-slate-500 shadow-lg"
      style={{
        overlay: {
          zIndex: 1000,
        },
      }}
    >
      {resort && (
        <>
        <h2 className="text-gray-800 text-2xl font-bold mb-4">{resort.resortName}</h2>
        <div className="mb-4">
          {[...resort.destinations].map((destination) => (
            <span key={destination} className="bg-blue-900 text-gray-100 px-2 py-1 mr-2 mb-2 inline-flex items-center text-sm rounded">
              {destination}
            </span>
          ))}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {resort.properties.map((property) => (
            <div key={property.name} className="bg-blue-100 rounded-lg flex flex-col">
              <img
                src={property.image || "/soon.jpg"}
                alt={property.name}
                className="w-full object-cover rounded-t-lg"
                style={{
                  height: '250px',
                }}
              />
              <h3
                className="text-lg font-semibold m-2 text-black z-10 relative"
              >
                {property.name}
              </h3>
            </div>
          ))}
        </div>
        <button
          onClick={closeModal}
          className="bg-gray-600 text-white px-4 py-2 mt-6 rounded hover:bg-red-600 transition duration-150"
        >
          Close
        </button>
      </>
      


      )}
    </Modal>
  );
}

function Rental() {
  const [selectedResort, setSelectedResort] = useState(null);

  const handleClick = (resort) => {
    setSelectedResort(resort);
  };

  const closeModal = () => {
    setSelectedResort(null);
  };

  const groupedResorts = resortData.reduce((acc, resort) => {
    if (!acc[resort.resort]) {
      acc[resort.resort] = {
        properties: [],
        destinations: new Set(),
      };
    }
    acc[resort.resort].properties.push(resort);
    resort.destino.split(", ").forEach((destination) => {
      acc[resort.resort].destinations.add(destination);
    });
    return acc;
  }, {});

  const availableResorts = Object.entries(groupedResorts).filter(
    ([_, resortData]) =>
      resortData.properties.some((property) => property.disponibilidad === "Si")
  );

  // const unavailableResorts = Object.entries(groupedResorts).filter(
  //   ([_, resortData]) =>
  //     resortData.properties.every((property) => property.disponibilidad !== "Si")
  // );

  const renderResorts = (resorts) => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {resorts.map(([resortName, resortData]) => {
        // Find the corresponding resort image
        // const resortImage = resortImages.find((r) => r.resort === resortName);
  
        const backgroundResortImage = backgroundResortImages.find((r) => r.resort === resortName);
  
        return (
          <button
            key={resortName}
            onClick={() => handleClick({ resortName, ...resortData })}
            className="bg-blue-100 rounded-lg shadow flex flex-col"
          >
            <img
              src={backgroundResortImage?.image}
              alt={resortName}
              className="w-full h-full object-cover rounded-t-lg"
            />
            <h2 className="text-lg font-semibold m-2 z-10 relative text-center">
              {resortName}
            </h2>
          </button>
        );
      })}
    </div>
  );
  

  return (
    <section className="bg-gray-50">
      <div className="container mx-auto px-4 py-6 mb-12">
        <h2 className="text-4xl font-bold leading-tight sm:text-4xl lg:text-5xl text-center  text-transparent gradient-text py-12">
          Properties
        </h2>
        <h1 className="text-2xl font-bold mb-">Available Resorts</h1>
        {renderResorts(availableResorts)}
{/* 
        <h1 className="text-2xl font-bold mt-10 mb-6">Coming Soon</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <button
            key="Park Units"
            className="bg-slate-200 rounded-lg shadow p-4 relative overflow-hidden disabled:opacity-100"
            disabled
          >
            <div
              className="absolute inset-0 bg-cover bg-center"
              style={{
                backgroundImage: `url("park-units.jpg")`,
              }}
            ></div>
            <img
              src={"blank.svg"}
              alt="Park Units"
              className="w-1/2 h-64 rounded opacity-60 mx-auto"
            />
            <h2 className="text-xl font-semibold mt-4 text-white z-10 relative px-2 py-1 bg-black bg-opacity-50">
              Park Units
            </h2>
          </button>
        </div> */}

        <ResortModal resort={selectedResort} closeModal={closeModal} />
      </div>
      <Footer />
    </section>
  );
}

export default Rental;