import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import Modal from 'react-modal';
import { toast } from "react-toastify";
import moment from 'moment';

import Select from 'react-select';

import { resortData } from "../data";

import { DataContext } from '../context/DataContext';
import { Plus } from 'lucide-react';

import * as XLSX from 'xlsx';

const Admin = () => {
  const { user } = useContext(DataContext);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [showOnlyNonNullValue, setShowOnlyNonNullValue] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [modalDetailsIsOpen, setDetailsIsOpen] = React.useState(false);
  const [weeks, setWeeks] = useState([]);
  const [onDemands, setOnDemands] = useState([]);
  const [isRentalDisabled, setIsRentalDisabled] = useState(false);
  const [isOnDemandDisabled, setIsOnDemandDisabled] = useState(false);
  const [limit, setLimit] = useState(0);

  const [onDemandProperty, setOnDemandProperty] = useState("");
  const [onDemandPlace, setOnDemandPlace] = useState("");
  const [onDemandWeeks, setOnDemandWeeks] = useState(1);
  const [onDemandYear, setOnDemandYear] = useState(2024);
  const [onDemandUsageFee, setOnDemandUsageFee] = useState("");
  const [onDemandFormattedUsageFee, setOnDemandFormattedUsageFee] = useState("");
  const [onDemandAmenities, setOnDemandAmenities] = useState([]);

  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedAmenities, setSelectedAmenities] = useState([]);

  const [selectedOnDemandProperty, setSelectedOnDemandProperty] = useState(null);
  const [selectedOnDemandWeeks, setSelectedOnDemandWeeks] = useState(null);
  const [selectedOnDemandYear, setSelectedOnDemandYear] = useState(null);
  const [selectedOnDemandAmenities, setSelectedOnDemandAmenities] = useState([]);
  const [sisterResorts, setSisterResorts] = useState([]);
  const [sisterResortsImage, setSisterResortsImage] = useState(null);
  const [onDemandComments, setOnDemandComments] = useState("");

  const [selectedRental, setSelectedRental] = useState(null);
  const [selectedOnDemand, setSelectedOnDemand] = useState(null);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [newModalIsOpen, setNewIsOpen] = useState(false);
  const [editModalRentalIsOpen, setEditRentalIsOpen] = useState(false);
  const [editModalOnDemandIsOpen, setEditOnDemandIsOpen] = useState(false);
  const [deleteModalRentalIsOpen, setDeleteRentalIsOpen] = useState(false);
  const [deleteModalOnDemandIsOpen, setDeleteOnDemandIsOpen] = useState(false);

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedPlanId, setSelectedPlanId] = useState(null);

  const [selectedPercentage, setSelectedPercentage] = useState(null);

  const [name, setName] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [usageFee, setUsageFee] = useState("");
  const [formattedUsageFee, setFormattedUsageFee] = useState("");
  const [amenities, setAmenities] = useState([]);
  const [confirmationFile, setConfirmationFile] = useState(null);
  const [comments, setComments] = useState("");

  const [inputFocused, setInputFocused] = useState(false);

  // ResortFilterForm state and handlers
  const [resorts, setResorts] = useState([]);
  const [names, setNames] = useState([]);
  const [destinations, setDestinations] = useState([]);

  const [selectedResort, setSelectedResort] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [selectedDestination, setSelectedDestination] = useState("");

  const [customAmenities, setCustomAmenities] = useState([]);


  // ResortFilterForm state and handlers
  const [onDemandResorts, setOnDemandResorts] = useState([]);
  const [onDemandNames, setOnDemandNames] = useState([]);
  const [onDemandDestinations, setOnDemandDestinations] = useState([]);

  const [selectedOnDemandResort, setSelectedOnDemandResort] = useState("");
  const [selectedOnDemandName, setSelectedOnDemandName] = useState("");
  const [selectedOnDemandDestination, setSelectedOnDemandDestination] = useState("");

  const [onDemandCustomAmenities, setOnDemandCustomAmenities] = useState([]);

  const [propertiesList, setPropertiesList] = useState([]);

  const [editResort, setEditResort] = useState("");
  const [editName, setEditName] = useState("");
  const [editDestination, setEditDestination] = useState("");
  const [editStartDate, setEditStartDate] = useState("");
  const [editEndDate, setEditEndDate] = useState("");
  const [editUsageFee, setEditUsageFee] = useState("");
  const [editFormattedUsageFee, setEditFormattedUsageFee] = useState("");
  const [editAmenities, setEditAmenities] = useState([]);
  const [editCustomAmenities, setEditCustomAmenities] = useState([]);
  const [editConfirmationFile, setEditConfirmationFile] = useState(null);
  const [editComments, setEditComments] = useState("");


  const [editOnDemandResort, setEditOnDemandResort] = useState("");
  const [editOnDemandName, setEditOnDemandName] = useState("");
  const [editOnDemandDestination, setEditOnDemandDestination] = useState("");
  const [editOnDemandYear, setEditOnDemandYear] = useState("");
  const [editOnDemandWeeks, setEditOnDemandWeeks] = useState("");
  const [editOnDemandUsageFee, setEditOnDemandUsageFee] = useState("");
  const [editOnDemandFormattedUsageFee, setEditOnDemandFormattedUsageFee] = useState("");
  const [editOnDemandSisterResorts, setEditOnDemandSisterResorts] = useState([]);
  const [editOnDemandSisterResortsImage, setEditOnDemandSisterResortsImage] = useState(null);
  const [editOnDemandAmenities, setEditOnDemandAmenities] = useState([]);
  const [editOnDemandCustomAmenities, setEditOnDemandCustomAmenities] = useState([]);
  const [editOnDemandComments, setEditOnDemandComments] = useState("");

  useEffect(() => {
    const propertiesList = Array.from(
      new Set(
        resortData
          .filter((row) => row.disponibilidad === "Si")
          .map((row) => ({
            value: `${row.resort} - ${row.name}`,
            label: `${row.resort} - ${row.name}`,
          }))
      )
    );
    setPropertiesList(propertiesList);
    console.log(propertiesList);
  }, []);

  useEffect(() => {
    console.log(resortData);
    const uniqueResorts = Array.from(
      new Set(
        resortData
          .filter((row) => row.disponibilidad === "Si")
          .map((row) => row.resort)
      )
    );
    setResorts(uniqueResorts);
  }, []);

  useEffect(() => {
    const filteredNames = Array.from(
      new Set(
        resortData
          .filter((row) => row.resort === selectedResort)
          .map((row) => row.name)
      )
    );
    setNames(filteredNames);

    // Set the default value for the "name" input when there is only one option available
    setSelectedName(filteredNames.length === 1 ? filteredNames[0] : "");
  }, [selectedResort]);

  useEffect(() => {
    const filteredDestinations = Array.from(
      new Set(
        resortData
          .filter(
            (row) => row.resort === selectedResort && row.name === selectedName
          )
          .map((row) => row.destino.split(",")) // Split the string by commas
          .flat() // Flatten the resulting array
      )
    );
    setDestinations(filteredDestinations);
    setSelectedDestination(
      filteredDestinations.length === 1 ? filteredDestinations[0] : ""
    );
  }, [selectedResort, selectedName]);


  useEffect(() => {
    const filteredNames = Array.from(
      new Set(
        resortData
          .filter((row) => row.resort === editResort)
          .map((row) => row.name)
      )
    );
    setNames(filteredNames);

    // Set the default value for the "name" input when there is only one option available
    // setEditName(filteredNames.length === 1 ? filteredNames[0] : "");
  }, [editResort]);

  useEffect(() => {
    const filteredDestinations = Array.from(
      new Set(
        resortData
          .filter(
            (row) => row.resort === editResort && row.name === editName
          )
          .map((row) => row.destino.split(",")) // Split the string by commas
          .flat() // Flatten the resulting array
      )
    );
    setDestinations(filteredDestinations);
    // setEditName(
    //   filteredDestinations.length === 1 ? filteredDestinations[0] : ""
    // );
  }, [editResort, editName]);

  useEffect(() => {
    const uniqueOnDemandResorts = Array.from(
      new Set(
        resortData
          // .filter((row) => row.disponibilidad === "Si")
          .map((row) => row.resort)
      )
    );
    setOnDemandResorts(uniqueOnDemandResorts);
  }, []);


  useEffect(() => {
    const filteredOnDemandNames = Array.from(
      new Set(
        resortData
          .filter((row) => row.resort === selectedOnDemandResort)
          .map((row) => row.name)
      )
    );
    setOnDemandNames(filteredOnDemandNames);

    // Set the default value for the "name" input when there is only one option available
    setSelectedOnDemandName(filteredOnDemandNames.length === 1 ? filteredOnDemandNames[0] : "");
  }, [selectedOnDemandResort]);


  useEffect(() => {
    const filteredOnDemandDestinations = Array.from(
      new Set(
        resortData
          .filter(
            (row) => row.resort === selectedOnDemandResort && row.name === selectedOnDemandName
          )
          .map((row) => row.destino.split(",")) // Split the string by commas
          .flat() // Flatten the resulting array
      )
    );
    setOnDemandDestinations(filteredOnDemandDestinations);
    setSelectedOnDemandDestination(
      filteredOnDemandDestinations.length === 1 ? filteredOnDemandDestinations[0] : ""
    );
  }, [selectedOnDemandResort, selectedOnDemandName]);



  useEffect(() => {
    const filteredOnDemandNames = Array.from(
      new Set(
        resortData
          .filter((row) => row.resort === editOnDemandResort)
          .map((row) => row.name)
      )
    );
    setOnDemandNames(filteredOnDemandNames);

    // Set the default value for the "name" input when there is only one option available
    // setEditOnDemandName(filteredOnDemandNames.length === 1 ? filteredOnDemandNames[0] : "");
  }, [editOnDemandResort]);


  useEffect(() => {
    const filteredOnDemandDestinations = Array.from(
      new Set(
        resortData
          .filter(
            (row) => row.resort === editOnDemandResort && row.name === editOnDemandName
          )
          .map((row) => row.destino.split(",")) // Split the string by commas
          .flat() // Flatten the resulting array
      )
    );
    setOnDemandDestinations(filteredOnDemandDestinations);
    // setEditOnDemandName(
    //   filteredOnDemandDestinations.length === 1 ? filteredOnDemandDestinations[0] : ""
    // );
  }, [editOnDemandResort, editOnDemandName]);


  useEffect(() => {
    if (startDate) {
      // add 7 days to the start date
      const endDate = moment(startDate).add(7, "days").format("YYYY-MM-DD");
      // set the end date
      setEndDate(endDate);
    }
  }, [startDate]);

  useEffect(() => {
    if (limit && weeks && onDemands) {
      if (weeks.length >= limit) {
        setIsRentalDisabled(true);
      } else {
        setIsRentalDisabled(false);
      }
      if (onDemands.length >= limit) {
        setIsOnDemandDisabled(true);
      } else {
        setIsOnDemandDisabled(false);
      }
    }
  }, [limit, weeks, onDemands]);

  useEffect(() => {
    if (limit && weeks && onDemands) {
      if (weeks.length >= limit) {
        setIsRentalDisabled(true);
      } else {
        setIsRentalDisabled(false);
      }
      if (onDemands.length >= limit) {
        setIsOnDemandDisabled(true);
      } else {
        setIsOnDemandDisabled(false);
      }
    }
  }, [limit, weeks, onDemands]);

  function openModal() {
    setDetailsIsOpen(true);
  }

  function closeModal() {
    setDetailsIsOpen(false);
  }

  useEffect(() => {
    // Fetch clients from API
    fetch('https://vrco.azurewebsites.net//api/RentalAgreement')
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setClients(data);
      });
  }, []);

  const handleViewDetails = (client) => {
    setSelectedClient(client);
    console.log(client);
    openModal();
  };

  const timestampToDate = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${month}/${day}/${year}`;
  };

  const handleSisterResorts = (selectedOptions) => {
    setSisterResorts(selectedOptions);
  };

  const handleEditSisterResorts = (selectedOptions) => {
    setEditOnDemandSisterResorts(selectedOptions);
  };

  const submitRentalInfo = () => {
    if (user) {
      console.log(JSON.stringify({
        emailAddress: selectedClient.email,
        rental: selectedResort + " " + selectedName + " " + selectedDestination,
        start: startDate,
        end: endDate,
        usageFee: usageFee,
        amenities: amenities.concat(customAmenities.map((amenity) => amenity.name)).join(","), // Convert the array to a string
        amenitiesDocuments: "",
        // confirmationOfReservation: confirmationFile,
        comments: comments,
        uid: selectedClient.uid
      })
      );

      fetch("https://vrco.azurewebsites.net//api/Rental", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          emailAddress: selectedClient.email,
          rental: selectedName,
          start: startDate,
          end: endDate,
          usageFee: usageFee,
          amenities: amenities.concat(customAmenities.map((amenity) => amenity.name)).join(","), // Convert the array to a string
          amenitiesDocuments: "",
          confirmationOfReservation: confirmationFile,
          uid: selectedClient.uid,
          resort: selectedResort,
          destination: selectedDestination,
          comments: comments
        }),
      })
        .then(() => {
          toast.success('Booked Week uploaded', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          fetch("https://vrco.azurewebsites.net//api/GetRental?uid=" + selectedClient.uid)
            .then((res) => res.json())
            .then((data) => {
              console.log(data);
              setWeeks(data);
            }
            );
          setSelectedResort("");
          setSelectedName("");
          setSelectedDestination("");
          setStartDate("");
          setEndDate("");
          setUsageFee("");
          setFormattedUsageFee("");
          setAmenities([]);
          setConfirmationFile(null);
          setCustomAmenities([]);
          setComments("");
        });
    }
  };

  const submitRentalModal = (e) => {
    e.preventDefault();
    setIsOpen(false);
    submitRentalInfo()
  };

  const submitOnDemandInfo = (e) => {
    e.preventDefault();
    if (user) {
      console.log(JSON.stringify({
        property: selectedOnDemandName,
        year: onDemandYear,
        noOfWeeks: onDemandWeeks,
        usageFee: onDemandUsageFee,
        sisterResorts: sisterResorts.map((resort) => resort.value).join(","), // Convert the array to a string
        sisterResortsFile: sisterResortsImage,
        amenities: onDemandAmenities.concat(onDemandCustomAmenities.map((amenity) => amenity.name)).join(","), // Convert the array to a string
        comments: onDemandComments,
        uid: selectedClient.uid,
        emailAddress: selectedClient.email,
        resort: selectedOnDemandResort,
        destination: selectedOnDemandDestination
      }));

      fetch("https://vrco.azurewebsites.net//api/OnDemand", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          property: selectedOnDemandName,
          year: onDemandYear,
          noOfWeeks: onDemandWeeks,
          usageFee: onDemandUsageFee,
          sisterResorts: sisterResorts.map((resort) => resort.value).join(","), // Convert the array to a string
          sisterResortsFile: sisterResortsImage,
          amenities: onDemandAmenities.concat(onDemandCustomAmenities.map((amenity) => amenity.name)).join(","), // Convert the array to a string
          comments: onDemandComments,
          uid: selectedClient.uid,
          emailAddress: selectedClient.email,
          resort: selectedOnDemandResort,
          destination: selectedOnDemandDestination
        }),
      })
        .then(() => {
          toast.success('On-Demand Rental uploaded', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          fetch("https://vrco.azurewebsites.net//api/GetOnDemand?uid=" + selectedClient.uid)
            .then((res) => res.json())
            .then((data) => {
              console.log(data);
              setOnDemands(data);
            }
            );
          setSelectedOnDemandResort("");
          setSelectedOnDemandName("");
          setSelectedOnDemandDestination("");
          setOnDemandYear(2024);
          setOnDemandWeeks(1);
          setOnDemandUsageFee("");
          setOnDemandFormattedUsageFee("");
          setSisterResorts([]);
          setSisterResortsImage(null);
          setOnDemandAmenities([]);
          setOnDemandCustomAmenities([]);
          setOnDemandComments("");
        });
    }
  };

  const submitOnDemandModal = (e) => {
    e.preventDefault();
    setNewIsOpen(false);
    submitOnDemandInfo(e)
  };

  const editRental = (week) => {
    setSelectedRental(week);
    setEditRentalIsOpen(true);
  }

  
  const deleteRental = (week) => {
    setSelectedRental(week);
    setDeleteRentalIsOpen(true);
  }

  const deleteRentalModal = (week) => {
    fetch("https://vrco.azurewebsites.net//api/Rental", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        id: week.id,
        uid: selectedClient.uid,
        emailAddress: selectedClient.email
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        toast.success('Booked Week deleted', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(data);
        fetch("https://vrco.azurewebsites.net//api/GetRental?uid=" + user.uid)
          .then((res) => res.json())
          .then((data) => {
            setWeeks(data);
          }
          );
      });
    setDeleteRentalIsOpen(false);
  }

  const deleteOnDemand = (week) => {
    setSelectedOnDemand(week);
    setDeleteOnDemandIsOpen(true);
  }

  const deleteOnDemandModal = (week) => {
    fetch("https://vrco.azurewebsites.net//api/OnDemand", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        id: week.id,
        uid: selectedClient.uid,
        emailAddress: selectedClient.email
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        toast.success('On-Demand Rental deleted', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        fetch("https://vrco.azurewebsites.net//api/GetOnDemand?uid=" + user.uid)
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            setOnDemands(data);
          });
      });
    setDeleteOnDemandIsOpen(false);
  }


  const editOnDemand = (week) => {
    setSelectedOnDemand(week);
    setEditOnDemandIsOpen(true)
  }

  useEffect(() => {
    if (selectedOnDemand) {
      console.log(selectedOnDemand);
      setEditOnDemandResort(selectedOnDemand.resort);
      setEditOnDemandName(selectedOnDemand.property);
      setEditOnDemandDestination(selectedOnDemand.destination);
      setEditOnDemandYear(selectedOnDemand.year);
      setEditOnDemandWeeks(selectedOnDemand.noOfWeeks);
      setEditOnDemandUsageFee(selectedOnDemand.usageFee);
      setEditOnDemandFormattedUsageFee(formatUsageFee(selectedOnDemand.usageFee));
      setEditOnDemandSisterResorts(selectedOnDemand.sisterResorts?.split(",").map((resort) => ({ value: resort, label: resort })));
      setEditOnDemandAmenities(selectedOnDemand.amenities.split(",").filter((amenity) => amenitiesList.includes(amenity)));
      const values = selectedOnDemand.amenities.split(",").filter((amenity) => !amenitiesList.includes(amenity));
      setEditOnDemandCustomAmenities(values.map((amenity) => ({ name: amenity, checked: true })));
      setEditOnDemandComments(selectedOnDemand.comments);
    }
  }, [selectedOnDemand])

  useEffect(() => {
    if (selectedRental) {
      console.log(selectedRental);
      const [property, city] = splitPropertyAndCity(selectedRental.rental);
      setSelectedProperty(property);
      setSelectedPlace(city);
      setSelectedStartDate(selectedRental.start.substring(0, 10));
      setSelectedEndDate(selectedRental.end.substring(0, 10));
      setSelectedAmenities(selectedRental.amenities.split(","));
    }
  }, [selectedRental])

  useEffect(() => {
    if (selectedOnDemand) {
      setSelectedOnDemandProperty(selectedOnDemand.property);
      setSelectedOnDemandYear(selectedOnDemand.year);
      setSelectedOnDemandWeeks(selectedOnDemand.noOfWeeks);
      setSelectedOnDemandAmenities(selectedOnDemand.amenities.split(","));
    }
  }, [selectedOnDemand])

  useEffect(() => {
    if (startDate) {
      // add 7 days to the start date
      const endDate = moment(startDate).add(7, "days").format("YYYY-MM-DD");
      // set the end date
      setEndDate(endDate);
    }
  }, [startDate]);

  const splitPropertyAndCity = (property) => {
    // possible cities are: Nuevo Vallarta, Puerto Vallarta, San Jose del Cabo, Puerto Penasco, Acapulco, Mazatlan and Riviera Maya
    const cities = ["Nuevo Vallarta", "Puerto Vallarta", "San Jose del Cabo", "Puerto Penasco", "Acapulco", "Mazatlan", "Riviera Maya"];
    let city = "";
    for (let i = 0; i < cities.length; i++) {
      if (property.includes(cities[i])) {
        city = cities[i];
        break;
      }
    }
    console.log(property.replace(city, "").trim());
    return [property.replace(city, "").trim(), city];
  }

  useEffect(() => {
    if (selectedClient) {
      setSelectedPlanId(selectedClient.planId);
      setSelectedPercentage(selectedClient.percentage);
      console.log(selectedClient);
      fetch("https://vrco.azurewebsites.net//api/GetRental?uid=" + selectedClient.uid)
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setWeeks(data);
        }
        );
      fetch("https://vrco.azurewebsites.net//api/GetOnDemand?uid=" + selectedClient.uid)
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setOnDemands(data);
        }
        );
    }
  }, [selectedClient]);

  const getPlan = (value) => {
    let plan = '';
    // if (value === '1,399' || value === '1,499' || value === '1,699') {
    //   plan = 'Starter Plan';
    // } else if (value === '1,799' || value === '1,899' || value === '1,999.99') {
    //   plan = 'Pro Plan';
    // } else if (value === '999') {
    //   plan = 'Limited T.S. Plan';
    // } else if (value === '4,999') {
    //   plan = 'Expert Plan';
    // } else if (value === '3,000' || value === '2,999') {
    //   plan = 'Mixed Pro Plan';
    // } else if (value === '9,999') {
    //   plan = 'Unlimited';
    // } else if (value === '1,999') {
    //   plan = 'Standard Plan';
    // } else if (value === '2,499') {
    //   plan = 'Extended Plan';
    // } else if (value === '3,499') {
    //   plan = 'Premium Plan';
    // } else if (value === '7,999') {
    //   plan = 'Supreme Plan';
    // } else if (value === '14,999') {
    //   plan = 'Expert Priority Plan'
    // } else {
    plan = value;
    // }
    return plan;
  };

  useEffect(() => {
    if (selectedClient) {
      let limit = 0;
      if (selectedClient.value === '1,399' || selectedClient.value === '1,499' || selectedClient.value === '1,699' || selectedClient.value === '1,999' || selectedClient.value === '3,499') {
        limit = 2;
      } else if (selectedClient.value === '1,799' || selectedClient.value === '1,899' || selectedClient.value === '1,999.99' || selectedClient.value === '2,499') {
        limit = 5;
      } else if (selectedClient.value === '999') {
        limit = 2;
      } else if (selectedClient.value === '4,999') {
        limit = 10;
      } else if (selectedClient.value === '3,000' || selectedClient.value === '2,999' || selectedClient.value === '7,999') {
        limit = 3;
      } else if (selectedClient.value === '9,999') {
        limit = 100;
      }
      setLimit(limit);
    }
  }, [selectedClient]);

  const filteredClients = clients.filter(client => {
    if (showOnlyNonNullValue && client.currentPlan === null) {
      return false;
    }
    if (searchQuery === '') {
      return true;
    }
    const searchRegex = new RegExp(searchQuery, 'i');
    return searchRegex.test(client.name) || searchRegex.test(client.email) || searchRegex.test(client.bookedWeeks) || searchRegex.test(client.onDemands);
  });

  const handleFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setConfirmationFile(reader.result);
    };
  };

  const handleEditFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setEditConfirmationFile(reader.result);
    };
  };

  const handleSisterResortsFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setSisterResortsImage(reader.result);
    };
  };

  const handleEditSisterResortsFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setEditOnDemandSisterResortsImage(reader.result);
    };
  };

  useEffect(() => {
    if (selectedRental) {
      console.log(selectedRental);
      setEditResort(selectedRental.resort);
      setEditName(selectedRental.rental);
      setEditDestination(selectedRental.destination);
      setEditStartDate(selectedRental.start.split(" ")[0]);
      setEditEndDate(selectedRental.end.split(" ")[0]);
      setEditUsageFee(selectedRental.usageFee);
      setEditFormattedUsageFee(formatUsageFee(selectedRental.usageFee));
      setEditAmenities(selectedRental.amenities.split(",").filter((amenity) => amenitiesList.includes(amenity)));
      const values = selectedRental.amenities.split(",").filter((amenity) => !amenitiesList.includes(amenity));
      setEditCustomAmenities(values.map((amenity) => ({ name: amenity, checked: true })));
      setEditComments(selectedRental.comments);
    }
  }, [selectedRental])

  const editRentalModal = (e) => {
    e.preventDefault();
    setEditRentalIsOpen(false);
    const requestBody = {
      id: selectedRental.id,
      emailAddress: selectedClient.email,
      uid: selectedClient.uid,
      rental: editName,
      start: editStartDate,
      end: editEndDate,
      usageFee: editUsageFee,
      amenities: editAmenities.concat(editCustomAmenities.map((amenity) => amenity.name)).join(","), // Convert the array to a string
      amenitiesDocuments: "",
      resort: editResort,
      destination: editDestination,
      comments: editComments
    };
    console.log(requestBody);
    if (editConfirmationFile) {
      requestBody.confirmationOfReservation = editConfirmationFile;
    }
    fetch("https://vrco.azurewebsites.net//api/Rental", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(requestBody),
    })
      .then((res) => res.json())
      .then((data) => {
        toast.success('Booked Week updated', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(data);
        fetch("https://vrco.azurewebsites.net//api/GetRental?uid=" + user.uid)
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            setWeeks(data);
          }
          );
      });
    setEditName("");
    setEditStartDate("");
    setEditEndDate("");
    setEditUsageFee("");
    setEditFormattedUsageFee("");
    setEditAmenities([]);
    setEditConfirmationFile(null);
    setEditCustomAmenities([]);
    setEditComments("");
  };

  const addPlan = (e, planId) => {

    let value = '';

    if (planId === 19) {
      value = '2,499';
    } else if (planId === 20) {
      value = '4,999';
    } else if (planId === 21) {
      value = '9,999';
    } else if (planId === 22) {
      value = '14,999';
    } else {
      value = '19,999';
    }

    e.preventDefault();
    console.log(value)
    console.log(selectedClient.uid)
    console.log(selectedClient.email)
    console.log(planId)
    fetch("https://vrco.azurewebsites.net//api/ConfirmPayment", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        uid: selectedClient.uid,
        payment: value,
        planId: planId,
        email: selectedClient.email
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      }
      );
  };

  const updatePlan = (e, planId) => {
    e.preventDefault();
    console.log(planId)
    console.log(selectedClient.uid)
    fetch("https://vrco.azurewebsites.net//api/UpdatePayment", {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        uid: selectedClient.uid,
        planId: planId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        toast.success('Plan updated, Refresh website to see changes', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      );
  };

  const updatePercentage = (e, value) => {
    e.preventDefault();
    console.log(value)
    console.log(selectedClient.uid)
    fetch("https://vrco.azurewebsites.net//api/UpdatePercentage", {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        uid: selectedClient.uid,
        percentage: value
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        toast.success('Percentage updated, Refresh website to see changes', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      );
  };

  const editOnDemandModal = (e) => {
    e.preventDefault();
    setEditOnDemandIsOpen(false);
    const requestBody = {
      id: selectedOnDemand.id,
      emailAddress: selectedClient.email,
      uid: selectedClient.uid,
      property: editOnDemandName,
      year: editOnDemandYear,
      noOfWeeks: editOnDemandWeeks,
      usageFee: editOnDemandUsageFee,
      sisterResorts: editOnDemandSisterResorts.map((resort) => resort.value).join(","), // Convert the array to a string
      amenities: editOnDemandAmenities.concat(editOnDemandCustomAmenities.map((amenity) => amenity.name)).join(","), // Convert the array to a string
      comments: editOnDemandComments,
      resort: editOnDemandResort,
      destination: editOnDemandDestination
    };
    console.log(requestBody);
    if (editOnDemandSisterResortsImage) {
      requestBody.sisterResortsFile = editOnDemandSisterResortsImage;
    }
    fetch("https://vrco.azurewebsites.net//api/OnDemand", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(requestBody),
    })
      .then((res) => res.json())
      .then((data) => {
        toast.success('On-Demand Rental updated', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(data);
        fetch("https://vrco.azurewebsites.net//api/GetOnDemand?uid=" + user.uid)
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            setOnDemands(data);
          });
        // setEditOnDemandName("");
      });
  };

  const addName = (e) => {
    e.preventDefault();
    if (user) {
      fetch("https://vrco.azurewebsites.net//api/SignTyC", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fullName: name,
          mobileNumber: "",
          address: "",
          city: "",
          state: "",
          email: selectedClient.email,
          uid: selectedClient.uid,
        }),
      })
        .then((res) => res.json())
        .catch((err) => {
          console.log(err);
        }
        );
    }
  };

  const amenitiesList = [
    "Resort Platinum Status",
    "2 for 1 - 50 minutes Spa massages",
    "Free Massages",
    "Discounted Rounds of Golf",
    "2 to 16 Free Rounds of Golf",
    "Access to Air Gondola"
  ];

  const handleAmenities = (e) => {
    const { value } = e.target;
    if (amenities.includes(value)) {
      setAmenities(amenities.filter((amenity) => amenity !== value));
    } else {
      setAmenities([...amenities, value]);
    }
  };

  const handleOnDemandAmenities = (e) => {
    const { value } = e.target;
    if (onDemandAmenities.includes(value)) {
      setOnDemandAmenities(onDemandAmenities.filter((amenity) => amenity !== value));
    } else {
      setOnDemandAmenities([...onDemandAmenities, value]);
    }
  };

  const handleEditOnDemandAmenities = (e) => {
    const { value } = e.target;
    if (editOnDemandAmenities.includes(value)) {
      setEditOnDemandAmenities(editOnDemandAmenities.filter((amenity) => amenity !== value));
    } else {
      setEditOnDemandAmenities([...editOnDemandAmenities, value]);
    }
  };

  const handleRentalAmenities = (e) => {
    const { value } = e.target;
    if (editAmenities.includes(value)) {
      setEditAmenities(editAmenities.filter((amenity) => amenity !== value));
    } else {
      setEditAmenities([...editAmenities, value]);
    }
  };

  const handleOnDemandRentalAmenities = (e) => {
    const { value } = e.target;
    if (selectedOnDemandAmenities.includes(value)) {
      setSelectedOnDemandAmenities(selectedOnDemandAmenities.filter((amenity) => amenity !== value));
    } else {
      setSelectedOnDemandAmenities([...selectedOnDemandAmenities, value]);
    }
  };

  const handleAddCustomAmenity = (e) => {
    if (e.key === "Enter") {
      setCustomAmenities([
        ...customAmenities,
        { name: e.target.value, checked: true },
      ]);
      e.target.value = "";
    }
  };

  const handleEditAddCustomAmenity = (e) => {
    if (e.key === "Enter") {
      setEditCustomAmenities([
        ...editCustomAmenities,
        { name: e.target.value, checked: true },
      ]);
      e.target.value = "";
    }
  };

  const handleEditOnDemandAddCustomAmenity = (e) => {
    if (e.key === "Enter") {
      setEditOnDemandCustomAmenities([
        ...editOnDemandCustomAmenities,
        { name: e.target.value, checked: true },
      ]);
      e.target.value = "";
    }
  };

  const deleteCustomAmenity = (index) => {
    const updatedAmenities = [...customAmenities];
    updatedAmenities.splice(index, 1);
    setCustomAmenities(updatedAmenities);
  };

  const editDeleteCustomAmenity = (index) => {
    const updatedAmenities = [...editCustomAmenities];
    updatedAmenities.splice(index, 1);
    setEditCustomAmenities(updatedAmenities);
  };

  const editOnDemandDeleteCustomAmenity = (index) => {
    const updatedAmenities = [...editOnDemandCustomAmenities];
    updatedAmenities.splice(index, 1);
    setEditOnDemandCustomAmenities(updatedAmenities);
  };

  const handleCustomAmenitiesCheck = (e, index) => {
    const updatedAmenities = [...customAmenities];
    updatedAmenities[index].checked = e.target.checked;
    setCustomAmenities(updatedAmenities);
  };

  const editHandleCustomAmenitiesCheck = (e, index) => {
    const updatedAmenities = [...editCustomAmenities];
    updatedAmenities[index].checked = e.target.checked;
    setEditCustomAmenities(updatedAmenities);
  };

  const editHandeOnDemandCustomAmenitiesCheck = (e, index) => {
    const updatedAmenities = [...editOnDemandCustomAmenities];
    updatedAmenities[index].checked = e.target.checked;
    setEditOnDemandCustomAmenities(updatedAmenities);
  };

  const handleOnDemandAddCustomAmenity = (e) => {
    if (e.key === "Enter") {
      setOnDemandCustomAmenities([
        ...onDemandCustomAmenities,
        { name: e.target.value, checked: true },
      ]);
      e.target.value = "";
    }
  };

  const deleteOnDemandCustomAmenity = (index) => {
    const updatedAmenities = [...onDemandCustomAmenities];
    updatedAmenities.splice(index, 1);
    setOnDemandCustomAmenities(updatedAmenities);
  };

  const handleOnDemandCustomAmenitiesCheck = (e, index) => {
    const updatedAmenities = [...onDemandCustomAmenities];
    updatedAmenities[index].checked = e.target.checked;
    setOnDemandCustomAmenities(updatedAmenities);
  };

  const formatUsageFee = (value) => {
    if (!value) {
      return "";
    }
    if (value < 0) {
      value = 0;
    }
    return parseFloat(value).toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  };

  const downloadExcel = () => {
    const filteredClients = clients.filter(client => {
      if (showOnlyNonNullValue && client.currentPlan === null) {
        return false;
      }
      return true;
    });
    const ws = XLSX.utils.json_to_sheet(filteredClients);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `vrco-clients-${new Date().toLocaleDateString()}.xlsx`);
  };

  return (
    <>
      {(user?.uid === "0NKGm5aaXJdmnGSDXBlUGbgmlg33" || user?.uid === "tWQDjskqLvYmTgWJD5n2PCuMch43" || user?.uid === "zfmWT6ASCAgFiTl5kfAlH2nJ68u1" || user?.uid === "Q3w1Ym98lhdv9xTbOuZ49UybedJ3") ? (
        <div className="flex flex-col items-center">
          <Modal isOpen={modalDetailsIsOpen} onRequestClose={closeModal} contentLabel="Example Modal" className="relative bg-white rounded-lg mx-auto mt-10 p-6 max-w-6xl h-[90%] overflow-y-auto border border-gray-400">
            <div className="absolute top-3 right-3">
              <button
                className="close cursor-pointer p-1 focus:outline-none"
                onClick={() => setDetailsIsOpen(false)}
              >
                <svg
                  className="w-8 h-8 text-gray-700 hover:text-red-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 9.292L5.858 5.15 4.444 6.564 8.586 10.707 4.444 14.85l1.414 1.414L10 12.121l4.142 4.143 1.414-1.414L11.414 10.707 15.556 6.564 14.142 5.15 10 9.292z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <div className="flex flex-col items-center">
              <h1 className="text-2xl font-medium">Client Details</h1>
              <div className="flex flex-col items-center">
                <label className="text-xl font-medium">Name</label>
                <p>{selectedClient?.name} ({selectedClient?.phoneNumber})</p>
                {selectedClient?.name === null && (
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="name" type="text" placeholder="Name" onChange={e => setName(e.target.value)} />
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={addName}>Add Name</button>
                  </div>
                )}
              </div>
              <div className="flex flex-col items-center">
                <label className="text-xl font-medium">Email</label>
                <p>{selectedClient?.email}</p>
              </div>
              <div className="flex flex-col items-center">
                <label className="text-xl font-medium">Plan</label>
                <p>{selectedClient?.planName}</p>
                {selectedClient?.currentPlan === null ? (
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <select class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500" name='plan' onChange={(e) => setSelectedPlanId(e.target.value)} value={selectedPlanId}>
                      <option value="">Select Plan</option>
                      <option value="19">Standard Plan</option>
                      <option value="20">Extended Plan</option>
                      <option value="21">Premium Plan</option>
                      <option value="22">Expert Priority Plan</option>
                    </select>
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={e => addPlan(e, selectedPlanId)}>Add Plan</button>
                  </div>
                ) : (
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <select class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500" name='plan' onChange={(e) => setSelectedPlanId(e.target.value)} value={selectedPlanId}>
                      <option value="">Select Plan</option>
                      <option value="19">Standard Plan</option>
                      <option value="20">Extended Plan</option>
                      <option value="21">Premium Plan</option>
                      <option value="22">Expert Priority Plan</option>
                    </select>
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={e => updatePlan(e, selectedPlanId)}>Update Plan</button>
                  </div>
                )}
              </div>
              <div className="flex flex-col items-center">
                <label className="text-xl font-medium">Percentage</label>
                <p>{selectedClient?.percentage}%</p>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <select class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500" name='percentage' onChange={(e) => setSelectedPercentage(e.target.value)} value={selectedPercentage}>
                    <option value="">Select Percentage</option>
                    <option value="10">10%</option>
                    <option value="15">15%</option>
                    <option value="20">20%</option>
                    <option value="25">25%</option>
                  </select>
                  <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={e => updatePercentage(e, selectedPercentage)}>Update Percentage</button>
                </div>
              </div>
              <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                <div className="add terms px-8 md:px-30 xl:px-60">
                  <div className="py-4 px-4 mx-auto max-w-screen-xl lg:px-6">
                    <div className="space-y-8 sm:gap-6 xl:gap-10 lg:space-y-0 md:space-y-0">
                      <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow xl:p-8 cursor-pointer" onClick={() => setIsOpen(true)} disabled={isRentalDisabled}>
                        <div className="flex items-center justify-center flex-col">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-indigo-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                          </svg>
                          <p className="text-indigo-500">Add Booked Week</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="add terms px-8 md:px-30 xl:px-60">
                  <div className="py-4 px-4 mx-auto max-w-screen-xl lg:px-6">
                    <div className="space-y-8 sm:gap-6 xl:gap-10 lg:space-y-0 md:space-y-0">
                      <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow xl:p-8 cursor-pointer" onClick={() => setNewIsOpen(true)} disabled={isOnDemandDisabled}>
                        <div className="flex items-center justify-center flex-col">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-indigo-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                          </svg>
                          <p className="text-indigo-500">Add On-Demand Rental</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {weeks.map((week, index) => (
                  <div className="terms md:px-30 xl:px-60" key={week.id}>
                    <div className="py-4 px-4 mx-auto max-w-screen-xl lg:px-6">
                      <div className="space-y-8 sm:gap-6 xl:gap-10 lg:space-y-0 md:space-y-0">
                        <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow xl:p-8">
                          <div className="flex justify-between items-center">
                            <div className="top-0 left-0 flex items-center">
                              <button className="rounded-full bg-gray-400 p-1 hover:bg-gray-600 mx-1 text-xs disabled:opacity-50" onClick={() => editRental(week)}>
                                <img src="/edit.svg" className="w-4"></img>
                              </button>
                              <button className=" rounded-full bg-gray-400 p-1 hover:bg-gray-600 mx-1 text-xs disabled:opacity-50" onClick={() => deleteRental(week)}>
                                <img src="/delete.svg" className="w-4"></img>
                              </button>
                            </div>
                            <div className="flex justify-end">
                              <span className={"px-2 py-1 text-xs font-semibold tracking-wider rounded-full bg-green-300"}>Booked Week</span>
                            </div>
                          </div>
                          <h3 className="my-4 text-2xl font-semibold">{week.resort} {week.rental} {week.destination}</h3>
                          <ul role="list" className="mb-8 space-y-4 text-left">
                            <li className="flex items-center space-x-3">
                              <span>Start:</span> <span className="font-semibold">{timestampToDate(week.start)}</span>
                            </li>
                            <li className="flex items-center space-x-3">
                              <span>End:</span> <span className="font-semibold">{timestampToDate(week.end)}</span>
                            </li>
                            <li className="flex items-center space-x-3">
                              <span>Amenities:</span> <span className="font-semibold">{week.amenities}</span>
                            </li>
                            <li className="flex items-center space-x-3">
                              <span>Usage fee:</span> <span className="font-semibold">$ {formatUsageFee(week.usageFee)}</span>
                            </li>
                            {week.comments && (
                              <li className="flex items-center space-x-3">
                                <span>Comments:</span> <span className="font-semibold">{week.comments}</span>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {onDemands.map((week, index) => (
                  <div className="terms md:px-30 xl:px-60" key={week.id}>
                    <div className="py-4 px-4 mx-auto max-w-screen-xl lg:px-6">
                      <div className="space-y-8 sm:gap-6 xl:gap-10 lg:space-y-0 md:space-y-0">
                        <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow xl:p-8">
                          <div className="flex justify-between items-center">
                            <div className="top-0 left-0 flex items-center">
                              <button className="rounded-full bg-gray-400 p-1 hover:bg-gray-600 mx-1 text-xs disabled:opacity-50" onClick={() => editOnDemand(week)}>
                                <img src="/edit.svg" className="w-4"></img>
                              </button>
                              <button className="rounded-full bg-gray-400 p-1 hover:bg-gray-600 mx-1 text-xs disabled:opacity-50" onClick={() => deleteOnDemand(week)}>
                                <img src="/delete.svg" className="w-4"></img>
                              </button>
                            </div>
                            <div className="flex justify-end">
                              <span className={"px-2 py-1 text-xs font-semibold tracking-wider rounded-full bg-pink-300"}>On Demand</span>
                            </div>
                          </div>
                          <h3 className="my-4 text-2xl font-semibold">{week.resort} {week.property}</h3>
                          {/* <p className="font-light text-gray-500 sm:text-lg ">Best option for rental properties</p>
                              <div className="flex justify-center items-baseline my-8">
                                <span className="mr-2 text-5xl font-extrabold">$1,699</span>
                                <span className="text-gray-500">USD</span>
                              </div> */}
                          <ul role="list" className="mb-8 space-y-4 text-left">
                            <li className="flex items-center space-x-3">
                              <span>Year:</span> <span className="font-semibold">{week.year}</span>
                            </li>
                            <li className="flex items-center space-x-3">
                              <span>Number of Weeks:</span> <span className="font-semibold">{week.noOfWeeks}</span>
                            </li>
                            <li className="flex items-center space-x-3">
                              <span>Usage fee:</span> <span className="font-semibold">{formatUsageFee(week.usageFee)}</span>
                            </li>
                            <li className="flex items-center space-x-3">
                              <span>Sister Resorts:</span> <span className="font-semibold">{week.sisterResorts}</span>
                            </li>
                            <li className="flex items-center space-x-3">
                              <span>Amenities:</span> <span className="font-semibold">{week.amenities}</span>
                            </li>
                            {week.comments && (
                              <li className="flex items-center space-x-3">
                                <span>Comments:</span> <span className="font-semibold">{week.comments}</span>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Modal>
          <Modal isOpen={modalIsOpen} onRequestClose={() => setIsOpen(false)} className="relative bg-white rounded-lg mx-auto mt-10 p-6 max-w-5xl h-[90%] overflow-y-auto border border-gray-400">
            <div className="px-2 md:px-16">
              <div className="rental-info">
                <div className="absolute top-3 right-3">
                  <button
                    className="close cursor-pointer p-1 focus:outline-none"
                    onClick={() => setIsOpen(false)}
                  >
                    <svg
                      className="w-8 h-8 text-gray-700 hover:text-red-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 9.292L5.858 5.15 4.444 6.564 8.586 10.707 4.444 14.85l1.414 1.414L10 12.121l4.142 4.143 1.414-1.414L11.414 10.707 15.556 6.564 14.142 5.15 10 9.292z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <h2 className="text-2xl font-bold leading-tight text-black sm:text-2xl lg:text-3xl py-8">Add Booked Week</h2>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-3/12">
                    <label className="text-gray-600 block md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                      Resort
                    </label>
                  </div>
                  <div className="md:w-8/12">
                    <select
                      id="resort"
                      value={selectedResort}
                      onChange={(e) => setSelectedResort(e.target.value)}
                      className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
                    >
                      <option value="">Select a resort</option>
                      {resorts.map((resort, index) => (
                        <option key={index} value={resort}>
                          {resort}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-3/12">
                    <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                      Property
                    </label>
                  </div>
                  <div className="md:w-8/12 relative">
                    <input
                      id="name"
                      value={selectedName}
                      onChange={(e) => setSelectedName(e.target.value)}
                      className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500 disabled:opacity-50"
                      list="name-datalist"
                      disabled={!selectedResort}
                      autoComplete="off"
                      placeholder="Type your property"
                      onFocus={() => setInputFocused(true)}
                      onBlur={() => setInputFocused(false)}
                    />
                    <datalist id="name-datalist">
                      {names.map((name, index) => (
                        <option key={index} value={name} />
                      ))}
                    </datalist>
                    {!inputFocused && !selectedName && (
                      <span className="absolute right-0 bottom-0 mb-1 mr-1 text-xs text-gray-500">If your property is not on the list you can type it</span>
                    )}
                  </div>
                </div>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-3/12">
                    <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                      Destination
                    </label>
                  </div>
                  <div className="md:w-8/12 relative">
                    <select
                      id="destination"
                      value={selectedDestination}
                      onChange={(e) => setSelectedDestination(e.target.value)}
                      className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500 disabled:opacity-50"
                      disabled={!selectedResort || !selectedName}
                    >
                      <option value="">Select a destination</option>
                      {destinations.map((destination, index) => (
                        <option key={index} value={destination}>
                          {destination}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-3/12">
                    <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                      Start Date
                    </label>
                  </div>
                  <div className="md:w-8/12 relative">
                    <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500" id="inline-full-name" type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                  </div>
                </div>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-3/12">
                    <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                      End Date
                    </label>
                  </div>
                  <div className="md:w-8/12 relative">
                    <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500" id="inline-full-name" type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} disabled={true} />
                  </div>
                </div>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-3/12">
                    <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                      Usage fee
                    </label>
                  </div>
                  <div className="md:w-8/12 relative">
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <span className="text-gray-600">$</span>
                      </div>
                      <input
                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 pl-8 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
                        id="usage-fee"
                        placeholder="Enter your usage fee as specified in the contract."
                        type="text"
                        value={formattedUsageFee}
                        onChange={(e) => {
                          const value = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
                          if (value === "") {
                            setUsageFee("");
                            setFormattedUsageFee("");
                          } else {
                            setUsageFee(value);
                            setFormattedUsageFee(formatUsageFee(value));
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row justify-start items-start w-full gap-4">
                  <div className="flex flex-col md:w-full lg:w-5/12">
                    <p className="block text-gray-600 mb-1 md:mb-0 pt-4 pb-3">
                      Select your amenities privileges
                    </p>
                    <div className="flex flex-col px-6 pb-6">
                      <label className="inline-flex items-center py-2">
                        <input className="form-checkbox text-indigo-600" type="checkbox" id="resort" name="Resort Platinum Status" value="Resort Platinum Status" onChange={handleAmenities} />
                        <span className="ml-2">Resort Platinum Status</span>
                      </label>
                      <label className="inline-flex items-center py-2">
                        <input type="checkbox" className="form-checkbox text-indigo-600" id="2for1" name="2 for 1 - 50 minutes Spa massages" value="2 for 1 - 50 minutes Spa massages" onChange={handleAmenities} />
                        <span className="ml-2">2 for 1 - 50 minutes Spa massages</span>
                      </label>
                      <label className="inline-flex items-center py-2">
                        <input type="checkbox" className="form-checkbox text-indigo-600" id="massages" name="Free Massages" value="Free Massages" onChange={handleAmenities} />
                        <span className="ml-2">Free Massages</span>
                      </label>
                      <label className="inline-flex items-center py-2">
                        <input type="checkbox" id="golf" name="Discounted Rounds of Golf" value="Discounted Rounds of Golf" onChange={handleAmenities} className="form-checkbox text-indigo-600" />
                        <span className="ml-2">Discounted Rounds of Golf</span>
                      </label>
                      <label className="inline-flex items-center py-2">
                        <input type="checkbox" id="golf1" name="2 to 16 Free Rounds of Golf" value="2 to 16 Free Rounds of Golf" onChange={handleAmenities} className="form-checkbox text-indigo-600" />
                        <span className="ml-2">2 to 16 Free Rounds of Golf</span>
                      </label>
                      <label className="inline-flex items-center py-2">
                        <input type="checkbox" id="gondola" name="Access to Air Gondola" value="Access to Air Gondola" onChange={handleAmenities} className="form-checkbox text-indigo-600" />
                        <span className="ml-2">Access to Air Gondola</span>
                      </label>
                      {customAmenities.map((amenity, index) => (
                        <div className="inline-flex items-center py-2" key={index}>
                          <input
                            type="checkbox"
                            id={`custom-${index}`}
                            name={amenity.name}
                            value={amenity.name}
                            checked={amenity.checked}
                            onChange={(e) => handleCustomAmenitiesCheck(e, index)}
                            className="form-checkbox text-indigo-600"
                          />
                          <span className="ml-2">{amenity.name}</span>
                          <button
                            className="ml-4 text-gray-400 hover:text-red-800 transition duration-150 ease-in-out focus:outline-none"
                            onClick={() => deleteCustomAmenity(index)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 9.293l5.707-5.707a1 1 0 011.414 1.414L11.414 10l5.707 5.707a1 1 0 01-1.414 1.414L10 11.414l-5.707 5.707a1 1 0 01-1.414-1.414l5.707-5.707-5.707-5.707a1 1 0 011.414-1.414L10 9.293z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                        </div>
                      ))}
                      <div className="md:flex md:items-center mb-6">
                        {/* <div className="md:w-3/12">
                              <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" htmlFor="custom-amenity">
                                Add Amenity
                              </label>
                            </div> */}
                        <div className="relative flex flex-row w-full gap-1">
                          <input
                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
                            id="custom-amenity"
                            type="text"
                            placeholder="Add custom amenity and press Enter"
                            onKeyPress={handleAddCustomAmenity}
                          />
                          <button
                            className="p-2 text-xs bg-gray-300 text-gray-600 hover:text-gray-800 focus:outline-none transition duration-150 ease-in-out px-2 py-1 rounded-md hover:bg-gray-400 z-10"
                            onClick={handleAddCustomAmenity}
                          >
                            <Plus className="w-4 h-4 mx-1" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-full lg:w-6/12">
                    <div className="md:flex flex-col md:items-start mb-6">
                      <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pt-4 pr-4 pb-3" htmlFor="comment-section">
                        Comments (Optional)
                      </label>
                      <div className="md:w-full pl-6">
                        <textarea
                          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500 h-48"
                          id="comment-section"
                          rows="5"
                          placeholder="Enter your comments here..."
                          value={comments}
                          onChange={(e) => setComments(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <p className="block text-gray-600 md:text-center mb-1 md:mb-0 pt-4 pb-4">
                  Upload here the confirmation of your reservation.
                </p>
                <input className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer focus:outline-none" type="file" id="file" name="file" accept="image/*" onChange={handleFile} />
                <br /> */}

                <button className="main btn btn-primary" onClick={submitRentalModal} disabled={!(selectedResort && selectedName && selectedDestination && startDate && endDate && usageFee)}>
                  Send
                </button>
              </div>
            </div>
          </Modal>
          <Modal isOpen={newModalIsOpen} onRequestClose={() => setNewIsOpen(false)} className="relative bg-white rounded-lg mx-auto mt-10 p-6 max-w-5xl h-[90%] overflow-y-auto border border-gray-400" >
            <div className="px-2 md:px-16">
              <div className="rental-info">
                <div className="flex justify-end">
                  <div className="absolute top-3 right-3">
                    <button
                      className="close cursor-pointer p-1 focus:outline-none"
                      onClick={() => setNewIsOpen(false)}
                    >
                      <svg
                        className="w-8 h-8 text-gray-700 hover:text-red-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 9.292L5.858 5.15 4.444 6.564 8.586 10.707 4.444 14.85l1.414 1.414L10 12.121l4.142 4.143 1.414-1.414L11.414 10.707 15.556 6.564 14.142 5.15 10 9.292z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <h2 className="text-2xl font-bold leading-tight text-black sm:text-2xl lg:text-3xl py-8">Add On-Demand Rental</h2>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-3/12">
                    <label className="text-gray-600 block md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                      Resort
                    </label>
                  </div>
                  <div className="md:w-8/12">
                    <select
                      id="resort"
                      value={selectedOnDemandResort}
                      onChange={(e) => setSelectedOnDemandResort(e.target.value)}
                      className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
                    >
                      <option value="">Select a resort</option>
                      {onDemandResorts.map((resort, index) => (
                        <option key={index} value={resort}>
                          {resort}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-3/12">
                    <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                      Property
                    </label>
                  </div>
                  <div className="md:w-8/12 relative">
                    <input
                      id="name"
                      value={selectedOnDemandName}
                      onChange={(e) => setSelectedOnDemandName(e.target.value)}
                      className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500 disabled:opacity-50"
                      list="name-datalist"
                      disabled={!selectedOnDemandResort}
                      autoComplete="off"
                      placeholder="Type your property"
                      onFocus={() => setInputFocused(true)}
                      onBlur={() => setInputFocused(false)}
                    />
                    <datalist id="name-datalist">
                      {onDemandNames.map((name, index) => (
                        <option key={index} value={name} />
                      ))}
                    </datalist>
                    {!inputFocused && !selectedOnDemandName && (
                      <span className="absolute right-0 bottom-0 mb-1 mr-1 text-xs text-gray-500">If your property is not on the list you can type it</span>
                    )}
                  </div>
                </div>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-3/12">
                    <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                      Year
                    </label>
                  </div>
                  <div className="md:w-8/12 relative">
                    <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500" id="inline-full-name" type="number" placeholder="Select a year" value={onDemandYear} onChange={(e) => setOnDemandYear(e.target.value)} />
                  </div>
                </div>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-3/12">
                    <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                      Number of weeks
                    </label>
                  </div>
                  <div className="md:w-8/12 relative">
                    <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500" id="inline-full-name" type="number" placeholder="Number of weeks" value={onDemandWeeks} onChange={(e) => setOnDemandWeeks(e.target.value)} />
                  </div>
                </div>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-3/12">
                    <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                      Usage fee
                    </label>
                  </div>
                  <div className="md:w-8/12 relative">
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <span className="text-gray-600">$</span>
                      </div>
                      <input
                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 pl-8 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
                        id="usage-fee"
                        type="text"
                        placeholder="Enter your usage fee as specified in the contract."
                        value={onDemandFormattedUsageFee}
                        onChange={(e) => {
                          const value = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
                          if (value === "") {
                            setOnDemandUsageFee("");
                            setOnDemandFormattedUsageFee("");
                          } else {
                            setOnDemandUsageFee(value);
                            setOnDemandFormattedUsageFee(formatUsageFee(value));
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="md:flex md:items-start mb-6">
                  <div className="md:w-3/12">
                    <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4 pt-1.5" htmlFor="sister-resorts">
                      Sister Resorts
                    </label>
                  </div>
                  <div className="md:w-8/12 relative">
                    <Select
                      isMulti
                      options={propertiesList}
                      value={sisterResorts}
                      onChange={handleSisterResorts}
                      className="basic-multi-select bg-gray-100"
                      classNamePrefix="select"
                    />
                    <p className="text-gray-500 text-sm pt-2">Enter all sister resorts listed in your contract to see more options available to your unit and receive offers.</p>
                  </div>
                </div>
                {sisterResorts.length > 0 && (
                  <div className="md:flex md:items-center mb-6 flex-col w-9/12 mx-auto">
                    <p className="block text-gray-600 md:text-center mb-1 md:mb-0 pt-4 pb-3">
                      Note: Attach a photo of the sister resorts / addendums. We do not need any data or information of your contract, we only need a photo of the sister resorts section to provide you with a better service. (Optional)
                    </p>
                    <input
                      className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer focus:outline-none"
                      type="file"
                      id="sister-resorts-file"
                      name="sister-resorts-file"
                      accept="image/*"
                      onChange={handleSisterResortsFile}
                    />
                  </div>
                )}
                <div className="flex flex-col lg:flex-row justify-start items-start w-full gap-4">
                  <div className="flex flex-col w-full lg:w-5/12">
                    <p className="block text-gray-600 mb-1 md:mb-0 pt-4 pb-3">
                      Select your amenities privileges
                    </p>
                    <div className="flex flex-col px-6 pb-6">
                      <label className="inline-flex items-center py-2">
                        <input className="form-checkbox text-indigo-600" type="checkbox" id="resort" name="Resort Platinum Status" value="Resort Platinum Status" onChange={handleOnDemandAmenities} />
                        <span className="ml-2">Resort Platinum Status</span>
                      </label>
                      <label className="inline-flex items-center py-2">
                        <input type="checkbox" className="form-checkbox text-indigo-600" id="2for1" name="2 for 1 - 50 minutes Spa massages" value="2 for 1 - 50 minutes Spa massages" onChange={handleOnDemandAmenities} />
                        <span className="ml-2">2 for 1 - 50 minutes Spa massages</span>
                      </label>
                      <label className="inline-flex items-center py-2">
                        <input type="checkbox" className="form-checkbox text-indigo-600" id="massages" name="Free Massages" value="Free Massages" onChange={handleOnDemandAmenities} />
                        <span className="ml-2">Free Massages</span>
                      </label>
                      <label className="inline-flex items-center py-2">
                        <input type="checkbox" id="golf" name="Discounted Rounds of Golf" value="Discounted Rounds of Golf" onChange={handleOnDemandAmenities} className="form-checkbox text-indigo-600" />
                        <span className="ml-2">Discounted Rounds of Golf</span>
                      </label>
                      <label className="inline-flex items-center py-2">
                        <input type="checkbox" id="golf1" name="2 to 16 Free Rounds of Golf" value="2 to 16 Free Rounds of Golf" onChange={handleOnDemandAmenities} className="form-checkbox text-indigo-600" />
                        <span className="ml-2">2 to 16 Free Rounds of Golf</span>
                      </label>
                      <label className="inline-flex items-center py-2">
                        <input type="checkbox" id="gondola" name="Access to Air Gondola" value="Access to Air Gondola" onChange={handleOnDemandAmenities} className="form-checkbox text-indigo-600" />
                        <span className="ml-2">Access to Air Gondola</span>
                      </label>
                      {onDemandCustomAmenities.map((amenity, index) => (
                        <div className="inline-flex items-center py-2" key={index}>
                          <input
                            type="checkbox"
                            id={`custom-${index}`}
                            name={amenity.name}
                            value={amenity.name}
                            checked={amenity.checked}
                            onChange={(e) => handleOnDemandCustomAmenitiesCheck(e, index)}
                            className="form-checkbox text-indigo-600"
                          />
                          <span className="ml-2">{amenity.name}</span>
                          <button
                            className="ml-4 text-gray-400 hover:text-red-800 transition duration-150 ease-in-out focus:outline-none"
                            onClick={() => deleteOnDemandCustomAmenity(index)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 9.293l5.707-5.707a1 1 0 011.414 1.414L11.414 10l5.707 5.707a1 1 0 01-1.414 1.414L10 11.414l-5.707 5.707a1 1 0 01-1.414-1.414l5.707-5.707-5.707-5.707a1 1 0 011.414-1.414L10 9.293z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                        </div>
                      ))}
                      <div className="md:flex md:items-center mb-6">
                        {/* <div className="md:w-3/12">
                              <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" htmlFor="custom-amenity">
                                Add Amenity
                              </label>
                            </div> */}
                        <div className="relative flex flex-row w-full gap-1">
                          <input
                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
                            id="custom-amenity"
                            type="text"
                            placeholder="Add custom amenity and press Enter"
                            onKeyPress={handleOnDemandAddCustomAmenity}
                          />
                          <button
                            className="p-2 text-xs bg-gray-300 text-gray-600 hover:text-gray-800 focus:outline-none transition duration-150 ease-in-out px-2 py-1 rounded-md hover:bg-gray-400 z-10"
                            onClick={handleOnDemandAddCustomAmenity}
                          >
                            <Plus className="w-4 h-4 mx-1" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-full lg:w-6/12">
                    <div className="md:flex flex-col md:items-start mb-6">
                      <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pt-4 pr-4 pb-3" htmlFor="comment-section">
                        Comments (Optional)
                      </label>
                      <div className="md:w-full pl-6">
                        <textarea
                          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500 h-48"
                          id="comment-section"
                          rows="5"
                          placeholder="Enter your comments here..."
                          value={onDemandComments}
                          onChange={(e) => setOnDemandComments(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <button className="main btn btn-primary" onClick={submitOnDemandModal} disabled={!(selectedOnDemandResort && selectedOnDemandName && onDemandYear && onDemandWeeks && onDemandUsageFee)}>
                  Send
                </button>
              </div>
            </div>
          </Modal>
          <Modal isOpen={editModalRentalIsOpen} onRequestClose={() => setEditRentalIsOpen(false)} className="relative bg-white rounded-lg mx-auto mt-10 p-6 max-w-5xl h-[90%] overflow-y-auto border border-gray-400">
            <div className="px-2 md:px-16">
              <div className="rental-info">
                <div className="flex justify-end">
                  <div className="absolute top-3 right-3">
                    <button
                      className="close cursor-pointer p-1 focus:outline-none"
                      onClick={() => setEditRentalIsOpen(false)}
                    >
                      <svg
                        className="w-8 h-8 text-gray-700 hover:text-red-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 9.292L5.858 5.15 4.444 6.564 8.586 10.707 4.444 14.85l1.414 1.414L10 12.121l4.142 4.143 1.414-1.414L11.414 10.707 15.556 6.564 14.142 5.15 10 9.292z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <h2 className="text-2xl font-bold leading-tight text-black sm:text-2xl lg:text-3xl py-8">Edit Booked Week</h2>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-3/12">
                    <label className="text-gray-600 block md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                      Resort
                    </label>
                  </div>
                  <div className="md:w-8/12">
                    <select
                      id="resort"
                      value={editResort}
                      onChange={(e) => setEditResort(e.target.value)}
                      className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
                    >
                      <option value="">Select a resort</option>
                      {resorts.map((resort, index) => (
                        <option key={index} value={resort}>
                          {resort}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-3/12">
                    <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                      Property
                    </label>
                  </div>
                  <div className="md:w-8/12 relative">
                    <input
                      id="name"
                      value={editName}
                      onChange={(e) => setEditName(e.target.value)}
                      className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500 disabled:opacity-50"
                      list="name-datalist"
                      disabled={!editResort}
                      autoComplete="off"
                      placeholder="Select a property"
                      onFocus={() => setInputFocused(true)}
                      onBlur={() => setInputFocused(false)}
                    />
                    <datalist id="name-datalist">
                      {names.map((name, index) => (
                        <option key={index} value={name} />
                      ))}
                    </datalist>
                    {!inputFocused && !editName && (
                      <span className="absolute right-0 bottom-0 mb-1 mr-1 text-xs text-gray-500">If your property is not on the list you can type it</span>
                    )}
                  </div>
                </div>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-3/12">
                    <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                      Destination
                    </label>
                  </div>
                  <div className="md:w-8/12 relative">
                    <select
                      id="destination"
                      value={editDestination}
                      onChange={(e) => setEditDestination(e.target.value)}
                      className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500 disabled:opacity-50"
                      disabled={!editResort || !editName}
                    >
                      <option value="">Select a destination</option>
                      {destinations.map((destination, index) => (
                        <option key={index} value={destination}>
                          {destination}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-3/12">
                    <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                      Start Date
                    </label>
                  </div>
                  <div className="md:w-8/12 relative">
                    <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500" id="inline-full-name" type="date" value={editStartDate} onChange={(e) => setEditStartDate(e.target.value)} />
                  </div>
                </div>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-3/12">
                    <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                      End Date
                    </label>
                  </div>
                  <div className="md:w-8/12 relative">
                    <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500" id="inline-full-name" type="date" value={editEndDate} onChange={(e) => setEditEndDate(e.target.value)} />
                  </div>
                </div>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-3/12">
                    <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                      Usage fee
                    </label>
                  </div>
                  <div className="md:w-8/12 relative">
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <span className="text-gray-600">$</span>
                      </div>
                      <input
                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 pl-8 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
                        id="usage-fee"
                        type="text"
                        value={editFormattedUsageFee}
                        onChange={(e) => {
                          const value = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
                          if (value === "") {
                            setEditUsageFee("");
                            setEditFormattedUsageFee("");
                          } else {
                            setEditUsageFee(value);
                            setEditFormattedUsageFee(formatUsageFee(value));
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row justify-start items-start w-full gap-4">
                  <div className="flex flex-col w-full lg:w-5/12">
                    <p className="block text-gray-600 mb-1 md:mb-0 pt-4 pb-3">
                      Select your amenities privileges
                    </p>
                    <div className="flex flex-col px-6 pb-6">
                      <label className="inline-flex items-center py-2">
                        <input className="form-checkbox text-indigo-600" type="checkbox" id="resort" name="Resort Platinum Status" value="Resort Platinum Status" onChange={handleRentalAmenities} checked={editAmenities.includes("Resort Platinum Status")} />
                        <span className="ml-2">Resort Platinum Status</span>
                      </label>
                      <label className="inline-flex items-center py-2">
                        <input type="checkbox" className="form-checkbox text-indigo-600" id="2for1" name="2 for 1 - 50 minutes Spa massages" value="2 for 1 - 50 minutes Spa massages" onChange={handleRentalAmenities} checked={editAmenities.includes("2 for 1 - 50 minutes Spa massages")} />
                        <span className="ml-2">2 for 1 - 50 minutes Spa massages</span>
                      </label>
                      <label className="inline-flex items-center py-2">
                        <input type="checkbox" className="form-checkbox text-indigo-600" id="massages" name="Free Massages" value="Free Massages" onChange={handleRentalAmenities} checked={editAmenities.includes("Free Massages")} />
                        <span className="ml-2">Free Massages</span>
                      </label>
                      <label className="inline-flex items-center py-2">
                        <input type="checkbox" id="golf" name="Discounted Rounds of Golf" value="Discounted Rounds of Golf" onChange={handleRentalAmenities} className="form-checkbox text-indigo-600" checked={editAmenities.includes("Discounted Rounds of Golf")} />
                        <span className="ml-2">Discounted Rounds of Golf</span>
                      </label>
                      <label className="inline-flex items-center py-2">
                        <input type="checkbox" id="golf1" name="2 to 16 Free Rounds of Golf" value="2 to 16 Free Rounds of Golf" onChange={handleRentalAmenities} className="form-checkbox text-indigo-600" checked={editAmenities.includes("2 to 16 Free Rounds of Golf")} />
                        <span className="ml-2">2 to 16 Free Rounds of Golf</span>
                      </label>
                      <label className="inline-flex items-center py-2">
                        <input type="checkbox" id="gondola" name="Access to Air Gondola" value="Access to Air Gondola" onChange={handleRentalAmenities} className="form-checkbox text-indigo-600" checked={editAmenities.includes("Access to Air Gondola")} />
                        <span className="ml-2">Access to Air Gondola</span>
                      </label>
                      {editCustomAmenities.map((amenity, index) => (
                        <>
                          {amenity.name !== "" && (
                            <div className="inline-flex items-center py-2" key={index}>
                              <input
                                type="checkbox"
                                id={`custom-${index}`}
                                name={amenity.name}
                                value={amenity.name}
                                checked={amenity.checked}
                                onChange={(e) => editHandleCustomAmenitiesCheck(e, index)}
                                className="form-checkbox text-indigo-600"
                              />
                              <span className="ml-2">{amenity.name}</span>
                              <button
                                className="ml-4 text-gray-400 hover:text-red-800 transition duration-150 ease-in-out focus:outline-none"
                                onClick={() => editDeleteCustomAmenity(index)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-4 w-4"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M10 9.293l5.707-5.707a1 1 0 011.414 1.414L11.414 10l5.707 5.707a1 1 0 01-1.414 1.414L10 11.414l-5.707 5.707a1 1 0 01-1.414-1.414l5.707-5.707-5.707-5.707a1 1 0 011.414-1.414L10 9.293z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </button>
                            </div>
                          )}
                        </>
                      ))}
                      <div className="md:flex md:items-center mb-6">
                        {/* <div className="md:w-3/12">
                              <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" htmlFor="custom-amenity">
                                Add Amenity
                              </label>
                            </div> */}
                        <div className="relative flex flex-row w-full gap-1">
                          <input
                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
                            id="custom-amenity"
                            type="text"
                            placeholder="Type custom amenity and press Enter"
                            onKeyPress={handleEditAddCustomAmenity}
                          />
                          <button
                            className="p-2 text-xs bg-gray-300 text-gray-600 hover:text-gray-800 focus:outline-none transition duration-150 ease-in-out px-2 py-1 rounded-md hover:bg-gray-400 z-10"
                            onClick={handleEditAddCustomAmenity}
                          >
                            <Plus className="w-4 h-4 mx-1" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-full lg:w-6/12">
                    <div className="md:flex md:items-start mb-6">
                      <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pt-4 pr-4 pb-3" htmlFor="comment-section">
                        Comments (Optional)
                      </label>
                      <div className="md:w-full pl-6">
                        <textarea
                          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500 h-48"
                          id="comment-section"
                          rows="5"
                          placeholder="Enter your comments here..."
                          value={editComments}
                          onChange={(e) => setEditComments(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <p className="block text-gray-600 md:text-center mb-1 md:mb-0 pt-4 pb-3">
                  Upload here the confirmation of your reservation.
                </p>
                <input className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer focus:outline-none" type="file" id="file" name="file" accept="image/*" onChange={handleEditFile} />
                <br />
                <button className="main btn btn-primary" onClick={editRentalModal}>
                  Send
                </button>
              </div>
            </div>
          </Modal>
          <Modal isOpen={editModalOnDemandIsOpen} onRequestClose={() => setEditOnDemandIsOpen(false)} className="relative bg-white rounded-lg mx-auto mt-10 p-6 max-w-5xl h-[90%] overflow-y-auto border border-gray-400">
            <div className="px-2 md:px-16">
              <div className="rental-info">
                <div className="flex justify-end">
                  <div className="absolute top-3 right-3">
                    <button
                      className="close cursor-pointer p-1 focus:outline-none"
                      onClick={() => setEditOnDemandIsOpen(false)}
                    >
                      <svg
                        className="w-8 h-8 text-gray-700 hover:text-red-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 9.292L5.858 5.15 4.444 6.564 8.586 10.707 4.444 14.85l1.414 1.414L10 12.121l4.142 4.143 1.414-1.414L11.414 10.707 15.556 6.564 14.142 5.15 10 9.292z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <h2 className="text-2xl font-bold leading-tight text-black sm:text-2xl lg:text-3xl py-8">Edit On-Demand Rental</h2>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-3/12">
                    <label className="text-gray-600 block md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                      Resort
                    </label>
                  </div>
                  <div className="md:w-8/12">
                    <select
                      id="resort"
                      value={editOnDemandResort}
                      onChange={(e) => setEditOnDemandResort(e.target.value)}
                      className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
                    >
                      <option value="">Select a resort</option>
                      {onDemandResorts.map((resort, index) => (
                        <option key={index} value={resort}>
                          {resort}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-3/12">
                    <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                      Property
                    </label>
                  </div>
                  <div className="md:w-8/12 relative">
                    <input
                      id="name"
                      value={editOnDemandName}
                      onChange={(e) => setEditOnDemandName(e.target.value)}
                      className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500 disabled:opacity-50"
                      list="name-datalist"
                      disabled={!editOnDemandResort}
                      autoComplete="off"
                      placeholder="Type your property"
                      onFocus={() => setInputFocused(true)}
                      onBlur={() => setInputFocused(false)}
                    />
                    <datalist id="name-datalist">
                      {onDemandNames.map((name, index) => (
                        <option key={index} value={name} />
                      ))}
                    </datalist>
                    {!inputFocused && !editOnDemandName && (
                      <span className="absolute right-0 bottom-0 mb-1 mr-1 text-xs text-gray-500">If your property is not on the list you can type it</span>
                    )}
                  </div>
                </div>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-3/12">
                    <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                      Year
                    </label>
                  </div>
                  <div className="md:w-8/12 relative">
                    <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500" id="inline-full-name" type="number" placeholder="Select a year" value={editOnDemandYear} onChange={(e) => setEditOnDemandYear(e.target.value)} />
                  </div>
                </div>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-3/12">
                    <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                      Number of weeks
                    </label>
                  </div>
                  <div className="md:w-8/12 relative">
                    <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500" id="inline-full-name" type="number" placeholder="Number of weeks" value={editOnDemandWeeks} onChange={(e) => setEditOnDemandWeeks(e.target.value)} />
                  </div>
                </div>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-3/12">
                    <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" for="inline-full-name">
                      Usage fee
                    </label>
                  </div>
                  <div className="md:w-8/12 relative">
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <span className="text-gray-600">$</span>
                      </div>
                      <input
                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 pl-8 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
                        id="usage-fee"
                        type="text"
                        placeholder="Enter your usage fee as specified in the contract."
                        value={editOnDemandFormattedUsageFee}
                        onChange={(e) => {
                          const value = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
                          if (value === "") {
                            setEditOnDemandUsageFee("");
                            setEditOnDemandFormattedUsageFee("");
                          } else {
                            setEditOnDemandUsageFee(value);
                            setEditOnDemandFormattedUsageFee(formatUsageFee(value));
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="md:flex md:items-start mb-6">
                  <div className="md:w-3/12">
                    <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4 pt-1.5" htmlFor="sister-resorts">
                      Sister Resorts
                    </label>
                  </div>
                  <div className="md:w-8/12 relative">
                    <Select
                      isMulti
                      options={propertiesList}
                      value={editOnDemandSisterResorts}
                      onChange={handleEditSisterResorts}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                    <p className="text-gray-500 text-sm pt-2">Enter all sister resorts listed in your contract to see more options available to your unit and receive offers.</p>
                  </div>
                </div>
                {editOnDemandSisterResorts?.length > 0 && (
                  <div className="md:flex md:items-center mb-6 flex-col w-9/12 mx-auto">
                    <p className="block text-gray-500 md:text-center mb-1 md:mb-0 pt-4 pb-3">
                      Note: Attach a photo of the sister resorts / addendums. We do not need any data or information of your contract, we only need a photo of the sister resorts section to provide you with a better service. (Optional)
                    </p>
                    <input
                      className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer focus:outline-none"
                      type="file"
                      id="sister-resorts-file"
                      name="sister-resorts-file"
                      accept="image/*"
                      onChange={handleEditSisterResortsFile}
                    />
                  </div>
                )}
                <div className="flex flex-col lg:flex-row justify-start items-start w-full gap-4">
                  <div className="flex flex-col w-full lg:w-5/12">
                    <p className="block text-gray-600 mb-1 md:mb-0 pt-4 pb-3">
                      Select your amenities privileges
                    </p>
                    <div className="flex flex-col px-6 pb-6">
                      <label className="inline-flex items-center py-2">
                        <input className="form-checkbox text-indigo-600" type="checkbox" id="resort" name="Resort Platinum Status" value="Resort Platinum Status" onChange={handleEditOnDemandAmenities} checked={editOnDemandAmenities.includes("Resort Platinum Status")} />
                        <span className="ml-2">Resort Platinum Status</span>
                      </label>
                      <label className="inline-flex items-center py-2">
                        <input type="checkbox" className="form-checkbox text-indigo-600" id="2for1" name="2 for 1 - 50 minutes Spa massages" value="2 for 1 - 50 minutes Spa massages" onChange={handleEditOnDemandAmenities} checked={editOnDemandAmenities.includes("2 for 1 - 50 minutes Spa massages")} />
                        <span className="ml-2">2 for 1 - 50 minutes Spa massages</span>
                      </label>
                      <label className="inline-flex items-center py-2">
                        <input type="checkbox" className="form-checkbox text-indigo-600" id="massages" name="Free Massages" value="Free Massages" onChange={handleEditOnDemandAmenities} checked={editOnDemandAmenities.includes("Free Massages")} />
                        <span className="ml-2">Free Massages</span>
                      </label>
                      <label className="inline-flex items-center py-2">
                        <input type="checkbox" id="golf" name="Discounted Rounds of Golf" value="Discounted Rounds of Golf" onChange={handleEditOnDemandAmenities} className="form-checkbox text-indigo-600" checked={editOnDemandAmenities.includes("Discounted Rounds of Golf")} />
                        <span className="ml-2">Discounted Rounds of Golf</span>
                      </label>
                      <label className="inline-flex items-center py-2">
                        <input type="checkbox" id="golf1" name="2 to 16 Free Rounds of Golf" value="2 to 16 Free Rounds of Golf" onChange={handleEditOnDemandAmenities} className="form-checkbox text-indigo-600" checked={editOnDemandAmenities.includes("2 to 16 Free Rounds of Golf")} />
                        <span className="ml-2">2 to 16 Free Rounds of Golf</span>
                      </label>
                      <label className="inline-flex items-center py-2">
                        <input type="checkbox" id="gondola" name="Access to Air Gondola" value="Access to Air Gondola" onChange={handleEditOnDemandAmenities} className="form-checkbox text-indigo-600" checked={editOnDemandAmenities.includes("Access to Air Gondola")} />
                        <span className="ml-2">Access to Air Gondola</span>
                      </label>
                      {editOnDemandCustomAmenities.map((amenity, index) => (
                        <>
                          {amenity.name !== "" && (
                            <div className="inline-flex items-center py-2" key={index}>
                              <input
                                type="checkbox"
                                id={`custom-${index}`}
                                name={amenity.name}
                                value={amenity.name}
                                checked={amenity.checked}
                                onChange={(e) => editHandeOnDemandCustomAmenitiesCheck(e, index)}
                                className="form-checkbox text-indigo-600"
                              />
                              <span className="ml-2">{amenity.name}</span>
                              <button
                                className="ml-4 text-gray-400 hover:text-red-800 transition duration-150 ease-in-out focus:outline-none"
                                onClick={() => editOnDemandDeleteCustomAmenity(index)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-4 w-4"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M10 9.293l5.707-5.707a1 1 0 011.414 1.414L11.414 10l5.707 5.707a1 1 0 01-1.414 1.414L10 11.414l-5.707 5.707a1 1 0 01-1.414-1.414l5.707-5.707-5.707-5.707a1 1 0 011.414-1.414L10 9.293z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </button>
                            </div>
                          )}
                        </>
                      ))}
                      <div className="md:flex md:items-center mb-6">
                        {/* <div className="md:w-3/12">
                          <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pr-4" htmlFor="custom-amenity">
                            Add Amenity
                          </label>
                        </div> */}
                        <div className="relative flex flex-row w-full gap-1">
                          <input
                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
                            id="custom-amenity"
                            type="text"
                            placeholder="Add custom amenity and press Enter"
                            onKeyPress={handleEditOnDemandAddCustomAmenity}
                          />
                          <button
                            className="p-2 text-xs bg-gray-300 text-gray-600 hover:text-gray-800 focus:outline-none transition duration-150 ease-in-out px-2 py-1 rounded-md hover:bg-gray-400 z-10"
                            onClick={handleEditOnDemandAddCustomAmenity}
                          >
                            <Plus className="w-4 h-4 mx-1" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-full lg:w-6/12">
                    <div className="md:flex flex-col md:items-start mb-6">
                      <label className="block text-gray-600 md:text-center mb-1 md:mb-0 pt-4 pr-4 pb-3" htmlFor="comment-section">
                        Comments (Optional)
                      </label>
                      <div className="md:w-full pl-6">
                        <textarea
                          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500 h-48"
                          id="comment-section"
                          rows="5"
                          placeholder="Enter your comments here..."
                          value={editOnDemandComments}
                          onChange={(e) => setEditOnDemandComments(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <button className="main btn btn-primary" onClick={editOnDemandModal}>
                  Send
                </button>
              </div>
            </div>
          </Modal>
          <Modal isOpen={deleteModalRentalIsOpen} onRequestClose={() => setDeleteRentalIsOpen(false)} className="relative bg-white rounded-lg mx-auto p-6 max-w-md h048 mt-[calc(50vh-10rem)] overflow-y-auto border border-gray-400">
            {selectedRental && (
              <p className="text-gray-700 text-center">
                Are you sure you want to delete <span className="font-semibold">{selectedRental.resort} {selectedRental.rental} {selectedRental.destination}</span> booked week?
              </p>
            )}
            <div className="flex justify-center items-center gap-8 mt-6">
              <button className="bg-gray-300 hover:bg-gray-400 p-2 px-6 rounded-lg" onClick={() => setDeleteRentalIsOpen(false)} >
                No
              </button>
              <button className="bg-red-500 hover:bg-red-600 p-2 px-6 rounded-lg" onClick={() => deleteRentalModal(selectedRental)} >
                Yes
              </button>
            </div>
          </Modal>
          <Modal isOpen={deleteModalOnDemandIsOpen} onRequestClose={() => setDeleteOnDemandIsOpen(false)} className="relative bg-white rounded-lg mx-auto p-6 max-w-md h048 mt-[calc(50vh-10rem)] overflow-y-auto border border-gray-400">
            {selectedOnDemand && (
              <p className="text-gray-700 text-center">
                Are you sure you want to delete <span className="font-semibold">{selectedOnDemand.resort} {selectedOnDemand.property}</span> on-demand rental?
              </p>
            )}
            <div className="flex justify-center items-center gap-8 mt-6">
              <button className="bg-gray-300 hover:bg-gray-400 p-2 px-6 rounded-lg" onClick={() => setDeleteOnDemandIsOpen(false)} >
                No
              </button>
              <button className="bg-red-500 hover:bg-red-600 p-2 px-6 rounded-lg" onClick={() => deleteOnDemandModal(selectedOnDemand)} >
                Yes
              </button>
            </div>
          </Modal>
          <h1 className="text-2xl font-medium">Clients</h1>
          <div className="flex items-center my-2">
            <input
              type="checkbox"
              checked={showOnlyNonNullValue}
              onChange={() => setShowOnlyNonNullValue(!showOnlyNonNullValue)}
              className="mr-2"
            />
            <label>Show only paid clients</label>
          </div>
          <div className="flex flex-col items-center my-2">
            <label htmlFor="searchQuery" className="mr-2">
              Search by name or email:
            </label>
            <input
              id="searchQuery"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <table className="table-auto w-full lg:w-2/3">
            <thead>
              <tr>
                <th className="px-4 py-2">Name</th>
                <th className="px-4 py-2">Plan</th>
                <th className="hidden lg:table-cell px-4 py-2">Booked weeks</th>
                <th className="hidden lg:table-cell px-4 py-2">On demands</th>
                <th className="px-4 py-2"></th>
              </tr>
            </thead>
            <tbody>
              {filteredClients.map(client => (
                <tr key={client.uid}>
                  <td className="flex flex-col px-1 lg:px-4 py-2 text-xs"><span className='text-zinc-600'>{client.name}</span> {client.email}</td>
                  <td className="px-1 lg:px-4 py-2 text-xs">{client.currentPlan} <span className='text-zinc-600'>{client.currentPlan && `(${client.percentage}%)`}</span></td>
                  <td className="hidden lg:table-cell px-1 lg:px-4 py-2 text-xs">{client.bookedWeeks}</td>
                  <td className="hidden lg:table-cell px-1 lg:px-4 py-2 text-xs">{client.onDemands}</td>
                  <td className="px-4 py-2">
                    <button
                      onClick={() => handleViewDetails(client)}
                      className="bg-blue-500 text-white px-4 py-2 hover:bg-blue-600 text-xs"
                    >
                      Details
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button className="  my-4 bg-green-500 text-white px-4 py-2 hover:bg-green-600" onClick={downloadExcel}>Download Excel</button>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-screen">
          <h1 className="text-2xl font-medium">Forbidden</h1>
        </div>
      )}
    </>

  );
};

export default Admin;
